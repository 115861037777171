import clsx from 'clsx';
import { FunctionComponent, PropsWithChildren } from 'react';

type Props = {
  className?: string;
};

export const CardText: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
}) => <div className={clsx(className, 'text-gray-500')}>{children}</div>;
