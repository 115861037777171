import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.analytics && window.analytics.page) {
      window.analytics.page();
    }
  }, [location]);

  return null;
};

export default GoogleAnalytics;
