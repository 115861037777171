import { useEffect, createContext, Dispatch, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { BackArrow, Card } from '@pm/core';
import QuestionnaireLayout from '../components/QuestionnaireLayout';
import PreScreenRoutes from './routes';
import { useGetPreScreenResults } from '../../../hooks/questionnaires/index';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const PreScreenResultsContext = createContext<{
  step: number;
  endStep: number;
  setStep: Dispatch<number>;
}>({
  step: 0,
  endStep: 0,
  setStep: useState,
});

type PreScreenResults = {
  PreviousDiagnosis?: string;
  SymptomsBefore12YearsOld?: string;
};

const PreScreenQuestionnaire = () => {
  const { t } = useTranslation('frida-pre-screen-questionnaire');
  const questions = Object.keys(t('Questions', { returnObjects: true }));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [step, setStep] = useState(0);
  const initialValues = useGetPreScreenResults();

  const shape: { [key: string]: Yup.StringSchema } = {};
  questions.forEach((q: string) => {
    shape[q] = Yup.string();
  });
  const schema = Yup.object().shape(shape);

  const LDClient = useLDClient();
  const showSymptomList = LDClient?.variation('frida-screening-age-12', false);

  useEffect(() => {
    if (pathname.split('/').length === 2) {
      navigate('/pre-screen/PreviousDiagnosis');
      window.analytics.track('Pre Screen Start');
    }
  }, [pathname, navigate]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        if (values.SymptomsBefore12YearsOld == undefined) {
          values.SymptomsBefore12YearsOld = 'N/A';
        }
        if (showSymptomList) {
          if (values.SymptomsBefore12YearsOld?.includes('No to all')) {
            values.SymptomsBefore12YearsOld = 'No';
          } else if (!values.SymptomsBefore12YearsOld.includes('N/A')) {
            values.SymptomsBefore12YearsOld = 'Yes';
          }
        }
        window.analytics.track('Pre Screen End');
        localStorage.setItem('PRE_SCREEN', JSON.stringify({ ...values }));

        return navigate('/asrs-a/intro');
      }}
    >
      <QuestionnaireLayout>
        <Card>
          <BackArrow
            className="mb-4"
            onClick={() => {
              if (!step) {
                navigate('/province');
              } else {
                step && setStep(step - 1);
                navigate(-1);
              }
            }}
          />
          <PreScreenResultsContext.Provider
            value={{ step, setStep, endStep: questions.length - 1 }}
          >
            <Outlet />
          </PreScreenResultsContext.Provider>
        </Card>
      </QuestionnaireLayout>
    </Formik>
  );
};

export default PreScreenQuestionnaire;
export { PreScreenRoutes, PreScreenResultsContext };
export type { PreScreenResults };
