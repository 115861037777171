import { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import type { MedResponseProps } from '.';
import MedicationResponse, { Question as MedResponseQuestion } from '.';

const MedResponseRoutes: FunctionComponent<
  PropsWithChildren<MedResponseProps>
> = ({ isAssessment = false }) => {
  const { t } = useTranslation('frida-medication-response-questionnaire');
  const medResponseQuestions = Object.entries(
    t('Questions', {
      returnObjects: true,
    }),
  );

  return (
    <Routes>
      {/* Medication Response Form */}
      <Route
        path="/"
        element={<MedicationResponse isAssessment={isAssessment} />}
      >
        {medResponseQuestions.map(([qKey, question], i) => (
          <Route
            key={i}
            path={qKey}
            element={
              <MedResponseQuestion
                name={qKey}
                question={question}
                isAssessment={isAssessment}
                nextPath={
                  i + 1 < medResponseQuestions.length
                    ? medResponseQuestions[i + 1][0]
                    : medResponseQuestions[medResponseQuestions.length - 1][0]
                }
              />
            }
          />
        ))}
      </Route>
    </Routes>
  );
};

export default MedResponseRoutes;
