import { FunctionComponent } from 'react';
import { I18nResources, useTranslation } from 'react-i18next';

import { ButtonLink, Card } from '@pm/core';
import { SignupWorkflowSteps, AssessmentWorkflowSteps } from './types';

type Props = {
  id: string;
  type?: string;
  questionnaireRoute?: string; // deprecate when frida-medication-response flag is removed and NewDashboard is used
};

type Routes = keyof I18nResources['patient-dashboard']['Questionnaires'];

const GenericQuestionnaireCard: FunctionComponent<Props> = ({
  id,
  type,
  questionnaireRoute,
}) => {
  const { t } = useTranslation('patient-dashboard');
  let route = '';
  let i18nKey = '';
  Object.values(SignupWorkflowSteps).forEach((signupStep) => {
    Object.values(AssessmentWorkflowSteps).forEach((assStep) => {
      if (signupStep.type === type) {
        route = signupStep.route;
        i18nKey = signupStep.route;
      }

      if (assStep.type === type) {
        route = assStep.route;
        i18nKey = 'followup' + assStep.route;
      }
    });
  });

  const cleanRoute = (questionnaireRoute ?? i18nKey) as Routes;

  return (
    <Card key={id}>
      <div className="text-2xl font-medium text-gray-900">
        {t(`Questionnaires.${cleanRoute}.Heading`)}
      </div>
      <div className="font-normal text-gray-500">
        <div className="mt-1 text-sm">
          <>
            {t(`Questionnaires.${cleanRoute}.Questions`)}
            <span className="mx-1">&bull;</span>
            {t(`Questionnaires.${cleanRoute}.Time`)}
          </>
        </div>
        <div className="mt-4 mb-6">
          {type === SignupWorkflowSteps['Screening'].type ||
          type === AssessmentWorkflowSteps['MedicationResponse'].type ? (
            <div>
              {t(`Questionnaires.${cleanRoute}.CardText`)
                .split('{n}')
                .map((substr: string) => (
                  <div key={substr} className="mt-4">
                    {substr}
                  </div>
                ))}
            </div>
          ) : (
            <div>{t(`Questionnaires.${cleanRoute}.CardText`)}</div>
          )}
        </div>
      </div>
      <div className="mt-2">
        <ButtonLink
          to={`/${route}/${id}`}
          onClick={() => {
            if (type === SignupWorkflowSteps.Screening.type) {
              window.analytics.track('Screening Questionnaire Start');
            }
            if (type === SignupWorkflowSteps.ASRS_B.type) {
              window.analytics.track('ASRS B Start');
            }
          }}
        >
          {t('StartAssessment')}
        </ButtonLink>
      </div>
    </Card>
  );
};

export default GenericQuestionnaireCard;
