import { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import AsrsIntro from './AsrsIntro';
import AsrsNotQualified from './AsrsNotQualified';
import { AsrsProgressContext } from '../index';
import {
  GetMePatientDocument,
  useUpdateWorkflowStepMutation,
  useUpdateAssessmentMutation,
} from '@pm/graphql';
import { Button, Select } from '@pm/core';
import NextButtonLabel from '../../components/NextButtonLabel';
import toast from 'react-hot-toast';

type QuestionProps = {
  name: string;
  question: string;
  options: string[] | { text: ReactNode; value: string }[];
  nextPath?: string;
  cacheAnswers?: boolean;
  part?: 'PartA' | 'PartB' | 'Both';
  isAssessment?: boolean;
};

const Question = ({
  name,
  question,
  options,
  nextPath,
  part,
  cacheAnswers = false,
  isAssessment = false,
}: QuestionProps) => {
  const { t } = useTranslation('frida-asrs-questionnaire');
  // can't type this one, no type based on questions :(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, submitForm, setSubmitting }: any = useFormikContext();
  const { step, endStep, setStep, baseRoute } = useContext(AsrsProgressContext);
  const [updateWorkflowStep] = useUpdateWorkflowStepMutation();
  const [updateAssessment] = useUpdateAssessmentMutation();
  const questionsFromI18n = Object.values(
    t('Questions', { returnObjects: true }),
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const questions: any =
    part === 'PartA'
      ? questionsFromI18n.slice(0, 6)
      : part === 'PartB'
      ? questionsFromI18n.slice(6, questionsFromI18n.length)
      : questionsFromI18n;
  const { id }: Readonly<Params<string>> = useParams(); // Either the workflow step id or the assessment id
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const disabled = !values[name];
  values['QuestionnaireVersion'] = t('QuestionnaireVersion');

  setStep(questions.indexOf(question) + 1);

  const cacheLocal = () =>
    localStorage.setItem('ASRS_SCREENING', JSON.stringify({ ...values }));

  return (
    <div className="flex flex-col">
      <div className="mb-2 text-xl text-gray-700">{question}</div>
      <Select name={name} options={options} />
      <Button
        disabled={disabled}
        className="mt-12"
        onClick={() => {
          cacheAnswers && cacheLocal();

          if (id) {
            setLoading(true);

            if (isAssessment) {
              try {
                updateAssessment({
                  variables: {
                    input: {
                      id,
                      data: {
                        ...values,
                        firstIncompleteQuestion: nextPath,
                      },
                    },
                  },
                  refetchQueries: [{ query: GetMePatientDocument }],
                });
              } catch (e) {
                setSubmitting(false);
                setLoading(false);
                return toast.error('Something went wrong!');
              }
            } else {
              try {
                updateWorkflowStep({
                  variables: {
                    input: {
                      id,
                      completionParams: {
                        ...values,
                        firstIncompleteQuestion: nextPath,
                      },
                    },
                  },
                  refetchQueries: [{ query: GetMePatientDocument }],
                });
              } catch (e) {
                setSubmitting(false);
                setLoading(false);
                return toast.error('Something went wrong!');
              }
            }
          }

          if (step === endStep) {
            return submitForm();
          } else {
            setLoading(false);
          }

          nextPath && navigate(baseRoute + nextPath);
        }}
      >
        <NextButtonLabel
          loading={loading}
          label={
            step !== endStep
              ? t('Next')
              : part === 'PartA'
              ? t('GetResults')
              : t('Finish')
          }
        />
      </Button>
    </div>
  );
};

export { AsrsIntro, Question, AsrsNotQualified };
