import { Dispatch, useState, createContext, FunctionComponent } from 'react';

import AuthAsrsQuestionnaire from './Auth';
import InauthAsrsQuestionnaire from './InAuth';
import { AsrsIntro, AsrsNotQualified, Question } from './pages';
import { ProfileProvider } from '@pm/core';
import AsrsRoutes from './routes';

export const AsrsProgressContext = createContext<{
  baseRoute: string;
  step: number;
  endStep: number;
  setStep: Dispatch<number>;
}>({
  baseRoute: '',
  step: 0,
  endStep: 0,
  setStep: useState,
});

export type AsrsQuestionnaireProps = {
  part?: 'PartA' | 'PartB' | 'Both';
};

type Props = {
  auth: boolean;
  part?: 'PartA' | 'PartB' | 'Both';
  isAssessment?: boolean;
};

const AsrsQuestionnaire: FunctionComponent<Props> = ({
  auth,
  part,
  isAssessment = false,
}) => {
  return auth ? (
    <ProfileProvider>
      <AuthAsrsQuestionnaire isAssessment={isAssessment} part={part} />
    </ProfileProvider>
  ) : (
    <InauthAsrsQuestionnaire part={part} />
  );
};

export default AsrsQuestionnaire;
export { AsrsIntro, Question, AsrsNotQualified, AsrsRoutes };
