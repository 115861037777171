import { Trans, useTranslation } from 'react-i18next';

const AsrsNotQualified = () => {
  const { t } = useTranslation('frida-asrs-questionnaire');

  return (
    <div className="flex flex-col text-gray-900">
      <div className="mb-4 text-2xl font-medium">{t('NotQualified.Title')}</div>
      {(t('NotQualified.CardText') as string).split('{n}').map((substr) => (
        <div key={substr} className="mb-8 text-xl text-gray-500">
          {substr}
        </div>
      ))}
      <div className="text-xl text-gray-500">
        <Trans t={t} i18nKey={'NotQualified.LinkText' as 'Continue'}>
          <a
            href="https://caddac.ca/find-a-resource/?toolset_maps_distance_radius=20&toolset_maps_distance_unit=km&wpv-resource-province=ontario&wpv_aux_current_post_id=96&wpv_aux_parent_post_id=96&wpv_view_count=1371"
            className="text-hyperlink-foreground text-xl underline"
          />
        </Trans>
      </div>
    </div>
  );
};

export default AsrsNotQualified;
