import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  BackArrow,
  Card,
  CardText,
  CardTitle,
  usePatientProfile,
} from '@pm/core';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Spacer } from '@pm/core';
import { CreditCardForm } from '../../../components/CreditCardForm';
import { Layout } from '../../../layout';

const stripePromise = loadStripe(
  import.meta.env.VITE_ENVIRONMENT === 'production'
    ? 'pk_live_51Jn9TVHmfhkgjy6M947UNfbwRPYh63dDjx5DLOknC3GCKhUGvsxp6KpOLKu7s6kM4Wo01RHQrloo9E9Di0xjRcR800hjn6N4rZ'
    : 'pk_test_51Jn9TVHmfhkgjy6Mk6qMPm5DVXQdmvryn1hZBp7UzWFlsFK3s8o86GFwJYiGqqcmg9wYzF480LJuLiEzU6sAzC0L00NQfXsWBk',
);

const CreditCardSignupPage = () => {
  const { t } = useTranslation('credit-card');
  const navigate = useNavigate();
  const { workflowStepId, appointmentType, appointmentDate } = useParams();
  const nextPath = `/appointment/${workflowStepId}/${appointmentType}/${appointmentDate}`;
  const { profile } = usePatientProfile();

  if (profile?.profile?.hasPaymentMethod) {
    navigate(nextPath);
  }

  return (
    <Layout narrow>
      <BackArrow
        onClick={() => {
          navigate(`/appointment/${workflowStepId}`);
        }}
      ></BackArrow>
      <Elements stripe={stripePromise}>
        <Card className="mb-4 mt-4">
          <CardTitle>{t('Heading')}</CardTitle>
          <CardText className="text-xl text-gray-500">
            {t('Description1')}
          </CardText>
          <Spacer size={4} />
          <CardText className="text-xl text-gray-500">
            {t('Description2')}
          </CardText>
        </Card>
        <Spacer size={6} />
        <Card>
          <CardTitle>{t('Enter')}</CardTitle>
          <CreditCardForm onSubmit={() => navigate(nextPath)} />
          <div className="mt-6 text-xs text-gray-500">
            <Trans t={t} i18nKey="Terms">
              <a
                href="https://talkwithfrida.com/terms-of-use/"
                className="text-frida-purple underline"
              />
            </Trans>
          </div>
        </Card>
        <Spacer size={6} />
        <Card>
          <CardText className="mb-4 text-xl text-gray-700">
            {t('NeedInfo')}
          </CardText>
          <a
            href={'https://3ni8kh4fzbc.typeform.com/to/QSWYxShU'}
            target="_blank"
            rel="noreferrer"
          >
            <CardText className="text-frida-purple-dark font-regular text-xl underline">
              {t('Survey')}
            </CardText>
          </a>
        </Card>
      </Elements>
    </Layout>
  );
};

export default CreditCardSignupPage;
