import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PencilIcon } from '@heroicons/react/solid';
import { useGetMyConversationsQuery } from '@pm/graphql';
import MessageList from '../../components/MessageList';
import { ButtonLink, LoadingPage, Toggle, useProfile } from '@pm/core';
import { Layout } from '../../layout';

const ConversationsPage = () => {
  const [showUnread, setShowUnread] = useState(false);
  const { userId } = useProfile();
  const { data, fetchMore } = useGetMyConversationsQuery({
    variables: {
      unreadById: showUnread ? userId : undefined,
    },
  });
  const { t } = useTranslation('messages');

  if (!data?.me?.myConversations?.edges) {
    return <LoadingPage />;
  }

  const conversations = data.me.myConversations.edges
    .filter((edge) => edge?.node)
    .map((edge) => edge?.node);

  if (!conversations) {
    return <LoadingPage />;
  }

  return (
    <Layout title="Messages" wide>
      <div className="flex flex-col md:grid md:grid-flow-col">
        <div className="mt-3 text-sm">
          <Toggle
            value={showUnread}
            label={t('ShowUnread')}
            onChange={() => setShowUnread(!showUnread)}
          />
        </div>
        <div>
          <ButtonLink
            to="/compose"
            variant="dark"
            icon={<PencilIcon className="mr-2 h-4 w-4" />}
            className="float-right mt-6 md:mt-0 md:w-36"
          >
            {t('Compose')}
          </ButtonLink>
        </div>
      </div>
      <MessageList conversations={conversations} />
      {data.me.myConversations.pageInfo.hasNextPage && (
        <button
          className="bg-freddie-green-1 mt-4 rounded-lg p-4 text-white"
          onClick={() => {
            fetchMore({
              variables: {
                afterCursor:
                  data?.me?.myConversations?.pageInfo.endCursor ?? '',
              },
            });
          }}
        >
          {t('LoadMore')}
        </button>
      )}
    </Layout>
  );
};

export default ConversationsPage;
