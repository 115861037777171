import { ButtonLink } from '@pm/core';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Params, useParams } from 'react-router-dom';

import { AsrsProgressContext } from '../index';

const AsrsIntro = () => {
  const { t } = useTranslation('frida-asrs-questionnaire');
  const { step, setStep, baseRoute } = useContext(AsrsProgressContext);
  const { workflowStepId }: Readonly<Params<string>> = useParams();
  const introText = (t('IntroPartA') as string).split('{n}');

  let firstQuestionRoute = '';
  if (workflowStepId) {
    firstQuestionRoute = baseRoute + workflowStepId + '/TroubleFinalizing';
  } else {
    firstQuestionRoute = baseRoute + 'TroubleFinalizing';
  }

  if (!step) setStep(1);

  return (
    <div className="flex flex-col">
      <div className="mb-4 text-2xl font-medium text-gray-900">
        {t('Header')}
      </div>
      {introText.map((substr: string) => (
        <div key={substr} className="mb-10 text-xl text-gray-700">
          {substr}
        </div>
      ))}
      <ButtonLink
        to={firstQuestionRoute}
        onClick={() => window.analytics.track('Intake ASRS-A Start')}
      >
        {t('Start')}
      </ButtonLink>
    </div>
  );
};

export default AsrsIntro;
