import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { WfirsProgressContext } from '../index';
import { Button, ButtonLink, Dialog } from '@pm/core';

const WfirsIntro = ({
  section,
  isAssessment = false,
}: {
  section: string;
  isAssessment: boolean;
}) => {
  const { t } = useTranslation(['frida-wfirs-questionnaire', 'global']);
  const answers = t('frida-wfirs-questionnaire:Answers', {
    returnObjects: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { step, setStep, setEndStep, setSection } =
    useContext(WfirsProgressContext);
  const { id } = useParams();
  const { values, setValues } = useFormikContext<{ [id: string]: string }>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const sections = Object.keys(
    t('frida-wfirs-questionnaire:Sections', { returnObjects: true }),
  );
  const skippableSections = sections.slice(1, 3);
  const nextSection = sections[sections.indexOf(section) + 1];

  const getSectionQuestions = (section: string) =>
    Object.keys(
      t(
        `frida-wfirs-questionnaire:Sections.${section}.Questions` as 'frida-wfirs-questionnaire:Sections.Section1Family.Questions',
        {
          returnObjects: true,
        },
      ),
    )[0];
  let nextPathPrefix = '/wfirs/';

  if (isAssessment) {
    nextPathPrefix = '/assessment' + nextPathPrefix;
  }
  const nextPath = `${nextPathPrefix}${id}/${section}/${getSectionQuestions(
    section,
  )}`;

  return (
    <div className="flex flex-col text-gray-900">
      {t(
        `frida-wfirs-questionnaire:Sections.${section}.Intro` as 'frida-wfirs-questionnaire:Sections.Section2Work.Intro',
      )
        .split('{n}')
        .map((substr: string) => (
          <div key={substr} className="mb-8 text-xl">
            {substr}
          </div>
        ))}
      <ButtonLink
        to={nextPath}
        onClick={() => {
          setStep(step + 1);
        }}
      >
        {t(
          `frida-wfirs-questionnaire:Sections.${section}.Start` as 'frida-wfirs-questionnaire:Sections.Section2Work.Start',
        )}
      </ButtonLink>
      {skippableSections.includes(section) ? (
        <Button
          variant="tertiary"
          className="mt-1"
          onClick={() => setDialogOpen(true)}
        >
          {t('frida-wfirs-questionnaire:SkipSection')}
        </Button>
      ) : null}
      {skippableSections.includes(section) ? (
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <div className="text-left">
            <div className="text-lg font-medium">
              {t('frida-wfirs-questionnaire:SkipSectionQuestion')}
            </div>
            <div>
              {t(
                `frida-wfirs-questionnaire:Sections.${section}.SkipSectionConfirmation` as 'frida-wfirs-questionnaire:Sections.Section2Work.SkipSectionConfirmation',
              )}
            </div>
          </div>
          <ButtonLink
            to={`${nextPathPrefix}${id}/${nextSection}/intro`}
            variant="dark"
            className="mt-3"
            onClick={() => {
              // section is actually the next section key from where
              // this button was clicked because of the route config
              const newValues = Object.assign({}, values);
              newValues[`${section}Skipped`] = 'true';
              const sectionKeys = Object.keys(
                t(
                  `frida-wfirs-questionnaire:Sections.${section}.Questions` as 'frida-wfirs-questionnaire:Sections.Section1Family.Questions',
                  { returnObjects: true },
                ) ?? [],
              );

              sectionKeys.forEach((sectionKey) => {
                Object.keys(values).forEach((answerKey) => {
                  if (sectionKey === answerKey) {
                    newValues[answerKey] = answers['NotApplicable'];
                  }
                });
              });

              setEndStep(nextSection.length);
              setValues(newValues);
              setDialogOpen(false);
              setSection(nextSection);
            }}
          >
            {t('global:Confirm')}
          </ButtonLink>
          <Button
            variant="tertiary"
            className="mt-2"
            onClick={() => setDialogOpen(false)}
          >
            {t('global:Cancel')}
          </Button>
        </Dialog>
      ) : null}
    </div>
  );
};

export default WfirsIntro;
