import {
  useState,
  useEffect,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import { ErrorMessage, Field, useField } from 'formik';
import clsx from 'clsx';

import BoolDropdown from './BoolDropdown';

export interface WrapperProps {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  selected?: boolean;
}

interface DropdownProps extends WrapperProps {
  options: object;
}

const Wrapper: FunctionComponent<PropsWithChildren<WrapperProps>> = ({
  name,
  label,
  placeholder,
  className,
  selected,
  children,
}) => {
  const [field, meta] = useField(name);

  return (
    <div className={`text-gray-${selected ? '9' : '5'}00`}>
      <label htmlFor={name} className="mb-0 text-sm font-medium text-gray-500">
        {label}
      </label>
      <Field
        name={name}
        as="select"
        className={clsx(
          'mt-0 w-full cursor-pointer rounded-lg border p-3',
          meta.error && meta.touched
            ? 'border-red-500 bg-red-50'
            : 'border-gray-500 bg-gray-50',
          className,
        )}
      >
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {children}
      </Field>
      <ErrorMessage
        name={field.name}
        className={clsx(
          'mt-1 mb-3 border-none text-sm font-medium text-red-600',
        )}
        component="p"
      />
      <div className="mb-3"></div>
    </div>
  );
};

export const Dropdown: FunctionComponent<DropdownProps> = (props) => {
  const [{ value }] = useField(props.name);
  const [selected, setSelected] = useState<boolean>(false);

  // when a value is selected, change the text color
  // of the "placeholder" - option 1 of the select
  // the `option` element cannot be styled, so this
  // is how we change its text color
  useEffect(() => {
    if (value && value.length) setSelected(true);
  }, [value]);

  return (
    <Wrapper {...props} selected={selected}>
      {Object.entries(props.options).map(
        ([key, value]: [string, string], index: number) => (
          <option key={index} value={key}>
            {value}
          </option>
        ),
      )}
    </Wrapper>
  );
};

export { Wrapper, BoolDropdown };
