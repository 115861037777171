import { Card, Spacer } from '@pm/core';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../layout';
import {
  QuestionnaireStep,
  AssessmentPageWorkflowSteps,
} from '../DashboardPage/types';
import GenericAssessmentCard from './GenericAssessmentCard';

const AssessmentsPage = () => {
  const { t } = useTranslation('assessment-page');
  const assessments: QuestionnaireStep['route'][] = Object.values(
    AssessmentPageWorkflowSteps,
  )
    .map(({ type }) => type)
    .filter((type) => type !== AssessmentPageWorkflowSteps.PostConsult.type);

  const assessmentsToComplete: React.ReactNode[] = [];
  const navigate = useNavigate();

  assessments.forEach((type, index) => {
    assessmentsToComplete.push(
      <div key={index}>
        <Spacer size={4} />
        <GenericAssessmentCard questionnaireType={type} />
      </div>,
    );
  });

  const LDClient = useLDClient();
  const showAssessmentPage = LDClient?.variation(
    'show-frida-prescriptions',
    false,
  );
  if (!showAssessmentPage) {
    navigate('/');
  }

  return (
    <Layout narrow>
      <Card>
        <>
          <div className="mb-4 text-2xl font-medium text-gray-900">
            {t('Assessments')}
          </div>
          <div className="text-xl font-normal text-gray-500">
            {t('AssessmentCardDescription')}
          </div>
        </>
      </Card>
      {assessmentsToComplete}
    </Layout>
  );
};

export default AssessmentsPage;
