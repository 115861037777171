import { useState } from 'react';
import {
  AcuityAppointmentFragment,
  useGetMyWorkflowStepsQuery,
} from '@pm/graphql';
import { add, isBefore, parseISO } from 'date-fns';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { LoadingPage, usePatientProfile } from '@pm/core';
import AssessmentCompleteCard from './AssessmentCompleteCard';
import BookAppointmentCard from './BookAppointmentCard';
import GenericQuestionnaireCard from './GenericQuestionnaireCard';
import GenericStepCard from './GenericStepCard';
import NotQualifiedCard from './NotQualifiedCard';
import ManageAppointmentCard from './ManageAppointmentCard';
import RoadmapCard, { RoadmapSteps } from './RoadmapCard';
import { Layout } from '../../layout';
import NewDashboardPage from './NewDashboardPage';
import { AllWorkflowSteps } from './types';

const DashboardPage = () => {
  const { data, loading: stepsLoading } = useGetMyWorkflowStepsQuery();
  const { profile, loading: profileLoading } = usePatientProfile();
  const [imageLoaded, setImageLoaded] = useState(false);
  const questionnaireSteps = [];
  const steps = data?.me?.workflowSteps ?? [];
  const incompleteSteps = steps.filter((step) => step.completedAt === null);
  let roadmapStep: RoadmapSteps['step'] = '1';

  const LDClient = useLDClient();
  const isMedResponseCardEnabled = LDClient?.variation(
    'frida-medication-response',
    false,
  );
  const showNewDashboard = LDClient?.variation(
    'show-frida-assessment-workflow',
    false,
  );

  const screenedOut = data?.me?.currentBrandMembership?.screenedOutStep;
  if (screenedOut) {
    return (
      <Layout narrow>
        <NotQualifiedCard />
      </Layout>
    );
  }

  // find upcoming appointment
  let upcomingAppointment: AcuityAppointmentFragment | undefined | null;
  const appointments = profile?.acuityAppointments.edges || [];

  if (appointments.length) {
    const displayableAppointmentTimeLimit = add(new Date(), { hours: -1 });
    upcomingAppointment = appointments.find((appointment) =>
      isBefore(
        displayableAppointmentTimeLimit,
        parseISO(appointment?.node?.scheduledAt),
      ),
    )?.node;

    if (upcomingAppointment) {
      const workflowStepId = steps.find(
        ({ type }) => type === AllWorkflowSteps['InitialAssessment'].type,
      )?.id;
      if (workflowStepId) {
        questionnaireSteps.push(
          <ManageAppointmentCard
            key={workflowStepId}
            appointment={upcomingAppointment}
            workflowStepId={workflowStepId}
          />,
        );
      }
    }
  }

  if (incompleteSteps.length) {
    if (
      // this is seriously gross, TODO fix this whole damn component
      incompleteSteps.map(({ type }) => type.includes('followup' || 'post'))
        .length
    ) {
      questionnaireSteps.push(
        <div key={1} className="my-2 font-bold text-gray-700">
          Assessments
        </div>,
      );
    } else {
      questionnaireSteps.push(
        <div key={1} className="my-2 font-bold text-gray-700">
          Next steps
        </div>,
      );
    }
  }

  incompleteSteps
    .map(({ type, id }) => {
      if (type === AllWorkflowSteps['InitialAssessment'].type) {
        questionnaireSteps.push(<BookAppointmentCard id={id} key={id} />);
        roadmapStep = '2';
      }

      const questionnaireRoute = Object.values(AllWorkflowSteps).find(
        (step) => step.type === type,
      )?.route;

      if (questionnaireRoute) {
        if (questionnaireRoute != 'med-response' || isMedResponseCardEnabled) {
          questionnaireSteps.push(
            <GenericQuestionnaireCard
              key={id}
              id={id}
              questionnaireRoute={questionnaireRoute}
              type={type}
            />,
          );
          if (questionnaireRoute !== 'screening') {
            roadmapStep = '3';
          }
        }
      }

      return <GenericStepCard step={type} id={id} key={id} />;
    })
    .filter((step) => step !== null);

  if (!incompleteSteps.length) {
    roadmapStep = '4';
  }

  if (showNewDashboard === 'show') {
    return <NewDashboardPage />;
  }

  if (stepsLoading || profileLoading) {
    return <LoadingPage />;
  }

  return (
    <Layout narrow>
      <div className={imageLoaded ? 'block' : 'hidden'}>
        <div className="mb-10 flex flex-col justify-center gap-2">
          {questionnaireSteps}
          {!incompleteSteps.length && <AssessmentCompleteCard />}
          <RoadmapCard step={roadmapStep} setImageLoaded={setImageLoaded} />
        </div>
      </div>
    </Layout>
  );
};

export default DashboardPage;
