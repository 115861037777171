import { useGetMeQuery } from '@pm/graphql';
import { format as dateFormat, parseISO } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';
import { FunctionComponent } from 'react';

type Props = {
  value: string;
  format: string;
};

export const DateTime: FunctionComponent<Props> = ({ value, format }) => {
  const { data } = useGetMeQuery();
  let locale = enCA;
  if (data?.me?.locale === 'fr') {
    locale = frCA;
  }

  return <>{dateFormat(parseISO(value), format, { locale })}</>;
};
