import { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import clsx from 'clsx';

export type ButtonProps = {
  onClick?: () => void;
  className?: string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'dark'
    | 'disabled'
    | 'whiteToGrey'
    | 'grey';
  disabled?: boolean;
  submit?: boolean;
  title?: string | undefined;
  icon?: ReactElement;
  onMouseEnter?: () => void;
};

export const Button: FunctionComponent<PropsWithChildren<ButtonProps>> = ({
  children,
  onClick,
  className,
  variant = 'primary',
  disabled = false,
  submit = false,
  title,
  icon,
  onMouseEnter,
}) => {
  if (disabled) {
    variant = 'disabled';
  }
  return (
    <button
      type={submit ? 'submit' : 'button'}
      role="button"
      title={title}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      className={clsx(
        className,
        'h-12 w-full rounded-lg px-5 text-center font-medium focus:ring-4',
        icon ? 'inline-flex items-center justify-center' : 'inline-block',
        variant === 'primary' &&
          'bg-button-primary-background hover-hover:hover:bg-button-primary-background-hover focus:bg-button-primary-background-focus text-white',
        variant === 'secondary' &&
          'hover-hover:hover:bg-gray-100 border-2 border-gray-700 bg-white text-gray-700 focus:bg-gray-200',
        variant === 'tertiary' &&
          'hover-hover:hover:bg-gray-100 hover-hover:hover:text-gray-700 bg-white text-gray-700 focus:bg-gray-200',
        variant === 'dark' &&
          'hover-hover:hover:bg-gray-600 bg-gray-700 text-white focus:bg-gray-500',
        variant === 'disabled' &&
          'text-button-disabled-foreground bg-button-disabled-background hover-hover:hover:cursor-not-allowed',
        variant === 'whiteToGrey' &&
          'h-14 w-full rounded-lg border border-gray-500 bg-white px-5 py-4 font-medium text-gray-500 transition duration-200 ease-in-out focus:bg-gray-500 focus:text-white focus:ring-0',
        variant === 'grey' &&
          'h-14 w-full rounded-lg bg-gray-200 px-5 py-4 font-medium text-gray-700 focus:ring-0',
      )}
    >
      {icon}
      {children}
    </button>
  );
};
