import { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

type Props = {
  to: string;
  icon: ReactElement;
  close?: () => void;
  desktop?: boolean;
  className?: string;
  badge?: JSX.Element | null;
  onClick?: () => void;
};

export const MenuLink: FunctionComponent<PropsWithChildren<Props>> = ({
  to,
  icon,
  desktop,
  badge,
  close,
  className,
  children,
  onClick,
}) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      clsx(
        'hover:text-menulink-hover-foreground hover:bg-menulink-hover-background group flex items-center py-4 pr-2 font-medium text-gray-700 transition focus:outline-none',
        desktop ? 'text-sm leading-5' : 'text-base leading-6',
        isActive && 'bg-menulink-active-background',
        className,
      )
    }
    onClick={() => {
      close && close();
      onClick && onClick();
    }}
  >
    <span>{icon}</span>
    {children}
    {badge && <div className="w-full">{badge}</div>}
  </NavLink>
);
