import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { isValid, parseISO } from 'date-fns';
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
  BackArrow,
  Card,
  Datepicker,
  FlowbiteField,
  Checkbox,
  getDay,
  getMonth,
  getYear,
  isUserEighteen,
  LoadingPage,
  SubmitButton,
  usePatientProfile,
  useUpdatePatientProfile,
} from '@pm/core';
import { Layout } from '../../../layout';
import { useIsProfileComplete } from '../../../hooks/profile';

export type FridaSignupResults = {
  province?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  email?: string;
  password?: string;
  phone?: string;
  results?: string;
};

const CompleteProfilePage = () => {
  const { t } = useTranslation('frida-signup-questionnaire');
  const navigate = useNavigate();
  const { profile } = usePatientProfile();
  const [updateProfile] = useUpdatePatientProfile();
  const day = getDay(profile?.profile?.dateOfBirth);
  const month = getMonth(profile?.profile?.dateOfBirth);
  const year = getYear(profile?.profile?.dateOfBirth);
  const { workflowStepId, appointmentType, appointmentDate } = useParams();
  const profileComplete = useIsProfileComplete();

  if (!profile) {
    return <LoadingPage />;
  }

  let nextPath = `/appointment/${workflowStepId}/${appointmentType}/${appointmentDate}`;
  if (!profile.profile?.hasPaymentMethod) {
    nextPath = `/services/${workflowStepId}/${appointmentType}/${appointmentDate}`;
  }

  if (profileComplete) {
    navigate(nextPath);
  }

  const initialState = {
    firstName: profile.firstName ?? '',
    lastName: profile.lastName ?? '',
    dateOfBirth: profile.profile?.dateOfBirth ?? '',
    bdayDay: (day.length === 1 ? `0${day}` : day) ?? '',
    bdayMonth: (month.length === 1 ? `0${month}` : month) ?? '',
    bdayYear: year ?? '',
    phone: profile.phone.rawNumber ?? '',
    voicemailConsent: profile.voicemailConsent,
  };

  const schema = Yup.object({
    firstName: Yup.string().required(t('Details.FirstNameRequired')),
    lastName: Yup.string().required(t('Details.LastNameRequired')),
    phone: Yup.string()
      .required(t('Details.PhoneRequired'))
      .test('valid-phone', t('Details.PhoneValid'), (value) => {
        return value ? isValidPhoneNumber(value, 'CA') : false;
      }),
    dateOfBirth: Yup.mixed()
      .required(t('Details.DateOfBirthRequired'))
      .test('valid-date', t('Details.DateOfBirthValid'), (value) => {
        return value ? isValid(value) || isValid(parseISO(value)) : false;
      })
      .test('adult-user', t('Details.DateOfBirthAdult'), (value) => {
        return isUserEighteen(value);
      }),
  });

  return (
    <Formik
      validateOnMount
      initialValues={initialState}
      validationSchema={schema}
      onSubmit={async (values) => {
        await updateProfile({
          variables: {
            input: {
              firstName: values.firstName,
              lastName: values.lastName,
              dateOfBirth: values.dateOfBirth,
              phoneNumber: values.phone,
              voicemailConsent: values.voicemailConsent,
            },
          },
          refetchQueries: ['GetMePatient'],
          awaitRefetchQueries: true,
        })
          .then(() => {
            navigate(nextPath);
          })
          .catch(() => {
            toast.error(t('Error'));
          });
      }}
    >
      <Form className="text-gray-900">
        <Layout narrow>
          <BackArrow
            onClick={() => navigate(`/appointment/${workflowStepId}`)}
          />
          <Card className="mt-4 mb-5 space-y-4 p-4">
            <div className="text-2xl font-medium">
              {t('CompleteProfileTitle')}
            </div>
            <div className="text-xl text-gray-500">
              {t('ProfileCompletionText')}
            </div>
          </Card>
          <Card className="mb-5 p-4">
            {/* Complete Frida account */}
            <FlowbiteField
              name="firstName"
              label={t('Details.FirstName')}
              placeholder={t('Details.FirstNamePlaceholder')}
              dataCy="signupFirstName"
              autoComplete="given-name"
            />
            <FlowbiteField
              name="lastName"
              label={t('Details.LastName')}
              placeholder={t('Details.LastNamePlaceholder')}
              dataCy="signupLastName"
              autoComplete="family-name"
            />
            <label
              htmlFor="dateOfBirth"
              className="mb-0 text-sm font-medium text-gray-500"
            >
              {t('Details.DateOfBirth')}
            </label>
            <Datepicker
              name="dateOfBirth"
              initialDate={profile.profile?.dateOfBirth ?? null}
            />
            <FlowbiteField
              name="phone"
              type="tel"
              label={t('Details.Phone')}
              placeholder={t('Details.PhonePlaceholder')}
              dataCy="signupPhone"
              autoComplete="tel-national"
            />
            <Checkbox name="voicemailConsent" className="mb-3">
              {t('Details.VoicemailConsent')}
            </Checkbox>
            <div className="mt-9">
              <SubmitButton>{t('SubmitCompleteAppointment')}</SubmitButton>
            </div>
          </Card>
        </Layout>
      </Form>
    </Formik>
  );
};

export default CompleteProfilePage;
