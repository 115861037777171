import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HomeIcon,
  LogoutIcon,
  MailIcon,
  StarIcon,
  UserIcon,
  ClipboardListIcon,
} from '@heroicons/react/solid';
import { MenuBadge, MenuLink, useAuth } from '@pm/core';
import { MenuContext } from '@pm/core';
import {
  useGetMessageStatisticsQuery,
  useGetMyWorkflowStepsQuery,
} from '@pm/graphql';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { AllWorkflowSteps } from '../pages/DashboardPage/types';

export const SidebarContent = () => {
  const { t } = useTranslation('global');
  const { logout } = useAuth();
  const { data } = useGetMessageStatisticsQuery();
  const { setSidebarOpen, isDesktop } = useContext(MenuContext);
  const wfs = useGetMyWorkflowStepsQuery();
  const LDClient = useLDClient();
  const showPrescriptionPage = LDClient?.variation(
    'show-frida-prescriptions',
    false,
  );

  const messages = data?.messageStatistics.conversations.myUnreadCount;

  const iconStyle = 'h-5';
  const iconWrapper = (icon: ReactElement) => (
    <div className="px-3">{icon}</div>
  );

  let showMessages = true;
  if (wfs.data?.me?.currentBrandMembership?.screenedOutStep) {
    showMessages = false;
  }
  wfs.data?.me?.workflowSteps?.forEach(({ type, completedAt }) => {
    if (type === AllWorkflowSteps['Screening'].type) {
      if (!completedAt || completedAt === null) {
        showMessages = false;
      }
    }
  });

  const close = () => {
    if (!isDesktop) {
      setSidebarOpen(false);
    }
  };

  return (
    <nav className="flex-1 space-y-1">
      <MenuLink
        to="/"
        icon={iconWrapper(<HomeIcon className={iconStyle} />)}
        close={close}
      >
        {t('Dashboard')}
      </MenuLink>
      {showPrescriptionPage && (
        <MenuLink
          to="/assessments"
          icon={iconWrapper(<ClipboardListIcon className={iconStyle} />)}
          close={close}
          onClick={() => {
            window.analytics.track('Visit assessment page clicked');
          }}
        >
          {t('Assessments')}
        </MenuLink>
      )}
      {showMessages && (
        <MenuLink
          to="/conversations"
          icon={iconWrapper(<MailIcon className={iconStyle} />)}
          close={close}
          badge={
            messages ? (
              <MenuBadge
                type="messages"
                text={messages ? messages.toString() : ''}
                className="float-right"
              />
            ) : null
          }
        >
          {t('Messages')}
        </MenuLink>
      )}
      {showPrescriptionPage && (
        <MenuLink
          to="/prescription-record"
          icon={
            <img src="/images/pill.svg" className="mx-3 h-5" alt="Pill icon" />
          }
          close={close}
          onClick={() => {
            window.analytics.track('Visit prescription record page clicked');
          }}
        >
          {t('Prescriptions')}
        </MenuLink>
      )}
      <MenuLink
        to="/credit-card"
        icon={iconWrapper(<StarIcon className={iconStyle} />)}
        close={close}
      >
        {t('Membership')}
      </MenuLink>
      <MenuLink
        to="/profile"
        icon={iconWrapper(<UserIcon className={iconStyle} />)}
        close={close}
      >
        {t('Profile')}
      </MenuLink>
      <div className="w-full border-b" />
      <button
        className="hover-hover:hover:bg-menulink-hover-background group flex w-full items-center py-4 pr-2 font-medium text-gray-700 transition hover:text-white focus:outline-none"
        onClick={logout}
      >
        {iconWrapper(<LogoutIcon className={iconStyle} />)}
        {t('Logout')}
      </button>
    </nav>
  );
};
