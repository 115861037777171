import { Routes, Route, Navigate } from 'react-router-dom';
import {
  ConversationRedirectPage,
  LoadingPage,
  PatientProfileProvider,
  useProfile,
} from '@pm/core';
import CreditCardPage from '../pages/CreditCardPage';
import PatientSettingsPage from '../pages/PatientSettingsPage';
import { AsrsRoutes } from '../pages/Questionnaires/ASRS';
import { Gad7Routes } from '../pages/Questionnaires/GAD7';
import { WfirsRoutes } from '../pages/Questionnaires/WFIRS';
import { Phq9Routes } from '../pages/Questionnaires/PHQ9';
import { ScreeningRoutes } from '../pages/Questionnaires/Screening';
import { PreConsultRoutes } from '../pages/Questionnaires/PreConsult';
import { MedResponseRoutes } from '../pages/Questionnaires/MedicationResponse';
import AppointmentPage from '../pages/AppointmentPage/AppointmentPage';
import AppointmentConfirmationPage from '../pages/AppointmentPage/AppointmentConfirmationPage';
import ComposePage from '../pages/ComposePage';
import ConversationsPage from '../pages/ConversationsPage';
import ConversationPage from '../pages/ConversationPage';
import ManageAppointmentPage from '../pages/AppointmentPage/ManageAppointmentPage';
import AppointmentCancellationPage from '../pages/AppointmentPage/AppointmentCancellationPage';
import CompleteProfilePage from '../pages/Questionnaires/Signup/CompleteProfilePage';
import CreditCardSignupPage from '../pages/Questionnaires/Signup/CreditCardSignupPage';
import ServiceSelectionPage from '../pages/AppointmentPage/ServiceSelectionPage';
import PrescriptionRecord from '../pages/Prescriptions/PrescriptionRecord';
import { PatientProfilePage } from '../pages/PatientSettingsPage/PatientProfilePage';
import AssessmentsPage from '../pages/AssessmentsPage';
import DashboardPage from '../pages/DashboardPage';

const PatientApp = () => {
  const { profile } = useProfile();

  // if we don't have a full profile yet we probably just signed up
  // wait for the profile to be filled and then display the app
  if (
    !profile?.firstName &&
    !profile?.lastName &&
    !profile?.locale &&
    !profile?.phone
  ) {
    return <LoadingPage />;
  }

  return (
    <PatientProfileProvider>
      <Routes>
        <Route path="/" element={<DashboardPage />} />

        {/* questionnaires */}
        <Route path="/asrs/:id/*" element={<AsrsRoutes auth bothParts />} />
        <Route path="/asrs-b/:id/*" element={<AsrsRoutes auth />} />
        <Route path="/gad7/:id/*" element={<Gad7Routes />} />
        <Route
          path="/assessment/gad7/:id/*"
          element={<Gad7Routes isAssessment />}
        />
        <Route path="/wfirs/:id/*" element={<WfirsRoutes />} />
        <Route
          path="/assessment/wfirs/:id/*"
          element={<WfirsRoutes isAssessment />}
        />
        <Route path="/phq9/:id/*" element={<Phq9Routes />} />
        <Route
          path="/assessment/phq9/:id/*"
          element={<Phq9Routes isAssessment />}
        />
        <Route
          path="/assessment/med-response/:id/*"
          element={<MedResponseRoutes isAssessment />}
        />
        <Route
          path="/screening/:workflowStepId/*"
          element={<ScreeningRoutes />}
        />
        <Route
          path="/pre-consult/:workflowStepId/*"
          element={<PreConsultRoutes />}
        />
        <Route path="/med-response/:id/*" element={<MedResponseRoutes />} />

        {/* assessments */}
        <Route
          path="/assessment/asrs/:id/*"
          element={<AsrsRoutes auth bothParts isAssessment />}
        />

        {/* appointments */}
        <Route
          path="appointment/:workflowStepId"
          element={<AppointmentPage />}
        />
        <Route
          path="completeProfile/:workflowStepId/:appointmentType/:appointmentDate"
          element={<CompleteProfilePage />}
        />
        <Route
          path="appointment/:workflowStepId/:bookingType/:date"
          element={<AppointmentConfirmationPage />}
        />
        <Route
          path="appointment/reschedule/:workflowStepId/:appointmentId/:bookingType/:date"
          element={<AppointmentConfirmationPage reschedule />}
        />
        <Route
          path="appointment/manage/:workflowStepId/:id/:date/:duration"
          element={<ManageAppointmentPage />}
        />
        <Route
          path="appointment/reschedule/:workflowStepId/:id"
          element={<AppointmentPage reschedule />}
        />
        <Route
          path="appointment/cancel/:id"
          element={<AppointmentCancellationPage />}
        />
        <Route path="credit-card" element={<CreditCardPage />} />
        <Route
          path="services/:workflowStepId/:appointmentType/:appointmentDate"
          element={<ServiceSelectionPage />}
        />
        <Route
          path="signup-credit-card/:workflowStepId/:appointmentType/:appointmentDate"
          element={<CreditCardSignupPage />}
        />
        <Route path="compose" element={<ComposePage />} />
        <Route path="/conversations" element={<ConversationsPage />} />
        <Route
          path="/conversations/:conversationId"
          element={<ConversationPage />}
        />
        <Route
          path="/messages/:messageId"
          element={<ConversationRedirectPage />}
        />
        <Route path="/assessments" element={<AssessmentsPage />} />
        <Route path="settings" element={<PatientSettingsPage />} />
        <Route path="profile" element={<PatientProfilePage />} />
        <Route path="prescription-record" element={<PrescriptionRecord />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </PatientProfileProvider>
  );
};

export default PatientApp;
