import {
  useState,
  useEffect,
  createContext,
  useMemo,
  Dispatch,
  FunctionComponent,
} from 'react';
import { Formik, FormikValues } from 'formik';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/solid';
import {
  GetMePatientDocument,
  GetMyWorkflowStepsDocument,
  useCompleteAssessmentMutation,
  useCompleteWorkflowStepMutation,
} from '@pm/graphql';
import { Card, BackArrow, usePatientProfile } from '@pm/core';
import { useIsQuestionnaireComplete } from '../../../hooks/workflowSteps';
import { SignupWorkflowSteps } from '../../DashboardPage/types';
import { Layout } from '../../../layout';
import WfirsRoutes from './routes';

/*
Scoring:
  number of items scored 2 or 3
  or
  total score
  or
  mean score
*/
type Props = {
  isAssessment?: boolean;
};

export type WfirsSectionType = {
  Intro: string;
  Start: string;
  Questions: object;
  SkipSectionConfirmation?: string;
};

export const WfirsProgressContext = createContext<{
  step: number;
  setStep: Dispatch<number>;
  endStep: number;
  setEndStep: Dispatch<number>;
  section: string;
  setSection: Dispatch<string>;
  setError: Dispatch<string>;
}>({
  step: 0,
  setStep: useState,
  endStep: 0,
  setEndStep: useState,
  section: '',
  setSection: useState,
  setError: useState,
});

const WfirsQuestionnaire: FunctionComponent<Props> = ({
  isAssessment = false,
}) => {
  const { t } = useTranslation('frida-wfirs-questionnaire');
  const [completeMutation] = useCompleteWorkflowStepMutation();
  const [completeAssessment] = useCompleteAssessmentMutation();
  const { id } = useParams(); // Either the workflow step id or the assessment id
  const questions: string[] = useMemo(() => [], []);
  const sections: [string, WfirsSectionType | { Questions: object }][] =
    Object.entries(t('Sections', { returnObjects: true }));
  sections.map(([, { Questions }]) => {
    Object.keys(Questions).map((key) => {
      if (!questions.includes(key)) questions.push(key);
    });
  });
  const initialValues = Object.assign(
    {},
    ...(questions ?? []).map((q) => ({
      [q]: '',
    })),
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shape: any = {};
  questions.forEach((q: string) => {
    shape[q] = Yup.string();
  });
  const schema = Yup.object().shape(shape);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [step, setStep] = useState(0);
  const [error, setError] = useState('');
  const [section, setSection] = useState(sections[0][0]);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [endStep, setEndStep] = useState(0);

  let firstPage = `/wfirs/${id}/${sections[0][0]}/${questions[0]}`;
  if (isAssessment) {
    firstPage = '/assessment' + firstPage;
  }
  const { profile } = usePatientProfile();
  const questionnaireStep = profile?.workflowSteps.find(
    (step) => step.id === id,
  );
  const questionnaireCompleted = useIsQuestionnaireComplete(
    questionnaireStep?.type ?? SignupWorkflowSteps['WFIRS'].type,
  );

  useEffect(() => {
    if (questionnaireCompleted && !isAssessment) {
      navigate('/', { replace: true, state: null });
    }
    if (!step) {
      setStep(1);
      navigate(firstPage, { replace: true });
    }
  }, [firstPage, navigate, step, questionnaireCompleted, isAssessment]);

  useEffect(() => {
    const newSection = sections.find((s) => s[0] === section);
    if (newSection) {
      setSectionIndex(sections.indexOf(newSection));
    }
    setEndStep(
      Object.values(
        (sections.find((s) => s[0] === section) ?? [])[1]?.Questions ?? [],
      )?.length ?? 0,
    );
  }, [section, sections, setEndStep]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values: FormikValues) => {
        if (id) {
          if (isAssessment) {
            await completeAssessment({
              variables: {
                input: {
                  id,
                  data: values,
                },
              },
              refetchQueries: [
                { query: GetMePatientDocument }, // TODO: remove this when NewDashboard feature flag is off
                { query: GetMyWorkflowStepsDocument },
              ],
              awaitRefetchQueries: true,
            }).then(() => {
              window.analytics.track('WFIRS End');
              navigate('/', { replace: true });
            });
          } else {
            await completeMutation({
              variables: {
                input: {
                  workflowStepId: id,
                  completionParams: values,
                },
              },
              refetchQueries: [
                { query: GetMePatientDocument }, // TODO: remove this when NewDashboard feature flag is off
                { query: GetMyWorkflowStepsDocument },
              ],
              awaitRefetchQueries: true,
            }).then(() => {
              window.analytics.track('WFIRS End');
              navigate('/', { replace: true });
            });
          }
        } else {
          setError('Something went wrong on our end. Please contact support.');
        }
      }}
    >
      <Layout narrow>
        <Card>
          <div className="grid grid-flow-col">
            {/* back button */}
            {!pathname.includes('intro') && (
              <BackArrow
                className="mb-4"
                onClick={() => {
                  setStep(step - 1);
                  navigate(-1);
                }}
              />
            )}
            <XIcon
              className="hover-hover:hover:cursor-pointer ml-auto mb-4 h-5 text-gray-500"
              onClick={() => {
                navigate('/dashboard');
              }}
            />
          </div>
          <WfirsProgressContext.Provider
            value={{
              step,
              setStep,
              endStep,
              setEndStep,
              section,
              setSection,
              setError,
            }}
          >
            <Outlet />
          </WfirsProgressContext.Provider>
          {/* question counter */}
          {!pathname.includes('intro') && (
            <div className="mt-3.5 text-center text-sm font-normal leading-5 text-gray-500">
              Section {sectionIndex + 1} - Question {step} of {endStep}
            </div>
          )}
          {!!error && (
            <div className="my-3 text-lg text-red-500">
              {error}
              <br />
              <a href="mailto:support@gofreddie.com" className="underline">
                support@gofreddie.com
              </a>
            </div>
          )}
        </Card>
      </Layout>
    </Formik>
  );
};

export { Question, WfirsSection, WfirsIntro } from './pages';
export { WfirsRoutes };
export default WfirsQuestionnaire;
