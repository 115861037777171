import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AcuityBooking,
  Province,
  useGetAvailableAppointmentTimesQuery,
} from '@pm/graphql';
import FiltersManager from './AppointmentTimesFilter/FiltersManager';
import { intlFormat, parseISO } from 'date-fns';
import { Button, SubmitButton, usePatientProfile } from '@pm/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useIsProfileComplete } from '../hooks/profile';

type Props = {
  date: string;
  province: Province;
  filtersManager: FiltersManager;
  appointmentId: string;
  bookingType: AcuityBooking;
  workflowStepId: string;
};

const SHOW_MORE_RANGE = 4;

const AppointmentDayTimes: FunctionComponent<Props> = ({
  date,
  province,
  filtersManager,
  appointmentId,
  bookingType,
  workflowStepId,
}) => {
  const [numOfTimesToShow, setNumOfTimesToShow] = useState(SHOW_MORE_RANGE);
  const profileComplete = useIsProfileComplete();
  const { profile } = usePatientProfile();
  const { data: currentDayTimes, loading } =
    useGetAvailableAppointmentTimesQuery({
      variables: {
        date: date,
        province: province,
        bookingType: bookingType,
      },
    });

  const { t } = useTranslation('appointment');

  const filteredAvailableAppointmentTimes = filtersManager.filterTimes(
    currentDayTimes?.availableAppointmentTimes ?? [],
  );

  const schema = Yup.object({
    appointmentTime: Yup.string().required(),
  });
  const navigate = useNavigate();

  const availableTimes = filteredAvailableAppointmentTimes.length;

  const formatTime = (time: string) => {
    return intlFormat(parseISO(time), {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    }).toString();
  };

  const setButtonFormatting = (
    appointmentTime: string,
    selectedTime: string,
  ) => {
    if (appointmentTime == selectedTime) {
      return 'mt-4 bg-gray-500 text-white';
    }
    return 'mt-4 ';
  };

  return (
    <Formik
      validateOnMount
      validationSchema={schema}
      initialValues={{ appointmentTime: '' }}
      onSubmit={({ appointmentTime }) => {
        const nextRoute = appointmentId
          ? `/appointment/reschedule/${workflowStepId}/${appointmentId}/${bookingType}/${appointmentTime}`
          : !profileComplete
          ? `/completeProfile/${workflowStepId}/${bookingType}/${appointmentTime}`
          : !profile?.profile?.hasPaymentMethod
          ? `/services/${workflowStepId}/${bookingType}/${appointmentTime}`
          : `/appointment/${workflowStepId}/${bookingType}/${appointmentTime}`;
        navigate(nextRoute);
      }}
    >
      {({ setFieldValue, values, isValidating, isValid }) => (
        <Form className="w-full">
          {loading ? (
            <div className="mt-3 w-full text-center">
              <FontAwesomeIcon icon={faCircleNotch} spin size="4x" />
            </div>
          ) : (
            <div className="mt-1">
              {filteredAvailableAppointmentTimes
                ?.slice(0, numOfTimesToShow)
                .map((time) => (
                  <Button
                    key={time.time}
                    variant="whiteToGrey"
                    onClick={() => {
                      setFieldValue('appointmentTime', time.time);
                    }}
                    className={setButtonFormatting(
                      values.appointmentTime,
                      time.time,
                    )}
                  >
                    {formatTime(time.time)}
                  </Button>
                ))}
              {availableTimes > numOfTimesToShow && (
                <Button
                  variant="whiteToGrey"
                  onClick={() =>
                    setNumOfTimesToShow(numOfTimesToShow + SHOW_MORE_RANGE)
                  }
                  className="mt-4 focus:bg-white focus:text-gray-500"
                >
                  {t('MoreTimes')}
                </Button>
              )}
            </div>
          )}
          <SubmitButton
            className="mt-10 border-none"
            disabled={
              loading || isValidating || !isValid || !values.appointmentTime
            }
          >
            {' '}
            {t('SelectTime')}{' '}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default AppointmentDayTimes;
