import { useEffect, useState, FunctionComponent, Dispatch } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ApolloQueryResult } from '@apollo/client';

import {
  useCreateStripeSetupIntentMutation,
  Exact,
  GetPatientProfileQuery,
} from '@pm/graphql';
import { StyledField, SubmitButton } from '@pm/core';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { ConfirmationDialog } from './ConfirmationDialog';
import { Spacer } from '@pm/core';

type Props = {
  refetchProfile?: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetPatientProfileQuery>>;
  userId?: string; // required if used by a provider
  setDialogOpen?: Dispatch<boolean>;
  onSubmit?: () => void;
};

export const CreditCardForm: FunctionComponent<Props> = ({
  refetchProfile,
  userId,
  setDialogOpen,
  onSubmit,
}) => {
  const { t } = useTranslation('credit-card');
  const stripe = useStripe();
  const elements = useElements();
  const [create] = useCreateStripeSetupIntentMutation({
    variables: {
      input: { userId },
    },
  });
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cardExpiryValid, setCardExpiryValid] = useState(false);
  const [cardCvcValid, setCardCvcValid] = useState(false);
  const [cardErrorMessage, setCardErrorMessage] = useState<null | string>(null);
  const handleChange = (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent,
  ) => {
    if (event.elementType === 'cardNumber') {
      setCardNumberValid(event.complete);
    }
    if (event.elementType === 'cardExpiry') {
      setCardExpiryValid(event.complete);
    }
    if (event.elementType === 'cardCvc') {
      setCardCvcValid(event.complete);
    }
    if (event.error?.message) {
      setCardErrorMessage(t('CardErrorMessage'));
    }
  };
  const isProvider = !!userId;

  useEffect(() => {
    if (cardNumberValid && cardExpiryValid && cardCvcValid) {
      setCardErrorMessage(null);
    }
  }, [cardNumberValid, cardExpiryValid, cardCvcValid]);

  const schema = Yup.object().shape({
    postalCode: Yup.string()
      .required('You must enter a postal code')
      .min(6, 'Your postal code must be at least 6 characters long'),
  });

  return (
    <>
      <Formik
        initialValues={{
          postalCode: '',
        }}
        validationSchema={schema}
        validateOnMount={true}
        onSubmit={(values, { setSubmitting }) => {
          if (!stripe || !elements) {
            toast.error('Stripe Elements failed to load');
            return;
          }

          const cardElement = elements.getElement('cardNumber');
          if (!cardElement) {
            toast.error('Stripe card element not found');
            return;
          }

          create().then((data) => {
            if (!data?.data?.createStripeSetupIntent?.clientSecret) {
              toast.error('Creating stripe setup intent failed');
              return;
            }

            stripe
              .confirmCardSetup(
                data.data.createStripeSetupIntent.clientSecret,
                {
                  payment_method: {
                    card: cardElement,
                    billing_details: {
                      address: {
                        postal_code: values.postalCode,
                      },
                    },
                  },
                },
              )
              .then((result) => {
                if (result.setupIntent?.status === 'succeeded') {
                  window.analytics.track('Payment Info Submitted');

                  if (isProvider) {
                    setDialogOpen && setDialogOpen(false);
                    toast.success('Added patient credit card info', {
                      duration: 4000,
                    });
                  } else {
                    setConfirmationOpen(true);
                  }

                  setSubmitting(false);
                  refetchProfile && refetchProfile();
                  onSubmit && onSubmit();
                } else {
                  toast.error(result.error?.message || 'Error');
                  setSubmitting(false);
                }
              });
          });
        }}
      >
        <Form>
          {!isProvider && (
            <ConfirmationDialog
              open={confirmationOpen}
              onClose={() => setConfirmationOpen(false)}
            />
          )}
          <label className="mb-0 text-sm font-medium text-gray-500">
            {t('CardDetails')}
          </label>
          <div className="relative mt-2">
            <CardNumberElement
              className="rounded-t-lg border border-b-0 border-gray-300 p-4"
              onChange={handleChange}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <div className="grid w-28 grid-cols-4 grid-rows-1 gap-1 sm:w-fit sm:gap-2">
                <img src="/images/visa-logo.svg" alt="Visa" />
                <img src="/images/mc-logo.svg" alt="Mastercard" />
                <img src="/images/amex-logo.svg" alt="Amex" />
                <img src="/images/discover-logo.svg" alt="Discover" />
              </div>
            </div>
          </div>
          <div className="relative flex">
            <CardExpiryElement
              className="w-full rounded-bl-lg border border-r-0 border-gray-300 bg-white p-4"
              onChange={handleChange}
            />
            <CardCvcElement
              className="w-full rounded-br-lg border border-gray-300 bg-white p-4"
              onChange={handleChange}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <img src="/images/cvc-card.svg" alt="CVC" />
            </div>
          </div>
          {cardErrorMessage && (
            <p className="mb-2 mt-3 text-sm text-red-600">{cardErrorMessage}</p>
          )}
          <Spacer size={6} />
          <label className="mb-0 text-sm font-medium text-gray-500">
            {t('PostalCode')}
          </label>
          <StyledField
            name="postalCode"
            placeholder={t('PostalCode')}
            labelStyle="mt-0"
          />
          <SubmitButton
            className="mt-4"
            disabled={!cardNumberValid || !cardExpiryValid || !cardCvcValid}
          >
            {isProvider ? 'Add patient credit card' : t('Submit')}
          </SubmitButton>
        </Form>
      </Formik>
    </>
  );
};
