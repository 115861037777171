import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { LoadingPage } from '@pm/core';

const Segment: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (window.analytics && window.analytics.load) {
      // configure segment
      if (import.meta.env.VITE_ENVIRONMENT === 'production') {
        window.analytics.load('42lByDV38z7qG7ExPJwEVMM2UfJtV2SF');
        console.log('Loaded production Segement');
      } else {
        window.analytics.load('2AiXmmLKRcuBy8Yev56BliCvivJ5tSym');
        console.log('Loaded development Segment');
      }
      setLoaded(true);
      window.analytics.page();
    }
  }, []);

  if (!loaded) {
    return <LoadingPage />;
  }

  return <>{children}</>;
};

export default Segment;
