import Filter from './Filter';
import { isWeekend } from 'date-fns';

class WeekendFilter extends Filter {
  isInRange(day: Date) {
    return isWeekend(day);
  }
}

export default WeekendFilter;
