import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PreConsultQuestionnaire, { Question } from '.';

const PreConsultRoutes = () => {
  const { t } = useTranslation('frida-pre-consult-questionnaire');
  const questions = Object.entries(t('Questions', { returnObjects: true }));
  const preConsultQuestionRoutes = [];

  for (const [key, value] of Object.entries(questions)) {
    preConsultQuestionRoutes.push(
      <Route
        key={key}
        path={value[0]}
        element={
          <Question
            name={value[0]}
            question={value[1].Question}
            options={value[1].Inputs}
            textInputLabel={value[1].TextInputLabel}
            textInputTrigger={value[1].TextInputTrigger}
            required={value[1].Optional !== 'True'}
            pageType={value[1].PageType}
            nextPath={
              parseInt(key) + 1 < questions.length
                ? questions[parseInt(key) + 1][0]
                : questions[questions.length - 1][0]
            }
          />
        }
      />,
    );
  }

  return (
    <Routes>
      <Route path="/" element={<PreConsultQuestionnaire />}>
        {preConsultQuestionRoutes}
      </Route>
    </Routes>
  );
};

export default PreConsultRoutes;
