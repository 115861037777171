import clsx from 'clsx';
import { FunctionComponent, ReactNode } from 'react';

type Props = {
  selected: boolean;
  text: ReactNode;
  onClick: () => void;
  vertical?: boolean;
  disabled?: boolean;
  className?: string;
};

export const ToggleButton: FunctionComponent<Props> = ({
  selected,
  text,
  onClick,
  className,
  vertical = false,
  disabled = false,
}) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={clsx(
      'w-full rounded-lg border border-gray-500 bg-white px-6 py-3.5 font-normal transition duration-200 ease-in-out',
      selected
        ? 'bg-selected-button-background text-selected-button-foreground'
        : 'bg-unselected-button-background text-unselected-button-foreground',
      vertical ? 'block' : '',
      className,
    )}
    role="radio"
    aria-checked={selected ? 'true' : 'false'}
  >
    {text}
  </button>
);
