import {
  AvailableAppointmentDate,
  AvailableAppointmentTime,
} from '@pm/graphql';
import Filter from './Filter';
import SelectAllFilter from './SelectAllFilter';
import TimeFilter from './TimeFilter';
import WeekendFilter from './WeekendFilter';
import { parseISO } from 'date-fns';

class FiltersManager {
  selectAllFilter: SelectAllFilter;
  timeFilters: TimeFilter[];
  weekendFilter: WeekendFilter;

  filters: Filter[];

  constructor() {
    this.selectAllFilter = new SelectAllFilter('SelectAll', this);
    this.timeFilters = [
      new TimeFilter('Early', this, 0, 9),
      new TimeFilter('Morning', this, 9, 12),
      new TimeFilter('Noon', this, 12, 14),
      new TimeFilter('Afternoon', this, 14, 17),
      new TimeFilter('Evening', this, 17, 23),
    ];
    this.weekendFilter = new WeekendFilter('Weekends', this);

    this.filters = [
      this.selectAllFilter,
      ...this.timeFilters,
      this.weekendFilter,
    ];
  }

  filterDays(dates: AvailableAppointmentDate[]) {
    if (this.weekendFilter.selected) {
      dates = dates.filter((day) =>
        this.weekendFilter.isInRange(parseISO(day.date)),
      );
    }
    return dates;
  }

  filterTimes(times: AvailableAppointmentTime[]) {
    if (this.timeFilterSelected()) {
      times = times.filter((time) =>
        this.isInTimeFiltersRange(parseISO(time.time)),
      );
    }
    return times;
  }

  private timeFilterSelected() {
    return this.timeFilters.some((filter) => filter.selected);
  }

  private isInTimeFiltersRange(time: Date) {
    return this.selectedTimeFilters().some((filter) => filter.isInRange(time));
  }

  private selectedTimeFilters() {
    return this.timeFilters.filter((filter) => filter.selected);
  }
}

export default FiltersManager;
