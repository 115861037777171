import FiltersManager from './FiltersManager';

let nextId = 0;

export type Label =
  | 'Early'
  | 'Morning'
  | 'Noon'
  | 'Afternoon'
  | 'Evening'
  | 'Weekends'
  | 'SelectAll';

abstract class Filter {
  readonly id: number;

  constructor(
    readonly label: Label,
    protected filtersManager: FiltersManager,
    public selected = false,
  ) {
    this.id = nextId++;
  }

  toggleSelected() {
    this.selected = !this.selected;
    if (this.selected) {
      this.filtersManager.selectAllFilter.selected = false;
    }
  }
}

export default Filter;
