import { createContext, Dispatch, useState, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Question, Qualified, NotQualified } from './pages';
import {
  GetMePatientDocument,
  GetMyWorkflowStepsDocument,
  useCompleteWorkflowStepMutation,
  PatientWorkflowStepFragment,
} from '@pm/graphql';
import { Card, BackArrow, usePatientProfile } from '@pm/core';
import { ScreeningOutcome } from './ScreeningOutcome';
import Psych from './pages/ScreenedOut/Psych';
import InPersonCare from './pages/ScreenedOut/InPersonCare';
import InPersonPsych from './pages/ScreenedOut/InPersonPsych';
import CompletedRedirector from '../components/CompletedRedirector';
import { SignupWorkflowSteps } from '../../DashboardPage/types';
import { Layout } from '../../../layout';
import ScreeningRoutes from './routes';

export const ScreeningContext = createContext<{
  step: number;
  endStep: number;
  setStep: Dispatch<number>;
}>({
  step: 0,
  endStep: 0,
  setStep: useState,
});

// service eligibilty screening
const ScreeningQuestionnaire = () => {
  const { t } = useTranslation('frida-screening-questionnaire');
  const { safeRoutes } = new ScreeningOutcome({});
  const navigate = useNavigate();
  const [completeMutation] = useCompleteWorkflowStepMutation();
  const { pathname } = useLocation();
  const [step, setStep] = useState(0);
  const [error, setError] = useState('');
  const { workflowStepId } = useParams();
  const { profile, refetch } = usePatientProfile();
  const workflowSteps = profile?.workflowSteps;

  const workflowStep: PatientWorkflowStepFragment | undefined =
    workflowSteps?.find(({ id }) => id === workflowStepId);
  const results = workflowStep?.completionParams;
  /*
  if user signed up before the PreScreen questionnaire was added,
  keep the first 2 questions - if not, remove them
  */
  const questions: string[] = Object.keys(
    t('Questions', { returnObjects: true }),
  );

  // parse initial values
  const initialValues: { [key: string]: string } = {};
  if (results) {
    questions.forEach((key) => {
      initialValues[key] = results[key] ?? '';
    });
  }

  // build Formik schema
  const shape: { [key: string]: Yup.StringSchema } = {};
  questions.forEach((q: string) => {
    shape[q] = Yup.string();
  });
  const schema = Yup.object().shape(shape);

  const firstQuestionPath = `/screening/${workflowStepId}/${questions[2]}`;

  // tracking
  useEffect(() => {
    const questionNum = parseInt(pathname.split('/')[2]);
    const currentQuestion = questions[questionNum];

    if (currentQuestion) {
      window.analytics.track(currentQuestion);
    }
  }, [pathname, questions]);

  const checkPath = useMemo(() => {
    return safeRoutes.every((route) => !pathname.includes(route));
  }, [pathname, safeRoutes]);

  useEffect(() => {
    const currentQuestion = pathname.split('/')[3];

    if (currentQuestion) {
      const currentQuestionIndex = questions.indexOf(currentQuestion);
      // ensure that the correct question number is shown
      setStep(currentQuestionIndex + 1);
    } else {
      navigate(firstQuestionPath, { replace: true });
    }
  }, [navigate, firstQuestionPath, pathname, questions, setStep]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values: FormikValues) => {
        const outcome = new ScreeningOutcome({
          ...results,
          ...values,
        });
        outcome.parseResults();
        const { pass, requiresMdAssessment, notQualifiedRoute } = outcome;

        const completionParams = {
          requiresMdAssessment,
          screenedOut: !pass,
          ...results,
          ...values,
        };

        if (workflowStepId) {
          await completeMutation({
            variables: {
              input: {
                workflowStepId,
                completionParams,
              },
            },
            refetchQueries: [
              { query: GetMePatientDocument }, // TODO: remove this when NewDashboard feature flag is off
              { query: GetMyWorkflowStepsDocument },
            ],
            awaitRefetchQueries: true,
          })
            .catch((err) => {
              console.error('Error completing wfs:', err);
            })
            .then(async () => {
              // get the id from the payment/appointment workflow step that
              // was created by useCompleteWorkflowStepMutation for the nextRoute
              let appointmentStepId = '';
              let nextRoute;

              await refetch().then(({ data }) => {
                if (data?.me?.workflowSteps) {
                  data?.me?.workflowSteps.forEach((step) => {
                    if (
                      step.type ===
                      SignupWorkflowSteps['InitialAssessment'].type
                    ) {
                      appointmentStepId = step.id;
                    }
                  });
                }

                window.analytics.track('Screening End');

                if (pass) {
                  if (appointmentStepId) {
                    nextRoute = `/appointment/${appointmentStepId}`;
                  } else {
                    nextRoute = '/';
                  }
                } else {
                  nextRoute = `/screening/${workflowStepId}/${notQualifiedRoute}`;
                }

                navigate(nextRoute, { replace: true });
              });
            });
        } else {
          setError('Something went wrong on our end. Please contact support.');
        }
      }}
    >
      <Layout narrow>
        <Card>
          {checkPath && (
            // back button
            <BackArrow
              className="mb-4"
              onClick={() => {
                setStep(step - 1);
                navigate(-1);
              }}
            />
          )}
          <ScreeningContext.Provider
            value={{
              step,
              setStep,
              endStep: questions.length,
            }}
          >
            <Outlet />
          </ScreeningContext.Provider>
          {!!error && (
            <div className="my-3 text-lg text-red-500">
              {error}
              <br />
              <a href="mailto:support@gofreddie.com" className="underline">
                support@gofreddie.com
              </a>
            </div>
          )}
          {/* question counter */}
          {checkPath && (
            <div className="mt-3.5 text-center text-sm font-normal leading-5 text-gray-500">
              Question {step - 2} of {questions.length - 2}
            </div>
          )}
        </Card>
        <CompletedRedirector
          questionnaireType={SignupWorkflowSteps['Screening'].type}
        />
      </Layout>
    </Formik>
  );
};

export {
  Question,
  Qualified,
  NotQualified,
  Psych,
  InPersonPsych,
  InPersonCare,
  ScreeningRoutes,
};
export default ScreeningQuestionnaire;
