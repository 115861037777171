import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  GetPatientProfileDocument,
  useCancelAppointmentMutation,
} from '@pm/graphql';
import { BackArrow, Button, ButtonLink, Card, Dialog, Select } from '@pm/core';
import { Layout } from '../../layout';

const AppointmentCancellationPage = () => {
  const { t } = useTranslation('manage-appointment');
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [cancelAppointment] = useCancelAppointmentMutation();

  const options: string[] = Object.values(
    t('Cancel.Reasons', { returnObjects: true }),
  );
  const schema = Yup.object({
    reason: Yup.string().required(),
  });

  return (
    <Layout narrow>
      <Formik
        initialValues={{ reason: '' }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          window.analytics.track('Consult Cancelled', {
            appointmentId: id,
            cancellationReason: values.reason,
          });
          cancelAppointment({
            variables: { input: { appointmentId: id ?? '' } },
            refetchQueries: [{ query: GetPatientProfileDocument }],
          })
            .then(({ data }) => {
              if (data?.cancelAppointment?.errors.length) {
                data?.cancelAppointment?.errors?.forEach((err) => {
                  if (err?.error) {
                    if (err?.error?.includes('cancel_too_close')) {
                      // should never come to this but last resort error message
                      return setErrors({
                        reason:
                          'You cannot cancel the appointment this close to the appointment date.',
                      });
                    }
                  }
                });
              } else {
                setDialogOpen(true);
              }
            })
            .catch(() => {
              setErrors({
                reason: 'Something went wrong! Please contact support.',
              });
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form className="text-gray-900">
            <BackArrow className="mb-4" onClick={() => navigate(-1)} />
            <Card>
              <div className="text-2xl">{t('Cancel.Title')}</div>
              <div className="mt-5 mb-7 text-xl text-gray-500">
                {t('Cancel.CardText')}
              </div>
              <Select name="reason" options={options} />
              <Button
                submit={true}
                disabled={
                  !values.reason ||
                  (errors.reason?.length && errors.reason?.length > 0) ||
                  isSubmitting
                }
                className="mt-10"
              >
                {t('Cancel.Confirm')}
              </Button>
            </Card>
          </Form>
        )}
      </Formik>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <div className="text-left">
          <div className="text-2xl text-gray-900">{t('Dialog.Title')}</div>
          <div className="mt-5 text-xl text-gray-500">{t('Dialog.Text')}</div>
          <ButtonLink
            to="/"
            variant="dark"
            className="w-84 mt-5"
            onClick={() => setDialogOpen(false)}
          >
            {t('Dialog.Button')}
          </ButtonLink>
        </div>
      </Dialog>
    </Layout>
  );
};

export default AppointmentCancellationPage;
