import { Trans, useTranslation } from 'react-i18next';
import { Card, CardText, CardTitle, Spacer } from '@pm/core';

const NotQualifiedCard = () => {
  const { t } = useTranslation('patient-dashboard');

  return (
    <Card>
      <CardTitle>{t('NotQualified.Title')}</CardTitle>
      {(t('NotQualified.CardText') as string).split('{n}').map((substr) => (
        <>
          <CardText key={substr}>{substr}</CardText>
          <Spacer size={4} />
        </>
      ))}
      <CardText>
        <Trans t={t} i18nKey="NotQualified.LinkText">
          <a
            href="https://caddac.ca/find-a-resource"
            className="text-hyperlink-foreground underline"
          />
        </Trans>
      </CardText>
    </Card>
  );
};

export default NotQualifiedCard;
