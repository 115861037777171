import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ScreeningQuestionnaire, {
  Question,
  Qualified,
  NotQualified,
  Psych,
  InPersonPsych,
  InPersonCare,
} from '.';

const ScreeningRoutes = () => {
  const { t } = useTranslation(['global', 'frida-screening-questionnaire']);
  const questions = Object.entries(
    t('frida-screening-questionnaire:Questions', { returnObjects: true }),
  );
  const answers = [
    { text: t('global:Yes'), value: 'Yes' },
    { text: t('global:No'), value: 'No' },
  ];

  return (
    <Routes>
      <Route path="/" element={<ScreeningQuestionnaire />}>
        <Route path="qualified" element={<Qualified />} />
        <Route path="not-qualified" element={<NotQualified />} />
        <Route path="in-person-psych" element={<InPersonPsych />} />
        <Route path="in-person-care" element={<InPersonCare />} />
        <Route path="psych" element={<Psych />} />
        {questions.map(([key, q], i) => {
          const nextPath =
            i + 1 < questions.length
              ? questions[i + 1][0]
              : questions[questions.length - 1][0];

          return (
            <Route
              key={i}
              path={key}
              element={
                <Question
                  name={key}
                  question={q}
                  options={answers}
                  nextPath={nextPath}
                />
              }
            />
          );
        })}
      </Route>
    </Routes>
  );
};

export default ScreeningRoutes;
