import Filter, { Label } from './Filter';
import FiltersManager from './FiltersManager';
import { set } from 'date-fns';

class TimeFilters extends Filter {
  constructor(
    label: Label,
    filtersManager: FiltersManager,
    private from: number,
    private to: number,
  ) {
    super(label, filtersManager);
  }

  isInRange(time: Date) {
    const from = set(time, {
      hours: this.from,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    const to = set(time, {
      hours: this.to,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    return time >= from && time <= to;
  }
}

export default TimeFilters;
