import { FunctionComponent } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, intlFormat, parseISO } from 'date-fns';
import { Card, BackArrow, ButtonLink } from '@pm/core';
import { Layout } from '../../layout';

const ManageAppointmentPage: FunctionComponent = () => {
  const { t } = useTranslation('manage-appointment');
  const { id, workflowStepId, date, duration } = useParams();
  const navigate = useNavigate();

  if (!id || !date) {
    navigate('/');
    return null;
  }

  const day = format(parseISO(date), 'EEEE, MMMM d');
  const time = format(parseISO(date), 'h:mm aaa');
  const timezone = intlFormat(parseISO(date), {
    timeZoneName: 'short',
  })
    .split(', ')
    .pop();

  const cardText: string = (t('Manage.CardText') as string)
    .replace('{duration}', duration ?? '')
    .replace('{date}', day)
    .replace('{time}', time)
    .replace('{timezone}', timezone ?? '');

  return (
    <Layout narrow>
      <div className="flex flex-col text-gray-900">
        <Link to="/">
          <BackArrow />
        </Link>
        <Card className="mt-5">
          <div className="text-2xl">{t('Manage.Title')}</div>
          {cardText.split('{n}').map((substr) => (
            <div key={substr} className="mt-5 text-xl text-gray-500">
              {substr}
            </div>
          ))}
          <div className="mt-10"></div>
          <ButtonLink
            to={`/appointment/reschedule/${workflowStepId}/${id}`}
            className="mb-5"
          >
            {t('Manage.Reschedule')}
          </ButtonLink>
          <ButtonLink to={`/appointment/cancel/${id}`} variant="secondary">
            {t('Manage.Cancel')}
          </ButtonLink>
        </Card>
      </div>
    </Layout>
  );
};

export default ManageAppointmentPage;
