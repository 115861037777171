import { FunctionComponent, useState } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { StyledField, SubmitButton } from '@pm/core';
import { useCreateMessageMutation } from '@pm/graphql';
import AttachmentUploader, { Attachment } from './AttachmentUploader';

type Props = {
  toId: string;
  userId: string;
  parentId: string;
  subject: string;
};

const ReplyMessage: FunctionComponent<Props> = ({
  toId,
  userId,
  parentId,
  subject,
}) => {
  const [send] = useCreateMessageMutation();
  const { t } = useTranslation('messages');
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const schema = Yup.object().shape({
    content: Yup.string().required('You must enter a message'),
    attachments: Yup.mixed().test(
      'attachmentsUploaded',
      'Attachments need to finish uploading',
      () => attachments.every((attachment) => !!attachment.blob),
    ),
  });

  return (
    <Formik
      initialValues={{
        content: '',
      }}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        return send({
          variables: {
            input: {
              senderId: userId,
              recipientId: toId,
              parentId: parentId,
              subject: subject,
              content: values.content,
              attachments: attachments.map(
                (attachment) => attachment.blob?.signed_id ?? '',
              ),
            },
          },
          refetchQueries: ['GetConversation'],
          awaitRefetchQueries: true,
        }).then(() => {
          actions.resetForm();
          toast.success('Message sent');
        });
      }}
    >
      <Form>
        <StyledField
          name="content"
          placeholder="Respond to this message"
          label={t('Reply')}
          as="textarea"
        />
        <AttachmentUploader
          name="attachments"
          attachments={attachments}
          setAttachments={setAttachments}
        />
        <div className="flex">
          <SubmitButton>{t('Send')}</SubmitButton>
        </div>
      </Form>
    </Formik>
  );
};

export default ReplyMessage;
