import { useFormikContext } from 'formik';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIsQuestionnaireComplete } from '../../../hooks/workflowSteps';
import { ScreeningOutcome } from '../Screening/ScreeningOutcome';

type Props = {
  questionnaireType: string;
};

const CompletedRedirector: FunctionComponent<Props> = ({
  questionnaireType,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isSubmitting } = useFormikContext();
  const questionnaireCompleted = useIsQuestionnaireComplete(questionnaireType);
  const { safeRoutes } = new ScreeningOutcome({});

  const checkPath = useMemo(() => {
    return safeRoutes.every((route) => !pathname.includes(route));
  }, [pathname, safeRoutes]);

  useEffect(() => {
    if (questionnaireCompleted && checkPath && !isSubmitting) {
      navigate('/', { replace: true, state: null });
    }
  }, [questionnaireCompleted, checkPath, navigate, isSubmitting]);

  return null;
};

export default CompletedRedirector;
