import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { CenteredLayout, FlowbiteField, SubmitButton, useAuth } from '@pm/core';

const PasswordResetPage = () => {
  const { t } = useTranslation(['reset-password', 'login']);
  const { useLogin } = useAuth();
  const [loginMutation] = useLogin();
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const email = query.get('email');
  const [error, setError] = useState(false);
  const { useResetPassword } = useAuth();
  const [resetPasswordMutation] = useResetPassword();

  if (!token || !email) {
    return <div>{t('reset-password:MissingTokenEmail')}</div>;
  }

  const schema = Yup.object().shape({
    password: Yup.string().required(t('reset-password:PleaseEnterNew')),
  });

  const handleSubmit = async ({ password }: { password: string }) => {
    await resetPasswordMutation({
      variables: {
        input: {
          email,
          token,
          newPassword: password,
        },
      },
    })
      .then(async ({ data }) => {
        if (data) {
          if (!data.resetPassword?.errors.length) {
            await loginMutation({
              variables: {
                input: {
                  email,
                  password,
                },
              },
            });
            navigate('/');
          }
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <CenteredLayout>
      <h2 className="text-2xl font-extrabold leading-9">
        {t('reset-password:Title')}
      </h2>
      <div className="my-4 text-xl font-normal text-gray-500">
        {t('reset-password:Description')}
      </div>
      <Formik
        initialValues={{
          password: '',
        }}
        validationSchema={schema}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            {error ? (
              <div className="text-red-500">
                {t('reset-password:PasswordNotReset')}{' '}
                <a href="mailto:support@gofreddie.com" className="underline">
                  support@gofreddie.com
                </a>
                .
              </div>
            ) : (
              <>
                <FlowbiteField
                  name="password"
                  label={t('login:Password')}
                  type="password"
                  placeholder={t('login:PasswordPlaceholder')}
                  bottomMargin={false}
                />
                <SubmitButton className="mt-10">
                  {t('reset-password:UpdatePassword')}
                </SubmitButton>
              </>
            )}
          </Form>
        )}
      </Formik>
    </CenteredLayout>
  );
};

export default PasswordResetPage;
