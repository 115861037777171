import { DirectUpload, Blob } from '@rails/activestorage';

class Uploader {
  private directUpload: DirectUpload;
  private setProgress: (progress: number) => void;

  constructor(
    file: File,
    setProgress: (progress: number) => void,
    URL: string,
  ) {
    this.directUpload = new DirectUpload(file, URL, this);
    this.setProgress = setProgress;
  }

  upload(callback: (blob: Blob) => void) {
    this.directUpload.create((error, blob) => {
      if (!error) callback(blob);
    });
  }

  directUploadWillStoreFileWithXHR(request: XMLHttpRequest) {
    request.upload.addEventListener('progress', (event) =>
      this.setProgress(event.loaded / event.total),
    );
  }
}

export default Uploader;
