import { useState } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { StyledField, SubmitButton, useProfile } from '@pm/core';
import AttachmentUploader, {
  Attachment,
} from '../components/AttachmentUploader';
import { useCreateMessageMutation } from '@pm/graphql';
import { Layout } from '../layout';

const ComposePage = () => {
  const { t } = useTranslation('messages');
  const [send] = useCreateMessageMutation();
  const navigate = useNavigate();
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const { userId } = useProfile();

  const supportId =
    import.meta.env.VITE_ENVIRONMENT === 'production' ? '1431' : '82';
  const toId = supportId;
  const refetchQueries = ['GetMyConversations'];

  const schema = Yup.object().shape({
    toId: Yup.string().required('You must select a recipient'),
    subject: Yup.string().required('You must enter a subject'),
    content: Yup.string().required('You must enter a message'),
    attachments: Yup.mixed().test(
      'attachmentsUploaded',
      'Attachments need to finish uploading',
      () => attachments.every((attachment) => !!attachment.blob),
    ),
  });

  return (
    <Layout title={t('Compose')}>
      <Formik
        initialValues={{
          toId: toId ?? '',
          subject: '',
          content: '',
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          send({
            variables: {
              input: {
                senderId: String(userId),
                recipientId: supportId,
                subject: values.subject,
                content: values.content,
                attachments: attachments.map(
                  (attachment) => attachment.blob?.signed_id ?? '',
                ),
              },
            },
            refetchQueries,
          }).then(() => {
            navigate('/conversations');
            toast.success('Message sent');
          });
        }}
      >
        {() => (
          <Form className="mt-4">
            <div className="mb-4 text-xl">{t('SendFreddie')}</div>
            <StyledField
              name="subject"
              placeholder={t('Subject')}
              label={t('Subject')}
            />
            <StyledField
              name="content"
              placeholder={t('Message')}
              label={t('Message')}
              as="textarea"
            />
            <AttachmentUploader
              name="attachments"
              attachments={attachments}
              setAttachments={setAttachments}
            />
            <div className="flex">
              <SubmitButton>{t('Send')}</SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default ComposePage;
