import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addMonths, format } from 'date-fns';
import { FunctionComponent, useEffect, useReducer, useState } from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppointmentDayTimes from '../../components/AppointmentDayTimes';
import AppointmentDayHeader from '../../components/AppointmentDayHeader';
import { ButtonLink, Card, LoadingPage, usePatientProfile } from '@pm/core';
import {
  AcuityBooking,
  Province,
  useGetAvailableAppointmentDatesQuery,
} from '@pm/graphql';
import FiltersManager from '../../components/AppointmentTimesFilter/FiltersManager';
import Filter from '../../components/AppointmentTimesFilter/Filter';
import { SignupWorkflowSteps } from '../DashboardPage/types';
import { Layout } from '../../layout';

const DAY_RANGE = 1;

const filtersReducer = (
  state: { filtersManager: FiltersManager },
  action: { type: string; payload: { filter: Filter } },
) => {
  switch (action.type) {
    case 'toggleSelected':
      action.payload.filter.toggleSelected();
      return { filtersManager: state.filtersManager };
    default:
      throw new Error();
  }
};

type Props = {
  reschedule?: boolean;
};

const AppointmentPage: FunctionComponent<Props> = ({ reschedule = false }) => {
  const { t } = useTranslation('appointment');
  const { profile } = usePatientProfile();
  const workflowSteps = profile?.workflowSteps;
  const { id, workflowStepId } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const followup = query.get('followup');
  const isFollowup = !!followup || profile?.hasAttendedConsult;
  const [startIndex, setStartIndex] = useState(0);
  const [{ filtersManager }] = useReducer(filtersReducer, {
    filtersManager: new FiltersManager(),
  });

  const { province, refetch } = usePatientProfile();
  const provinceCode = province?.code as Province;
  const startOfCurrentMonth = format(new Date(), 'yyyy-MM-01');
  const startOfNextMonth = format(addMonths(new Date(), 1), 'yyyy-MM-01');
  const screeningResults = workflowSteps?.find(
    (step) => step.type === SignupWorkflowSteps['Screening'].type,
  );
  const userMustSeeMD =
    screeningResults?.completionParams?.requiresMdAssessment || false;

  const bookingType = userMustSeeMD
    ? AcuityBooking.InitialMdAssessment
    : isFollowup
    ? AcuityBooking.Followup
    : AcuityBooking.Extended;

  const { data: currentMonth } = useGetAvailableAppointmentDatesQuery({
    variables: {
      date: startOfCurrentMonth,
      province: provinceCode,
      bookingType: bookingType,
    },
  });

  const { data: nextMonth } = useGetAvailableAppointmentDatesQuery({
    variables: {
      date: startOfNextMonth,
      province: provinceCode,
      bookingType: bookingType,
    },
  });

  const navigate = useNavigate();

  // if we're a new user and haven't properly got our
  // province yet, refetch our profile
  useEffect(() => {
    if (province === null) {
      refetch();
    }
  }, [province, refetch]);

  if (!workflowStepId) {
    navigate('/');
    return null;
  }

  if (
    !currentMonth?.availableAppointmentDates ||
    !nextMonth?.availableAppointmentDates
  ) {
    return <LoadingPage />;
  }

  const twoMonthsOfDates = [
    ...currentMonth.availableAppointmentDates,
    ...nextMonth.availableAppointmentDates,
  ];

  const filteredDays = filtersManager.filterDays(twoMonthsOfDates);

  const daysInRange = filteredDays.slice(startIndex, startIndex + DAY_RANGE);

  return (
    <Layout narrow>
      <div className="flex flex-col gap-y-5">
        <Card className="mt-1">
          <div className="mb-4 text-2xl font-medium text-gray-900">
            {t('Match')}
          </div>
          <div className="mb-5 text-xl text-gray-500">{t('Description')}</div>
          <div className="flex flex-col">
            {followup === 'optional' && (
              <div className="mt-2">
                <ButtonLink to="/">Skip Booking</ButtonLink>
              </div>
            )}
            <div className="mb-4 text-2xl font-medium text-gray-900">
              {t('NextSteps')}
            </div>
            <ul className="ml-8 list-disc space-y-4">
              <li className="text-xl text-gray-500">{t('Step1')}</li>
              <li className="text-xl text-gray-500">{t('Step2')}</li>
              <li className="text-xl text-gray-500">{t('Step3')}</li>
            </ul>
          </div>
        </Card>
        {daysInRange.length ? (
          <Card>
            <div className="text-frida-grey-2 mb-2 flex items-center justify-between px-2">
              <div
                className={clsx(
                  'cursor-pointer',
                  startIndex === 0 && 'invisible',
                )}
                onClick={() => {
                  setStartIndex(startIndex - 1);
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} color="frida-grey-2" />
              </div>
              {daysInRange.map((day) => (
                <AppointmentDayHeader date={day.date} key={day.date} />
              ))}
              <div
                className={clsx(
                  'cursor-pointer',
                  startIndex === twoMonthsOfDates.length - DAY_RANGE &&
                    'invisible',
                )}
                onClick={() => {
                  setStartIndex(startIndex + 1);
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} color="frida-grey-2" />
              </div>
            </div>
            <div className="grid grid-cols-1">
              {daysInRange.map((day) => (
                <AppointmentDayTimes
                  date={day.date}
                  province={provinceCode}
                  key={day.date}
                  filtersManager={filtersManager}
                  appointmentId={reschedule ? (id as string) : ''}
                  bookingType={bookingType}
                  workflowStepId={workflowStepId}
                />
              ))}
            </div>
          </Card>
        ) : (
          <Card>
            <div className="mb-4 text-2xl font-medium text-gray-900">
              {t('Sorry')}
            </div>
            <div className="text-xl text-gray-500">
              {t('NoAvailableAppointments')}
            </div>
          </Card>
        )}
      </div>
    </Layout>
  );
};

export default AppointmentPage;
