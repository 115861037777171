import Filter, { Label } from './Filter';
import FiltersManager from './FiltersManager';

class SelectAllFilter extends Filter {
  constructor(label: Label, filtersManager: FiltersManager) {
    super(label, filtersManager, true);
  }

  toggleSelected() {
    this.selected = !this.selected;
    if (this.selected) {
      this.filtersManager.filters.forEach((filter) => {
        if (filter !== this) filter.selected = false;
      });
    }
  }
}

export default SelectAllFilter;
