import { FunctionComponent, PropsWithChildren } from 'react';

const QuestionnaireLayout: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <div className="bg-frida-beige min-h-screen pb-16">
      <div className="mb-4 grid h-16 w-full justify-items-center bg-white text-center text-xl font-bold shadow-sm sm:mb-16">
        <div className="flex items-center">
          <a href="https://talkwithfrida.com" target="_blank" rel="noreferrer">
            <img className="h-8" src="/images/frida-logo.svg" alt="Frida" />
          </a>
        </div>
      </div>
      <div className="sm:w-512 mx-4 grid grid-cols-1 sm:m-auto">{children}</div>
    </div>
  );
};

export default QuestionnaireLayout;
