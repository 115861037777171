import { useEffect } from 'react';
import { useNavigate, Navigate, useSearchParams } from 'react-router-dom';
import { LoadingPage, useAuth } from '@pm/core';

const VerifyPage = () => {
  const { useLogin, error, setError } = useAuth();
  const [loginMutation] = useLogin();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const oneTimeAccessToken = searchParams.get('token');
    const email = searchParams.get('email');
    const redirectUrl = searchParams.get('redirect_url');
    const doLogin = async (): Promise<void> => {
      await loginMutation({
        variables: {
          input: {
            email: email ?? '',
            oneTimeAccessToken,
          },
        },
      });
    };

    if (oneTimeAccessToken && email) {
      doLogin().then(() => navigate(redirectUrl || '/', { replace: true }));
    } else {
      setError(true);
    }
  }, [searchParams, navigate, loginMutation, setError]);

  if (error) {
    // if we tried to send them to a page but the token failed
    // send them to the page they wanted to go to
    // they will be intercepted with the login page
    // but after logging in with their password they'll see their content
    const redirectUrl = searchParams.get('redirect_url');
    if (redirectUrl) {
      return <Navigate to={redirectUrl} />;
    }
    return (
      <div>
        Failed to login, please try again. You might have clicked an old email.
      </div>
    );
  }

  return <LoadingPage />;
};

export default VerifyPage;
