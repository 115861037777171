import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import WfirsQuestionnaire, { WfirsSection, WfirsIntro, Question } from '.';

type Props = {
  isAssessment?: boolean;
};

const WfirsRoutes: FunctionComponent<Props> = ({ isAssessment = false }) => {
  const { t } = useTranslation('frida-wfirs-questionnaire');
  const sections = Object.entries(t('Sections', { returnObjects: true }));
  const answers = Object.values(t('Answers', { returnObjects: true }));

  return (
    <Routes>
      <Route
        path="/"
        element={<WfirsQuestionnaire isAssessment={isAssessment} />}
      >
        {sections.map(([sectionKey, section], i0) => {
          const questions = Object.entries(section?.Questions) ?? [];

          return (
            <Route key={i0} path={sectionKey} element={<WfirsSection />}>
              <Route
                path="intro"
                element={
                  <WfirsIntro
                    section={sectionKey}
                    isAssessment={isAssessment}
                  />
                }
              />
              {questions.map(([questionKey, question], i1) => {
                const nextQuestion = {
                  section:
                    i1 + 1 === questions.length
                      ? (sections[i0 + 1] ?? [])[0]
                      : null,
                  question: (questions[i1 + 1] ?? [])[0],
                  intro: i1 + 1 === questions.length ? true : false,
                };

                return (
                  <Route
                    key={i1}
                    path={questionKey}
                    element={
                      <Question
                        name={questionKey}
                        question={question}
                        section={sectionKey}
                        options={answers}
                        next={nextQuestion}
                        isAssessment={isAssessment}
                      />
                    }
                  />
                );
              })}
            </Route>
          );
        })}
      </Route>
    </Routes>
  );
};

export default WfirsRoutes;
