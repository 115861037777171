import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  Button,
  LoadingPage,
  Select,
  useSupportedCanadianProvinces,
} from '@pm/core';
import QuestionnaireLayout from '../components/QuestionnaireLayout';

const Province = () => {
  const { t } = useTranslation('frida-signup-province');
  const navigate = useNavigate();
  const supported = useSupportedCanadianProvinces();
  const provinces = supported
    ? Array.from(
        supported.map(({ code, name }) => ({ text: name, value: code })),
      )
    : [];
  provinces.push({ text: 'Other', value: 'Other ' });

  const schema = Yup.object({
    province: Yup.string().required(t('ProvinceRequired')),
  });

  if (!supported) {
    return <LoadingPage />;
  }

  return (
    <Formik
      validateOnMount
      initialValues={{ province: '' }}
      validationSchema={schema}
      onSubmit={({ province }) => {
        if (['AB', 'ON'].includes(province)) {
          navigate('/pre-screen');
        } else {
          navigate('/out-of-province');
        }
      }}
    >
      {({ isValid, values }) => (
        <Form>
          <QuestionnaireLayout>
            <Card className="p-4">
              <h2 className="text-2xl font-medium text-gray-900">
                {t('Title')}
              </h2>
              <p className="mt-4 mb-10 text-xl text-gray-500">
                {t('CardText')}
              </p>
              <Select
                name="province"
                options={provinces}
                onSelect={(value) =>
                  window.analytics.track('/province item selected', { value })
                }
              />
              <Button
                submit
                disabled={!isValid}
                onClick={() => {
                  localStorage.setItem('PROVINCE', values.province);
                }}
                className="mt-10"
              >
                {t('Continue')}
              </Button>
            </Card>
            <Card className="mt-4 p-4">
              <Link
                to="/login"
                className="flex flex-row justify-center gap-1 font-medium"
              >
                <div>{t('AlreadyPatient')}</div>
                <div className="text-frida-purple font-medium underline">
                  {' '}
                  {t('LogIn')}
                </div>
              </Link>
            </Card>
          </QuestionnaireLayout>
        </Form>
      )}
    </Formik>
  );
};

export default Province;
