import { FunctionComponent, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { ErrorMessage, useField, Field } from 'formik';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface StyledFieldProps {
  name: string;
  type?: string;
  label: string;
  labelStyle?: string;
  description?: string;
  placeholder?: string;
  large?: boolean;
  inverted?: boolean;
  hideErrorMessage?: boolean;
  disabled?: boolean;
  as?: string;
  inputStyle?: string;
}

export const StyledField: FunctionComponent<PropsWithChildren<any>> = ({
  label,
  description,
  placeholder,
  type,
  large = false,
  inverted = false,
  children,
  labelStyle = '',
  hideErrorMessage = false,
  disabled = false,
  inputStyle = 'mb-3 p-4 block rounded-md focus:ring focus:ring-opacity-50 sm:leading-5 border-gray-300',
  ...props
}) => {
  const [field, meta] = useField(props.name);
  const showError = !!meta.error && meta.touched;

  return (
    <div className="flex-grow text-black">
      <div className="relative rounded-md">
        {label && (
          <label
            htmlFor={field.name}
            className={
              labelStyle
                ? labelStyle
                : 'text-freddie-green-1 block text-xl font-semibold leading-5'
            }
          >
            {label}
          </label>
        )}
        <Field
          {...field}
          {...props}
          checked={type === 'checkbox' && field.value}
          disabled={disabled}
          type={type ? type : 'text'}
          className={clsx(
            inputStyle,
            meta.error && meta.touched
              ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-400 focus:ring-red-200'
              : ' focus:border-blue-300 focus:ring-blue-200',
            type === 'checkbox' ? 'w-auto' : 'w-full',
            large ? 'text-lg' : 'sm:text-sm',
            labelStyle ? 'mt-2' : 'mt-4',
          )}
          placeholder={placeholder}
          aria-invalid={showError}
          aria-describedby={`${field.name}-error`}
        >
          {children}
        </Field>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {showError && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-red-500"
            />
          )}
        </div>
      </div>
      {description && (
        <div className="mt-2 w-full">
          <p className="text-xs italic text-gray-400">{description}</p>
        </div>
      )}
      {!hideErrorMessage && (
        <ErrorMessage
          name={field.name}
          className={clsx(
            'mb-2 text-sm',
            inverted ? 'text-white' : 'text-red-600',
          )}
          component="p"
        />
      )}
    </div>
  );
};
