import {
  Fragment,
  FunctionComponent,
  PropsWithChildren,
  useContext,
} from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { MenuContext } from './MenuProvider';

export const Sidebar: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { sidebarOpen, setSidebarOpen, isDesktop, mediaQueryLoading } =
    useContext(MenuContext);

  const showMobileSidebar = !isDesktop && !mediaQueryLoading;
  const showDesktopSidebar = (isDesktop || mediaQueryLoading) && sidebarOpen;

  return (
    <>
      {showMobileSidebar && (
        <Transition.Root show={Boolean(sidebarOpen)} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 mt-16 overflow-hidden"
            onClose={setSidebarOpen}
          >
            <div className="absolute inset-0 overflow-hidden">
              {/* darkened overlay */}
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute inset-0 bg-gray-500 bg-opacity-80 transition-opacity" />
              </Transition.Child>
              {/* sidebar itself */}
              <div className="fixed inset-y-0 left-0 flex max-w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div className="relative w-screen max-w-xs">
                    {/* menu items */}
                    <Dialog.Panel className="bg-menu-background mt-16 flex h-full flex-col overflow-y-hidden shadow-xl">
                      <div className="relative flex-1">
                        <nav>
                          <div className="grid grid-cols-1 divide-y">
                            {children}
                          </div>
                        </nav>
                      </div>
                    </Dialog.Panel>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}

      <Transition
        as={Fragment}
        show={Boolean(showDesktopSidebar)}
        enter="transform transition ease-in-out duration-500 sm:duration-700"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-500 sm:duration-700"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="hidden md:flex md:w-64 md:flex-col">
          <div className="bg-menu-background flex flex-grow flex-col overflow-y-auto">
            <div className="flex flex-1 flex-col">{children}</div>
          </div>
        </div>
      </Transition>
    </>
  );
};
