import { FunctionComponent } from 'react';
import { CoreParticipantFieldsFragment } from '@pm/graphql';

type Props = {
  sender: CoreParticipantFieldsFragment;
};

const SenderName: FunctionComponent<Props> = ({ sender }) => {
  let content = (
    <>
      {sender.firstName} {sender.lastName}
    </>
  );

  if (['CX', 'MOA', 'ADMIN'].includes(sender.role.code)) {
    content = <>Frida Medical Team</>;
  }

  return <div className="p-4 text-xl font-black">{content}</div>;
};

export default SenderName;
