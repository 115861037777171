import { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

type Props = {
  to: string;
  onClick?: () => void;
  disabled?: boolean;
  external?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'dark' | 'disabled';
  icon?: ReactElement;
  target?: '' | '_blank';
  rel?: string;
  className?: string;
};

export const ButtonLink: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  to,
  onClick,
  disabled = false,
  variant = 'primary',
  external = false,
  target = '',
  rel,
  icon,
  className,
}) => {
  if (disabled) {
    variant = 'disabled';
  }
  const buttonClass = clsx(
    'h-12 focus:ring-4 font-medium rounded-lg px-5 py-3 text-center w-full',
    icon ? 'inline-flex items-center justify-center' : 'inline-block',
    variant === 'primary' &&
      'bg-button-primary-background text-white hover-hover:hover:bg-button-primary-background-hover focus:bg-button-primary-background-focus',
    variant === 'secondary' &&
      'text-gray-700 bg-white border-2 border-gray-700 hover-hover:hover:bg-gray-100 focus:bg-gray-200',
    variant === 'tertiary' &&
      'text-gray-700 bg-white hover-hover:hover:bg-gray-100 hover-hover:hover:text-gray-700 focus:bg-gray-200',
    variant === 'dark' &&
      'text-white bg-gray-700 hover-hover:hover:bg-gray-600 focus:bg-gray-500',
    variant === 'disabled' &&
      'text-button-disabled-foreground bg-disabled-background hover-hover:hover:cursor-not-allowed',
    className,
  );

  return (
    <>
      {!external ? (
        <Link
          to={to}
          className={buttonClass}
          target={target}
          rel={rel}
          onClick={!disabled ? onClick : (e) => e.preventDefault()}
        >
          {icon}
          {children}
        </Link>
      ) : (
        <a
          href={to}
          className={buttonClass}
          target={target}
          rel={rel}
          onClick={!disabled ? onClick : (e) => e.preventDefault()}
        >
          {icon}
          {children}
        </a>
      )}
    </>
  );
};
