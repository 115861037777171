import { FunctionComponent, PropsWithChildren } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AsrsQuestionnaire from '.';
import { AsrsIntro, AsrsNotQualified, Question } from './pages';

interface Props {
  auth?: boolean;
  bothParts?: boolean; // if true, combine part A and B into one questionnaire
  isAssessment?: boolean; // if true, call different mutations & handle things differently
}

const AsrsRoutes: FunctionComponent<PropsWithChildren<Props>> = ({
  auth = false,
  bothParts = false,
  isAssessment = false,
}) => {
  const { t } = useTranslation('frida-asrs-questionnaire');
  const answers = Object.values(t('Answers', { returnObjects: true }));
  let part: 'PartA' | 'PartB' | 'Both' = 'Both';
  let asrsQuestions: [string, string][] = [];

  if (!bothParts) {
    part = auth ? 'PartB' : 'PartA';
    asrsQuestions = Object.entries(
      t('Questions', { returnObjects: true }),
    ).slice(auth ? 6 : 0, auth ? 18 : 6);
  } else {
    asrsQuestions = Object.entries(t('Questions', { returnObjects: true }));
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AsrsQuestionnaire
            auth={auth}
            part={part}
            isAssessment={isAssessment}
          />
        }
      >
        <Route path="intro" element={<AsrsIntro />} />
        <Route path="not-qualified" element={<AsrsNotQualified />} />
        {asrsQuestions.map(([key, question], i) => (
          <Route
            key={i}
            path={key}
            element={
              <Question
                name={key}
                question={question}
                options={answers}
                cacheAnswers={true}
                part={part}
                isAssessment={isAssessment}
                nextPath={
                  i + 1 < asrsQuestions.length
                    ? asrsQuestions[i + 1][0]
                    : asrsQuestions[asrsQuestions.length - 1][0]
                }
              />
            }
          />
        ))}
      </Route>
    </Routes>
  );
};

export default AsrsRoutes;
