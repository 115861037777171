import {
  useState,
  useEffect,
  createContext,
  Dispatch,
  SetStateAction,
  FunctionComponent,
} from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { BackArrow, Card, usePatientProfile } from '@pm/core';
import {
  GetMePatientDocument,
  GetMyWorkflowStepsDocument,
  useCompleteWorkflowStepMutation,
  useCompleteAssessmentMutation,
} from '@pm/graphql';
import { SignupWorkflowSteps } from '../../DashboardPage/types';
import CompletedRedirector from '../components/CompletedRedirector';
import { Layout } from '../../../layout';
import Gad7Routes from './routes';

export const Gad7ProgressContext = createContext<{
  step: number;
  endStep: number;
  setStep: Dispatch<SetStateAction<number>>;
}>({
  step: 0,
  endStep: 0,
  setStep: useState,
});

type Props = {
  isAssessment?: boolean;
};

const Gad7Questionnaire: FunctionComponent<Props> = ({
  isAssessment = false,
}) => {
  const { profile } = usePatientProfile();
  const { id } = useParams(); // Either the workflow step id or the assessment id
  const questionnaireStep = profile?.workflowSteps.find(
    (step) => step.id === id,
  );
  const [complete] = useCompleteWorkflowStepMutation();
  const [completeAssessment] = useCompleteAssessmentMutation();
  let currentValues: { [key: string]: string } =
    questionnaireStep?.completionParams ?? {};
  const { t } = useTranslation('frida-gad7-questionnaire');
  const questions = Object.keys(t('Questions', { returnObjects: true }));
  const initialValues: { [field: string]: string } = {};

  const shape: { [id: string]: Yup.StringSchema } = {};
  questions.forEach((q: string) => {
    shape[q] = Yup.string();
  });
  let questionPathIndex = 3;
  let firstQuestionPath = `/gad7/${id}/${questions[0]}`;

  if (isAssessment) {
    firstQuestionPath = `/assessment/gad7/${id}/${questions[0]}`;
    const assessmentNode = profile?.assessments?.nodes?.find(
      (step) => step?.id === id,
    );
    currentValues = assessmentNode?.data ?? {};
    questionPathIndex = 4;
  }

  if (currentValues) {
    questions.forEach((key) => {
      initialValues[key] = currentValues[key] ?? '';
    });
  }
  const schema = Yup.object().shape(shape);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [step, setStep] = useState(1);

  useEffect(() => {
    const currentQuestion = pathname.split('/')[questionPathIndex];
    if (currentQuestion) {
      const currentQuestionIndex = questions.indexOf(currentQuestion);
      // ensure that the correct question number is shown
      setStep(currentQuestionIndex + 1);
      window.analytics.track(currentQuestion);
    } else {
      navigate(firstQuestionPath, { replace: true });
    }
  }, [
    pathname,
    questions,
    firstQuestionPath,
    setStep,
    navigate,
    questionPathIndex,
  ]);

  if (id && initialValues) {
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={(values) => {
          if (isAssessment) {
            completeAssessment({
              variables: {
                input: {
                  id,
                  data: values,
                },
              },
              refetchQueries: [
                { query: GetMePatientDocument }, // TODO: remove this when NewDashboard feature flag is off
                { query: GetMyWorkflowStepsDocument },
              ],
              awaitRefetchQueries: true,
            }).then(() => {
              navigate('/', { replace: true });
            });
          } else {
            complete({
              variables: {
                input: {
                  workflowStepId: id,
                  completionParams: values,
                },
              },
              refetchQueries: [
                { query: GetMePatientDocument }, // TODO: remove this when NewDashboard feature flag is off
                { query: GetMyWorkflowStepsDocument },
              ],
              awaitRefetchQueries: true,
            }).then(() => {
              navigate('/', { replace: true });
            });
          }
        }}
      >
        <Layout narrow>
          <Card>
            <BackArrow
              className="mb-4"
              onClick={() => {
                navigate(-1);
                setStep(step - 1);
              }}
            />
            <Gad7ProgressContext.Provider
              value={{
                step,
                setStep,
                endStep: questions.length,
              }}
            >
              <Outlet />
            </Gad7ProgressContext.Provider>
            {/* question counter */}
            <div className="mt-4 text-center text-sm font-normal leading-5 text-gray-500">
              Question {step} of {questions.length}
            </div>
          </Card>
          <CompletedRedirector
            questionnaireType={
              questionnaireStep?.type ?? SignupWorkflowSteps['GAD7'].type
            }
          />
        </Layout>
      </Formik>
    );
  } else {
    return null;
  }
};

export { Question } from './pages';
export { Gad7Routes };
export default Gad7Questionnaire;
