import { BackArrow, Card, usePatientProfile } from '@pm/core';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Layout } from '../../layout';
import format from 'date-fns/format';
import parsePhoneNumber from 'libphonenumber-js';
import { parse } from 'date-fns/esm';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IdentificationCard } from '../../components/IdentificationCard';

export const PatientProfilePage = () => {
  const { verifyButton } = useFlags();
  const navigate = useNavigate();
  const { profile } = usePatientProfile();
  const { t } = useTranslation(['settings', 'global']);
  const labelClass = 'text-sm font-normal text-gray-500';
  const dataClass = 'text-base font-normal text-gray-700 mb-2';
  const phoneNumber = parsePhoneNumber(profile?.phone.rawNumber || '', 'CA');
  const insurance = Object.entries(
    t('settings:CoverageTypes', {
      returnObjects: true,
    }),
  ).find(
    (coverageType) => coverageType[0] == profile?.profile?.financialExpectation,
  );

  return (
    <Layout narrow>
      <BackArrow className="mb-3" onClick={() => navigate('/')} />
      <Card>
        <div className="flex justify-between">
          <div className="mb-6 text-2xl font-medium text-gray-900">
            {t('settings:YourProfile')}
          </div>
          <Link to="/settings">
            <div className="text-frida-purple-dark text-base underline">
              {t('settings:Edit')}
            </div>
          </Link>
        </div>
        <div className={labelClass}>{t('settings:LegalName')}</div>
        <div className={dataClass}>
          {profile?.firstName && profile?.lastName
            ? profile?.firstName + ' ' + profile?.lastName
            : t('settings:NotProvided')}
        </div>
        <div className={labelClass}>{t('settings:DateOfBirth')}</div>
        <div className={dataClass}>
          {profile?.profile?.dateOfBirth
            ? format(
                parse(profile?.profile?.dateOfBirth, 'yyyy-MM-dd', new Date()),
                'MMMM dd, yyyy',
              )
            : t('settings:NotProvided')}
        </div>
        <div className={labelClass}>{t('settings:Email')}</div>
        <div className={dataClass}>{profile?.email}</div>
        <div className={labelClass}>{t('settings:PhoneNumber')}</div>
        <div className={dataClass}>{phoneNumber?.formatNational()}</div>
        <div className={labelClass}>{t('settings:SmsEnabled')}</div>
        <div className={dataClass}>
          {profile?.smsEnabled ? t('global:Yes') : t('global:No')}
        </div>
        <div className={labelClass}>{t('settings:HealthCareNumber')}</div>
        <div className={dataClass}>
          {profile?.profile?.healthCareNumber || t('settings:NotProvided')}
        </div>
        <div className={labelClass}>{t('settings:Insurance')}</div>
        <div className={dataClass}>
          {(insurance && insurance[1]) || t('settings:NotProvided')}
        </div>
        <div className={labelClass}>{t('settings:JustPassword')}</div>
        <div className={dataClass}>{t('settings:PasswordStars')}</div>
      </Card>
      {verifyButton && <IdentificationCard />}
    </Layout>
  );
};
