import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { browserName, deviceType } from 'react-device-detect';
import { useAuth } from '../hooks/auth';

export const LDUserLocation = () => {
  const location = useLocation();
  const client = useLDClient();
  const { isLoggedIn } = useAuth();

  // update LaunchDarkly user attributes when location changes
  useEffect(() => {
    if (client) {
      const currentUserKey = sessionStorage.getItem('USER_ID');
      if (isLoggedIn && currentUserKey) {
        const updatedUser = {
          key: currentUserKey,
          custom: {
            location: window.location.href,
            browserName: browserName,
            deviceType: deviceType,
            province: localStorage.getItem('PROVINCE') || '',
            hasReceivedRX: sessionStorage.getItem('HAS_RECEIVED_RX') || '',
          },
        };
        client.identify(updatedUser);
      } else {
        const updatedUser = {
          key: client.getUser().key,
          custom: {
            location: window.location.href,
            browserName: browserName,
            deviceType: deviceType,
            province: localStorage.getItem('PROVINCE') || '',
            hasReceivedRX: sessionStorage.getItem('HAS_RECEIVED_RX') || '',
          },
        };
        client.identify(updatedUser);
      }
    }
  }, [client, location, isLoggedIn]);

  return null;
};
