import { FunctionComponent, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { Blob } from '@rails/activestorage';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import UploadAttachment from './UploadAttachment';

export interface Attachment extends File {
  blob?: Blob;
  key?: string;
  error?: string;
}

type Props = {
  name: string;
  attachments: Attachment[];
  setAttachments: (attachments: Attachment[]) => void;
  isDialog?: boolean;
  label?: string;
  labelStyle?: string;
};

const AttachmentUploader: FunctionComponent<Props> = ({
  name,
  attachments,
  setAttachments,
  label,
  labelStyle,
  isDialog = false,
}) => {
  const { t } = useTranslation('messages');
  const [, meta] = useField(name);
  const { validateForm } = useFormikContext();

  useEffect(() => {
    validateForm();
  }, [validateForm, attachments]);

  const onDrop = async (acceptedFiles: Attachment[]) => {
    acceptedFiles.forEach((file) => (file.key = `${file.name}-${Date.now()}`));

    const newAttachments = [...attachments, ...acceptedFiles];
    setAttachments(newAttachments);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  useEffect(() => {
    validateForm();
  }, [validateForm, attachments]);

  return (
    <div className="mb-4">
      <label
        className={clsx(
          labelStyle ??
            'text-freddie-green-1 mb-3 block text-xl font-semibold leading-5',
        )}
        htmlFor="attachments"
      >
        {label ?? t('Attachments')}
      </label>
      <div
        {...getRootProps()}
        className={clsx(
          'mt-1 mb-4 rounded-lg border-2 border-dashed bg-white p-4 text-sm text-gray-400',
          isDragActive && 'border-green-500 text-green-500',
          meta.error && 'border-red-600',
        )}
      >
        <input id="attachments" data-testid="dropzone" {...getInputProps()} />
        {isDragActive ? <p>{t('Drop')}</p> : <p>{t('Drag')}</p>}
      </div>
      <ul
        className={clsx(
          'mt-3 grid grid-cols-1 gap-5 sm:gap-6',
          !isDialog && 'sm:grid-cols-2 lg:grid-cols-4',
        )}
      >
        {attachments.map((attachment: Attachment, index: number) => (
          <div key={index}>
            <UploadAttachment
              attachment={attachment}
              error={attachment.error || ''}
              onRemove={() => {
                const newAttachments = attachments.filter(
                  (a) => a !== attachment,
                );
                setAttachments(newAttachments);
              }}
            />
            {attachment.error && (
              <p className="mt-2 text-sm text-red-600">{attachment.error}</p>
            )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default AttachmentUploader;
