import { FunctionComponent } from 'react';
import { Button, Card } from '@pm/core';
import { useCompleteWorkflowStepMutation } from '@pm/graphql';

type Props = {
  step: string;
  id: string;
};

const GenericQuestionnaireCard: FunctionComponent<Props> = ({ step, id }) => {
  const [complete] = useCompleteWorkflowStepMutation();

  return (
    <Card key={id}>
      <div className="text-2xl font-medium">{step}</div>
      <div className="mt-3">
        <Button
          onClick={() => {
            complete({
              variables: {
                input: {
                  workflowStepId: id,
                },
              },
              refetchQueries: ['GetMyWorkflowSteps'],
            });
          }}
        >
          Complete
        </Button>
      </div>
    </Card>
  );
};

export default GenericQuestionnaireCard;
