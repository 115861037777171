import { FunctionComponent } from 'react';
import { I18nResources, useTranslation } from 'react-i18next';
import { Button, Card } from '@pm/core';
import { useNavigate } from 'react-router-dom';
import { AssessmentType, useCreateAssessmentMutation } from '@pm/graphql';
import {
  QuestionnaireStep,
  AssessmentPageWorkflowSteps,
} from '../DashboardPage/types';

type Props = {
  questionnaireType: QuestionnaireStep['type'];
};

type Routes = keyof I18nResources['assessment-page']['Questionnaires'];

const GenericAssessmentCard: FunctionComponent<Props> = ({
  questionnaireType,
}) => {
  const { t } = useTranslation('assessment-page');
  const navigate = useNavigate();
  const [createAssessmentMutation] = useCreateAssessmentMutation();
  const route: string = Object.values(AssessmentPageWorkflowSteps).filter(
    ({ type, route }) => {
      if (type === questionnaireType) return route;
    },
  )[0].route;
  let type = route;
  if (
    questionnaireType === AssessmentPageWorkflowSteps.MedicationResponse.type
  ) {
    type = 'medication_response';
  }
  let id: string | undefined;

  const getAssessmentId = async ({
    questionnaire,
  }: {
    questionnaire: string;
  }) => {
    await createAssessmentMutation({
      variables: {
        input: { type: questionnaire as AssessmentType },
      },
    }).then(({ data }) => {
      id = data?.createAssessment?.assessment?.id;
    });
  };

  return (
    <Card>
      <div className="text-2xl font-medium text-gray-900">
        {t(`Questionnaires.${route as Routes}.Heading`)}
      </div>
      <div className="font-normal text-gray-500">
        <div className="mt-1 text-sm">
          <>
            {t(`Questionnaires.${route as Routes}.Questions`)}
            <span className="mx-1">&bull;</span>
            {t(`Questionnaires.${route as Routes}.Time`)}
          </>
        </div>
        <div className="mt-4 mb-6">
          <div>{t(`Questionnaires.${route as Routes}.CardText`)}</div>
        </div>
      </div>
      <div className="mt-2">
        <Button
          onClick={async () => {
            getAssessmentId({
              questionnaire: type,
            }).then(() => {
              navigate(`/assessment/${route}/${id}`);
            });
          }}
        >
          {t('StartAssessment')}
        </Button>
      </div>
    </Card>
  );
};

export default GenericAssessmentCard;
