import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const NextButtonLabel: FunctionComponent<{
  loading: boolean;
  label: string;
}> = ({ loading, label }) => {
  if (loading) {
    return <FontAwesomeIcon icon={faCircleNotch} spin />;
  }
  return <span>{label}</span>;
};

export default NextButtonLabel;
