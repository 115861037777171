import { useState, useEffect, useMemo } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

import { useGetMyWorkflowStepsQuery } from '@pm/graphql';
import { useGetUpcomingAppointment, LoadingPage, Spacer } from '@pm/core';
import { Layout } from '../../layout';
import { SignupWorkflowSteps, AssessmentWorkflowSteps } from './types';
import NotQualifiedCard from './NotQualifiedCard';
import RoadmapCard, { RoadmapSteps } from './RoadmapCard';
import AssessmentCompleteCard from './AssessmentCompleteCard';
import ManageAppointmentCard from './ManageAppointmentCard';
import GenericQuestionnaireCard from './GenericQuestionnaireCard';
import BookAppointmentCard from './BookAppointmentCard';

const NewDashboardPage = () => {
  const { t } = useTranslation('patient-dashboard');
  const { data, loading } = useGetMyWorkflowStepsQuery();
  const isScreenedOut = data?.me?.currentBrandMembership?.screenedOutStep;
  const { upcomingAppointment } = useGetUpcomingAppointment();
  // TODO: why do we need this? 👇🏻
  const [, setImageLoaded] = useState(false);
  const [signupStep, setSignupStep] = useState<RoadmapSteps['step']>('1');
  const LDClient = useLDClient();
  const isMedResponseCardEnabled = LDClient?.variation(
    'frida-medication-response',
    false,
  );

  // all the steps
  const steps = useMemo(
    () => data?.me?.workflowSteps || [],
    [data?.me?.workflowSteps],
  );
  const initialAssessmentWorkflowStepId = steps.find(
    ({ type }) => type === SignupWorkflowSteps['InitialAssessment'].type,
  )?.id;
  const incompleteWorkflowSteps = useMemo(
    () => steps.filter(({ completedAt }) => completedAt === null) ?? [],
    [steps],
  );
  // signup flow steps to show
  const signupSteps = Array.from(
    incompleteWorkflowSteps.filter((step) =>
      Object.values(SignupWorkflowSteps)
        .map(({ type }) => type)
        .filter((type) => type !== SignupWorkflowSteps['PostConsult'].type)
        .includes(step.type),
    ),
  );
  // assessment flow steps to show
  let rxSteps = Array.from(
    incompleteWorkflowSteps.filter((step) =>
      Object.values(AssessmentWorkflowSteps)
        .map(({ type }) => type)
        .filter((type) => type !== AssessmentWorkflowSteps['PostConsult'].type)
        .includes(step.type),
    ),
  );
  if (!isMedResponseCardEnabled) {
    rxSteps = rxSteps.filter(
      ({ type }) => type !== AssessmentWorkflowSteps['MedicationResponse'].type,
    );
  }

  useEffect(() => {
    // set the signup step for the roadmap card
    if (signupSteps.length) {
      signupSteps.forEach(({ type }) => {
        if (type === SignupWorkflowSteps['Screening'].type) {
          setSignupStep('1');
        } else if (type === SignupWorkflowSteps['InitialAssessment'].type) {
          setSignupStep('2');
        } else {
          // the rest of the steps in the signup flow
          setSignupStep('3');
        }
      });
    } else {
      // nothing left to do but attend the appt
      setSignupStep('4');
    }
  }, [signupSteps]);

  if (loading) {
    return <LoadingPage />;
  }

  if (isScreenedOut) {
    return (
      <Layout narrow>
        <NotQualifiedCard />
      </Layout>
    );
  }

  return (
    <Layout title={upcomingAppointment ? t('Dashboard') : ''} narrow>
      <div className="mb-10 flex flex-col justify-center">
        {/* upcoming appointment stuff */}
        {upcomingAppointment && initialAssessmentWorkflowStepId && (
          <ManageAppointmentCard
            appointment={upcomingAppointment}
            workflowStepId={initialAssessmentWorkflowStepId}
          />
        )}
        <Spacer size={4} />
        <div className="font-bold text-gray-700">Next steps</div>

        {rxSteps.length > 0 ? (
          // rx steps stuff
          <>
            {rxSteps.map(({ id, type }) => (
              <div key={id}>
                {type !== AssessmentWorkflowSteps['PostConsult'].type && (
                  <>
                    <Spacer size={4} />
                    <GenericQuestionnaireCard id={id} type={type} />
                  </>
                )}
              </div>
            ))}
          </>
        ) : (
          // signup steps stuff
          <>
            {signupSteps.length > 0 ? (
              <>
                {signupSteps.map(({ id, type }) => (
                  <div key={id}>
                    {type === SignupWorkflowSteps['InitialAssessment'].type ? (
                      <BookAppointmentCard id={id} />
                    ) : (
                      <GenericQuestionnaireCard id={id} type={type} />
                    )}
                  </div>
                ))}
              </>
            ) : (
              <AssessmentCompleteCard />
            )}
            <Spacer size={4} />
            <RoadmapCard step={signupStep} setImageLoaded={setImageLoaded} />
          </>
        )}
      </div>
    </Layout>
  );
};

export default NewDashboardPage;
