import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Dropdown,
  FlowbiteField,
  SubmitButton,
  useAuth,
  useUnsupportedCanadianProvinces,
} from '@pm/core';
import QuestionnaireLayout from '../components/QuestionnaireLayout';

const OutOfProvince = () => {
  const { t } = useTranslation('frida-signup-out-of-province');
  const navigate = useNavigate();
  const { useSignup } = useAuth();
  const [signupMutation] = useSignup(false);
  const [error, setError] = useState<string>('');
  const unsupported = useUnsupportedCanadianProvinces();
  const provinces = unsupported
    ? Object.assign({}, ...unsupported.map(({ id, name }) => ({ [id]: name })))
    : {};
  const schema = Yup.object({
    email: Yup.string().required(t('EmailRequired')),
    province: Yup.string().required(t('ProvinceRequired')),
  });

  return (
    <Formik
      validateOnMount
      initialValues={{ email: '', province: '' }}
      validationSchema={schema}
      onSubmit={async (values, actions) => {
        await signupMutation({
          variables: {
            input: {
              email: values.email,
              provinceId: values.province,
            },
          },
        })
          .then(() => {
            navigate('/out-of-province-feedback');
          })
          .catch((err) => {
            actions.setSubmitting(false);
            if (err) {
              if (err.includes && err.includes('email')) {
                setError('This email has already been taken.');
              } else {
                setError('Something went wrong!');
              }
            }
          });
      }}
    >
      <Form>
        <QuestionnaireLayout>
          <Card className="p-4">
            <div className="mb-4 text-2xl font-medium text-gray-900">
              {t('Title')}
            </div>
            {(t('CardText') as string).split('{n}').map((substr: string) => (
              <div key={substr} className="mb-7 text-xl text-gray-500">
                {substr}
              </div>
            ))}
            <FlowbiteField
              name="email"
              type="email"
              label={t('Email')}
              placeholder={t('EmailPlaceholder')}
              dataCy="unsupportedProvEmail"
            />
            <Dropdown
              name="province"
              options={provinces}
              label={t('Province')}
              placeholder={t('ProvincePlaceholder')}
            />
            <SubmitButton className="mt-10">{t('Submit')}</SubmitButton>
            {error.length ? (
              <div className="mt-4 text-lg text-red-500">
                {error}
                <br />
                Please contact us at{' '}
                <a href="mailto:support@gofreddie.com" className="underline">
                  support@gofreddie.com
                </a>
              </div>
            ) : null}
          </Card>
        </QuestionnaireLayout>
      </Form>
    </Formik>
  );
};

export default OutOfProvince;
