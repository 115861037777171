import { useTranslation } from 'react-i18next';

import { Card } from '@pm/core';

const AssessmentCompleteCard = () => {
  const { t } = useTranslation('patient-dashboard');

  return (
    <Card>
      <div className="text-2xl font-bold text-gray-900">
        {t('AssessmentComplete.Heading')}
      </div>
      <div className="mt-4 text-gray-500">
        {t('AssessmentComplete.CardText')}
      </div>
    </Card>
  );
};

export default AssessmentCompleteCard;
