import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  Button,
  Card,
  CardText,
  LoadingPage,
  CardTitle,
  Spacer,
} from '@pm/core';
import { Trans, useTranslation } from 'react-i18next';
import { useGetPatientProfileQuery } from '@pm/graphql';
import { CreditCardForm } from '../../components/CreditCardForm';
import { Layout } from '../../layout';

const stripePromise = loadStripe(
  import.meta.env.VITE_ENVIRONMENT === 'production'
    ? 'pk_live_51Jn9TVHmfhkgjy6M947UNfbwRPYh63dDjx5DLOknC3GCKhUGvsxp6KpOLKu7s6kM4Wo01RHQrloo9E9Di0xjRcR800hjn6N4rZ'
    : 'pk_test_51Jn9TVHmfhkgjy6Mk6qMPm5DVXQdmvryn1hZBp7UzWFlsFK3s8o86GFwJYiGqqcmg9wYzF480LJuLiEzU6sAzC0L00NQfXsWBk',
);

const CreditCardPage = () => {
  const { t } = useTranslation('credit-card');
  const {
    data,
    loading,
    refetch: refetchProfile,
  } = useGetPatientProfileQuery();
  const profile = data?.me?.profile;

  if (
    loading ||
    (!profile?.hasPaymentMethod && profile?.hasSubmittedPaymentMethod)
  ) {
    return (
      <Layout>
        <LoadingPage />
      </Layout>
    );
  }

  return (
    <Layout narrow>
      {profile?.hasPaymentMethod ? (
        <Card>
          <div className="mb-7 text-xl text-gray-500">{t('SupportMsg')}</div>
          <Button
            variant="secondary"
            onClick={() =>
              (window.location.href = 'mailto:support@talkwithfrida.com')
            }
          >
            {t('ContactSupport')}
          </Button>
        </Card>
      ) : (
        <Elements stripe={stripePromise}>
          <Card className="mb-4">
            <CardTitle>{t('Heading')}</CardTitle>
            <CardText>{t('Description1')}</CardText>
            <Spacer size={4} />
            <CardText>{t('Description2')}</CardText>
          </Card>
          <Spacer />
          <Card>
            <CardTitle>{t('Enter')}</CardTitle>
            <CreditCardForm refetchProfile={refetchProfile} />
            <div className="mt-6 text-xs text-gray-500">
              <Trans t={t} i18nKey="Terms">
                <a
                  href="https://talkwithfrida.com/terms-of-use/"
                  className="text-frida-purple underline"
                />
              </Trans>
            </div>
          </Card>
          <Spacer />
        </Elements>
      )}
    </Layout>
  );
};

export default CreditCardPage;
