import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLink, Card } from '@pm/core';

type Props = {
  id: string;
};

const BookAppointmentCard: FunctionComponent<Props> = ({ id }) => {
  const { t } = useTranslation('patient-dashboard');

  return (
    <Card>
      <div className="text-2xl font-medium text-gray-900">
        {t('Appointment.Book.Heading')}
      </div>
      <div className="mt-4 text-gray-500">{t('Appointment.Book.CardText')}</div>
      <div className="mt-4">
        <ButtonLink to={`/appointment/${id}`}>
          {t('Appointment.Book.Button')}
        </ButtonLink>
      </div>
    </Card>
  );
};

export default BookAppointmentCard;
