export interface QuestionnaireStep {
  type:
    | 'Steps::Frida::SubmitGad7Questionnaire'
    | 'Steps::Frida::SubmitAsrsPartBQuestionnaire'
    | 'Steps::Frida::SubmitPhq9Questionnaire'
    | 'Steps::Frida::SubmitWfrisQuestionnaire'
    | 'Steps::Frida::SubmitPreConsultAssessment'
    | 'Steps::Frida::SubmitScreeningQuestionnaire'
    | 'Steps::Frida::SubmitMedicalResponseForm'
    | 'Steps::Frida::ScheduleInitialAssessment'
    | 'Steps::Frida::SubmitFollowupAsrsQuestionnaire'
    | 'Steps::Frida::SubmitFollowupPhq9Questionnaire'
    | 'Steps::Frida::SubmitFollowupGad7Questionnaire'
    | 'Steps::Frida::SubmitPostConsultAssessment'
    | 'Steps::Frida::SubmitFollowupPostConsultAssessment'
    | string; // to work with the GraphQL type
  route: string;
}

// questionnaire steps dividied into each workflow
export const SignupWorkflowSteps: Record<string, QuestionnaireStep> = {
  GAD7: { type: 'Steps::Frida::SubmitGad7Questionnaire', route: 'gad7' },
  PHQ9: { type: 'Steps::Frida::SubmitPhq9Questionnaire', route: 'phq9' },
  ASRS_B: {
    type: 'Steps::Frida::SubmitAsrsPartBQuestionnaire',
    route: 'asrs-b',
  },
  WFIRS: { type: 'Steps::Frida::SubmitWfrisQuestionnaire', route: 'wfirs' },
  PreConsult: {
    type: 'Steps::Frida::SubmitPreConsultAssessment',
    route: 'pre-consult',
  },
  Screening: {
    type: 'Steps::Frida::SubmitScreeningQuestionnaire',
    route: 'screening',
  },
  InitialAssessment: {
    type: 'Steps::Frida::ScheduleInitialAssessment',
    route: '',
  },
  PostConsult: {
    type: 'Steps::Frida::SubmitPostConsultAssessment',
    route: '', // handled by frida clinician
  },
};

// aka Followup flow
export const AssessmentWorkflowSteps: Record<string, QuestionnaireStep> = {
  MedicationResponse: {
    type: 'Steps::Frida::SubmitMedicalResponseForm',
    route: 'med-response',
  },
  FollowupASRS: {
    type: 'Steps::Frida::SubmitFollowupAsrsQuestionnaire',
    route: 'asrs',
  },
  FollowupPHQ9: {
    type: 'Steps::Frida::SubmitFollowupPhq9Questionnaire',
    route: 'phq9',
  },
  FollowupGAD7: {
    type: 'Steps::Frida::SubmitFollowupGad7Questionnaire',
    route: 'gad7',
  },
  PostConsult: {
    type: 'Steps::Frida::SubmitPostConsultAssessment',
    route: '', // handled by frida clinician
  },
};

// aka Assessments page
export const AssessmentPageWorkflowSteps: Record<string, QuestionnaireStep> = {
  MedicationResponse: {
    type: 'Steps::Frida::SubmitMedicalResponseForm',
    route: 'med-response',
  },
  FollowupASRS: {
    type: 'Steps::Frida::SubmitFollowupAsrsQuestionnaire',
    route: 'asrs',
  },
  FollowupPHQ9: {
    type: 'Steps::Frida::SubmitFollowupPhq9Questionnaire',
    route: 'phq9',
  },
  FollowupGAD7: {
    type: 'Steps::Frida::SubmitFollowupGad7Questionnaire',
    route: 'gad7',
  },
  PostConsult: {
    type: 'Steps::Frida::SubmitPostConsultAssessment',
    route: '', // handled by frida clinician
  },
  WFIRS: { type: 'Steps::Frida::SubmitWfrisQuestionnaire', route: 'wfirs' },
};

// all together now
export const AllWorkflowSteps: Record<string, QuestionnaireStep> = {
  ...SignupWorkflowSteps,
  ...AssessmentWorkflowSteps,
};
