import { PreScreenResults } from '../../pages/Questionnaires/PreScreen';

export const useGetPreScreenResults = (): PreScreenResults => {
  let results: PreScreenResults = {};

  if ('PRE_SCREEN' in localStorage) {
    const stringified = localStorage.getItem('PRE_SCREEN');

    try {
      if (stringified !== null) {
        results = JSON.parse(stringified);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return results;
};
