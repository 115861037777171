import { ChangeEvent, FunctionComponent } from 'react';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import clsx from 'clsx';
import { formatPhoneNumber } from '../../utilities/phoneNumberUtilities';
import './flowbiteField.css';

type Props = React.HTMLProps<HTMLInputElement> & {
  name: string;
  dataCy?: string;
  bottomMargin?: boolean;
};

export const FlowbiteField: FunctionComponent<Props> = ({
  name,
  label,
  placeholder,
  className,
  autoComplete = 'on',
  autoFocus = false,
  onChange,
  dataCy,
  bottomMargin = true,
  type = 'text',
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  return (
    <div>
      <label htmlFor={name} className="mb-0 text-sm font-medium text-gray-500">
        {label}
      </label>
      <input
        {...field}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        data-cy={dataCy}
        aria-label={name}
        className={clsx(
          'bg-input-background mt-0 w-full rounded-lg px-4 py-3 placeholder:text-gray-500',
          meta.error && meta.touched
            ? 'border-red-500 bg-red-50 pr-10 text-red-900 placeholder-red-300 focus:border-red-400 focus:ring-red-200'
            : 'border-gray-500 focus:border-blue-300 focus:ring-blue-200',
          className,
        )}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (type === 'tel') {
            setFieldValue(name, formatPhoneNumber(event.target.value), true);
          } else {
            setFieldValue(name, event.target.value, true);
          }
          onChange && onChange(event);
        }}
      />
      <ErrorMessage
        name={field.name}
        className={clsx(
          'mt-1 border-none text-sm font-medium text-red-600',
          bottomMargin && 'mb-4',
        )}
        component="p"
      />
      {bottomMargin && <div className="mb-4"></div>}
    </div>
  );
};
