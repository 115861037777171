import { Routes, Route } from 'react-router-dom';

import VerifyPage from '../pages/VerifyPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import LoginPage from '../pages/LoginPage';
import { AsrsRoutes } from '../pages/Questionnaires/ASRS';
import { PreScreenRoutes } from '../pages/Questionnaires/PreScreen';
import FridaSignupQuestionnaire, {
  Province,
  OutOfProvince,
  OutOfProvinceFeedback,
} from '../pages/Questionnaires/Signup';

const InauthApp = () => {
  return (
    <Routes>
      <Route path="/login_callback" element={<VerifyPage />} />
      <Route path="/password/reset" element={<ResetPasswordPage />} />
      <Route path="/password/forgot" element={<ForgotPasswordPage />} />
      <Route path="/province" element={<Province />} />
      <Route path="/out-of-province" element={<OutOfProvince />} />
      <Route
        path="/out-of-province-feedback"
        element={<OutOfProvinceFeedback />}
      />
      <Route path="/signup" element={<FridaSignupQuestionnaire />} />
      <Route path="/pre-screen/*" element={<PreScreenRoutes />} />
      <Route path="/asrs-a/*" element={<AsrsRoutes />} />
      <Route path="*" element={<LoginPage />} />
    </Routes>
  );
};

export default InauthApp;
