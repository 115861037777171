import { MenuIcon, XIcon } from '@heroicons/react/solid';
import { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { MenuContext } from './MenuProvider';

export const Topbar: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { sidebarOpen, setSidebarOpen } = useContext(MenuContext);

  return (
    <div
      id="topbar"
      className="bg-menu-background sticky flex h-16 items-center justify-between border-b border-gray-200 py-5"
    >
      <div className="flex items-center justify-center text-center">
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-label="Toggle sidebar"
          className="ml-4 inline-block h-8 w-8 text-gray-700 opacity-100 transition-opacity duration-700 ease-out active:opacity-0"
        >
          {sidebarOpen ? <XIcon /> : <MenuIcon />}
        </button>
      </div>
      <div className="flex h-7 flex-1 place-content-center">{children}</div>
    </div>
  );
};
