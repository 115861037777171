import { useTranslation } from 'react-i18next';

import { ButtonLink, Card } from '@pm/core';
import QuestionnaireLayout from '../components/QuestionnaireLayout';

const OutOfProvinceFeedback = () => {
  const { t } = useTranslation('frida-signup-out-of-province');

  return (
    <QuestionnaireLayout>
      <Card className="mt-0 p-4">
        <div className="mb-4 text-2xl font-medium text-gray-900">
          {t('Feedback.Title')}
        </div>
        {(t('Feedback.CardText') as string)
          .split('{n}')
          .map((substr: string) => (
            <div key={substr} className="mb-10 text-xl text-gray-500">
              {substr}
            </div>
          ))}
        <ButtonLink
          to="https://talkwithfrida.com/"
          className="mt-4"
          external={true}
        >
          {t('Feedback.BackToFrida')}
        </ButtonLink>
      </Card>
    </QuestionnaireLayout>
  );
};

export default OutOfProvinceFeedback;
