import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BackArrow, Button, CardText, CardTitle, Card } from '@pm/core';
import { Layout } from '../../layout';

const ServiceSelectionPage = () => {
  const navigate = useNavigate();
  const { workflowStepId, appointmentType, appointmentDate } = useParams();
  const { t } = useTranslation('services');
  const diagnosisServiceDescription = Object.entries(
    t('DiagnosisDescription', { returnObjects: true }),
  ).map((element) => {
    return { value: element[0], text: element[1] };
  });

  return (
    <Layout narrow>
      <BackArrow
        className="my-4"
        onClick={() => {
          navigate(`/appointment/${workflowStepId}`);
        }}
      />
      <Card>
        <CardTitle>{t('Service')}</CardTitle>
        <CardText className="text-frida-grey text-xl font-normal">
          {t('ServiceDescription')}
        </CardText>
      </Card>
      <Card className="mt-4">
        <div className="flex flex-row justify-between">
          <div className="text-2xl font-medium text-gray-900">
            {t('Diagnosis')}
          </div>
          <div className="text-frida-purple m-1 pl-6 text-3xl font-medium">
            {t('Price')}
          </div>
        </div>
        <ul className="text-frida-grey list-disc space-y-1 p-6 text-xl font-normal">
          {diagnosisServiceDescription.map((element) => (
            <li className="pl-1" key={element.value}>
              {element.text}
            </li>
          ))}
        </ul>
        <div className="text-frida-grey italic">{t('DiagnosisFinePrint')}</div>
        <Button
          className="mt-6"
          onClick={() => {
            window.analytics.track('Diagnosis service selected');
            navigate(
              `/signup-credit-card/${workflowStepId}/${appointmentType}/${appointmentDate}`,
            );
          }}
        >
          {t('Submit')}
        </Button>
      </Card>
    </Layout>
  );
};

export default ServiceSelectionPage;
