import { ProfileProvider, useAuth } from '@pm/core';
import AuthApp from './AuthApp';
import InauthApp from './InauthApp';
import MaintenancePage from '../pages/MaintenancePage';
import useLDClient from 'launchdarkly-react-client-sdk/lib/useLDClient';

const App = () => {
  const LDClient = useLDClient();
  const isMaintenanceEnabled = LDClient?.variation('maintenance', false);
  const { isLoggedIn } = useAuth();

  if (isMaintenanceEnabled) {
    return <MaintenancePage />;
  }

  if (isLoggedIn) {
    return (
      <ProfileProvider>
        <AuthApp />
      </ProfileProvider>
    );
  }

  return <InauthApp />;
};

export default App;
