import { useContext } from 'react';
import { add, isBefore, parseISO } from 'date-fns';
import { ProfileContext } from '../../providers/ProfileProvider';
import { PatientProfileContext } from '@pm/core/src/providers/PatientProfileProvider';
import { useUpdatePatientMutation } from '@pm/graphql';

export const useProfile = () => {
  const context = useContext(ProfileContext);

  if (!context) {
    throw 'This hook must be used inside a ProfileProvider';
  }

  const { profile, refetch, loading } = context;

  return {
    profile: profile,
    isPatient: profile?.role.code === 'PATIENT',
    isClinician: profile?.role.code === 'CLINICIAN',
    isCx: profile?.role.code === 'CX',
    isMoa: profile?.role.code === 'MOA',
    isAdmin: profile?.role.code === 'ADMIN',
    get isProvider() {
      return this.isClinician || this.isCx || this.isMoa || this.isAdmin;
    },
    get isNonClinicianProvider() {
      return this.isProvider && !this.isClinician;
    },
    userId: profile?.id,
    name: `${profile?.firstName} ${profile?.lastName}`,
    refetch,
    loading,
  };
};

export const usePatientProfile = () => {
  const context = useContext(PatientProfileContext);

  if (!context) {
    throw 'This hook must be used inside a PatientProfileProvider';
  }

  const { profile, refetch, loading } = context;

  return {
    profile: profile,
    userId: profile?.id,
    name: `${profile?.firstName} ${profile?.lastName}`,
    province: profile?.profile?.province,
    refetch,
    loading,
  };
};

export const useGetUpcomingAppointment = () => {
  const context = useContext(PatientProfileContext);

  if (!context) {
    throw 'This hook must be used inside a PatientProfileProvider';
  }

  const { profile, refetch, loading } = context;
  let upcomingAppointment;
  const appointments = profile?.acuityAppointments.edges || [];

  if (appointments.length) {
    upcomingAppointment = appointments.find((appointment) =>
      isBefore(
        add(new Date(), { hours: -1 }),
        parseISO(appointment?.node?.scheduledAt),
      ),
    )?.node;
  }

  return {
    upcomingAppointment,
    refetch,
    loading,
  };
};

export const useUpdatePatientProfile = () => useUpdatePatientMutation();
