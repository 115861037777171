import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { CenteredLayout, FlowbiteField, SubmitButton, useAuth } from '@pm/core';
import Logo from './Logo';
import SEO from '../../utilities/seo';

const LoginPage = () => {
  const { useLogin, error } = useAuth();
  const [loginMutation, { loading }] = useLogin();
  const { t } = useTranslation('login');

  const schema = Yup.object().shape({
    email: Yup.string().required(t('MissingEmail')).email(t('InvalidEmail')),
    password: Yup.string().required(t('MissingPassword')),
  });

  return (
    <CenteredLayout>
      <SEO
        title="Log In - Frida Care Portal"
        description="Log in to see your diagnosis and treatment next steps, upcoming appointments and secure messages with Frida's care team."
      />
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={schema}
        onSubmit={async ({ email, password }, actions) => {
          await loginMutation({
            variables: {
              input: {
                email,
                password,
              },
            },
          }).then(({ data }) => {
            if (data) {
              if (!data.login?.errors.length) {
                toast.success('Logged in');
              }
            }
          });

          return actions.setSubmitting(false);
        }}
      >
        <div className="flex flex-1 items-center justify-center text-black">
          <div className="w-full max-w-md">
            <div className="mb-10">
              <Logo />
            </div>
            {/* Split out into component? */}
            <div className="mb-4">
              {error && (
                <div className="mr-2 rounded bg-red-100 px-2.5 py-0.5 text-center text-sm font-medium text-red-800 dark:bg-red-200 dark:text-red-900">
                  {t('IncorrectPassword')}
                </div>
              )}
            </div>
            {/* ----- */}
            <Form>
              <div>
                <FlowbiteField
                  name="email"
                  label={t('Email')}
                  placeholder={t('EmailPlaceholder')}
                  dataCy="email-input"
                  type="email"
                  autoFocus
                />
                <label className="text-frida-purple float-right mb-0 text-sm font-medium">
                  <Link to="/password/forgot">{t('Forgot')}</Link>
                </label>
                <FlowbiteField
                  aria-label="Password"
                  name="password"
                  label={t('Password')}
                  bottomMargin={false}
                  type="password"
                  placeholder={t('PasswordPlaceholder')}
                  dataCy="password-input"
                />
              </div>
              <div className="mt-8 mb-2">
                <SubmitButton disabled={loading}>{t('SignIn')}</SubmitButton>
              </div>
              <div className="mt-3 w-full text-center">
                <Trans t={t} i18nKey="SignUp">
                  <a
                    href="province"
                    className="font-medium text-gray-900 underline"
                  />
                </Trans>
              </div>
            </Form>
          </div>
        </div>
      </Formik>
    </CenteredLayout>
  );
};

export default LoginPage;
