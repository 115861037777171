import { FunctionComponent } from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEnvironment } from '../../hooks/env';

export type AttachmentType = {
  attachment: {
    filename: string;
    downloadUrl: string;
  };
  handleAttachmentClick: React.MouseEventHandler<HTMLAnchorElement>;
};

export const Attachment: FunctionComponent<AttachmentType> = ({
  attachment,
  handleAttachmentClick,
}) => {
  const { apiHost } = useEnvironment();
  const downloadUrl = `${apiHost}${attachment.downloadUrl}`;

  return (
    <li className="col-span-1 flex rounded-md bg-white shadow-sm">
      <a
        className="bg-button-primary-background flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium leading-5 text-white"
        href={downloadUrl}
        onClick={(e) => {
          handleAttachmentClick && handleAttachmentClick(e);
        }}
      >
        <FontAwesomeIcon icon={faDownload} />
      </a>
      <div className="ml-2 truncate p-2" title={attachment.filename}>
        {attachment.filename}
      </div>
    </li>
  );
};
