import {
  useState,
  useEffect,
  createContext,
  Dispatch,
  SetStateAction,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { BackArrow, Card, usePatientProfile } from '@pm/core';
import { Layout } from '../../../layout';
import MedResponseRoutes from './routes';
import {
  GetMyWorkflowStepsDocument,
  useCompleteWorkflowStepMutation,
  useCompleteAssessmentMutation,
  GetMePatientDocument,
} from '@pm/graphql';
import { AssessmentWorkflowSteps } from '../../DashboardPage/types';
import { useIsQuestionnaireComplete } from '../../../hooks/workflowSteps';

export const MedicationResponseProgressContext = createContext<{
  step: number;
  endStep: number;
  setStep: Dispatch<SetStateAction<number>>;
}>({
  step: 0,
  endStep: 0,
  setStep: useState,
});

export type MedResponseProps = {
  isAssessment?: boolean;
};

const MedicationResponseQuestionnaire: FunctionComponent<
  PropsWithChildren<MedResponseProps>
> = ({ isAssessment = false }) => {
  const { id } = useParams();
  const { profile } = usePatientProfile();
  const [completeWorkflowStep] = useCompleteWorkflowStepMutation();
  const [completeAssessment] = useCompleteAssessmentMutation();
  const questionnaireCompleted = useIsQuestionnaireComplete(
    AssessmentWorkflowSteps['MedicationResponse'].type,
  );

  const { t } = useTranslation('frida-medication-response-questionnaire');
  const questions = Object.keys(t('Questions', { returnObjects: true }));
  const initialValues: { [field: string]: string } = {};
  const shape: { [id: string]: Yup.StringSchema } = {};
  questions.forEach((q: string) => {
    shape[q] = Yup.string();
    initialValues[q] = '';
  });

  const schema = Yup.object().shape(shape);
  const questionnaireStep = profile?.workflowSteps.find(
    (step) => step.id === id,
  );
  let currentValues: { [key: string]: string } =
    questionnaireStep?.completionParams ?? {};
  if (isAssessment) {
    const assessmentNode = profile?.assessments?.nodes?.find(
      (step) => step?.id === id,
    );
    // console.log(assessmentNode);
    currentValues = assessmentNode?.data ?? {};
  }
  if (currentValues) {
    for (const key in currentValues) {
      initialValues[key] = currentValues[key] ?? '';
    }
  }
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [step, setStep] = useState(0);
  const [previousPath, setPreviousPath] = useState('MedicationResponse');
  const firstQuestionPath = `${
    isAssessment ? '/assessment' : ''
  }/med-response/${id}/${questions[0]}`;

  if (questionnaireCompleted && !isAssessment) {
    navigate('/');
  }

  useEffect(() => {
    const currentQuestion = pathname.split('/')[isAssessment ? 4 : 3];
    const usersSideEffects = currentValues.SideEffects;

    if (currentQuestion) {
      const currentQuestionIndex = questions.indexOf(currentQuestion);
      // ensure that the correct question number is shown
      setStep(currentQuestionIndex + 1);
      let lastPath =
        currentQuestionIndex > 0 ? questions[currentQuestionIndex - 1] : '/';
      if (usersSideEffects === 'None' && currentQuestion == 'OtherEffects') {
        lastPath = questions[currentQuestionIndex - 2];
      }
      setPreviousPath(lastPath);
    } else {
      navigate(firstQuestionPath, { replace: true });
    }
  }, [
    pathname,
    questions,
    firstQuestionPath,
    setStep,
    isAssessment,
    navigate,
    currentValues.SideEffects,
  ]);

  if (initialValues && id) {
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={async (values) => {
          if (isAssessment) {
            await completeAssessment({
              variables: {
                input: {
                  id,
                  data: values,
                },
              },
              refetchQueries: [
                { query: GetMePatientDocument }, // TODO: remove this when NewDashboard feature flag is off
                { query: GetMyWorkflowStepsDocument },
              ],
              awaitRefetchQueries: true,
            });
          } else {
            await completeWorkflowStep({
              variables: {
                input: {
                  workflowStepId: id,
                  completionParams: values,
                },
              },
              refetchQueries: [
                { query: GetMePatientDocument }, // TODO: remove this when NewDashboard feature flag is off
                { query: GetMyWorkflowStepsDocument },
              ],
              awaitRefetchQueries: true,
            });
          }

          navigate('/', { replace: true });
        }}
      >
        <Layout narrow>
          <Card>
            <BackArrow
              onClick={() => {
                navigate(previousPath);
                setStep(step - 1);
              }}
            />
            <MedicationResponseProgressContext.Provider
              value={{
                step,
                setStep,
                endStep: questions.length,
              }}
            >
              <Outlet />
            </MedicationResponseProgressContext.Provider>
            {/* question counter */}
            <div className="mt-4 text-center text-sm font-normal leading-5 text-gray-500">
              Question {step} of {questions.length}
            </div>
          </Card>
        </Layout>
      </Formik>
    );
  } else {
    return null;
  }
};

export { Question } from './pages';
export { MedResponseRoutes };
export default MedicationResponseQuestionnaire;
