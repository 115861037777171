import { useTranslation } from 'react-i18next';
import { ButtonLink } from '@pm/core';
import { useGetMyWorkflowStepsQuery } from '@pm/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SignupWorkflowSteps } from '../../../DashboardPage/types';

const Qualified = () => {
  const { t } = useTranslation('frida-screening-questionnaire');
  const { data } = useGetMyWorkflowStepsQuery();

  const nextStepId = data?.me?.workflowSteps.find(
    (step) => step.type === SignupWorkflowSteps['InitialAssessment'].type,
  )?.id;

  return (
    <div className="flex flex-col">
      <div className="mb-4 text-2xl font-medium">{t('Qualified.Title')}</div>
      {(t('Qualified.CardText') as string).split('{n}').map((substr) => (
        <div key={substr} className="mb-10 text-xl text-gray-500">
          {substr}
        </div>
      ))}
      {nextStepId ? (
        <>
          <ButtonLink to={`/appointment/${nextStepId}`}>
            {t('Qualified.BookAppointment')}
          </ButtonLink>
          <ButtonLink to="/" variant="secondary" className="mt-4">
            {t('Qualified.GoToDashboard')}
          </ButtonLink>
        </>
      ) : (
        <FontAwesomeIcon icon={faSpinner} spin />
      )}
    </div>
  );
};

export default Qualified;
