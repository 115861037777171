import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, ButtonLink } from '@pm/core';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ConfirmationDialog: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation('credit-card');

  return (
    <Dialog open={open} onClose={onClose} className="mx-4">
      <div className="text-left">
        <div className="text-lg font-medium text-gray-900">
          {t('Dialog.Title')}
        </div>
        <div className="mt-5 text-gray-500">{t('Dialog.Text')}</div>
        <ButtonLink
          to="/"
          variant="dark"
          className="w-84 mt-5"
          onClick={onClose}
        >
          {t('Dialog.Button')}
        </ButtonLink>
      </div>
    </Dialog>
  );
};
