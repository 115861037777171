import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableHeadingCell } from '@pm/core';
import MessageListRow from './MessageListRow';
import { CoreConversationFieldsFragment } from '@pm/graphql';

type Props = {
  conversations: (CoreConversationFieldsFragment | undefined | null)[];
};

const MessageList: FunctionComponent<Props> = ({ conversations }) => {
  const { t } = useTranslation('messages');

  return (
    <Table>
      <thead>
        <tr>
          <TableHeadingCell>{t('From')}</TableHeadingCell>
          <TableHeadingCell>{t('Subject')}</TableHeadingCell>
          <TableHeadingCell>{t('Date')}</TableHeadingCell>
        </tr>
      </thead>
      <TableBody>
        {conversations.length === 0 && (
          <tr>
            <td colSpan={4} className="p-4 text-center italic text-gray-400">
              {t('NoMessages')}
            </td>
          </tr>
        )}
        {conversations.map(
          (conversation) =>
            conversation && (
              <MessageListRow
                key={conversation.id}
                conversation={conversation}
              />
            ),
        )}
      </TableBody>
    </Table>
  );
};

export default MessageList;
