import { MainAppLayout } from '@pm/core';
import { FunctionComponent, PropsWithChildren } from 'react';
import { SidebarContent } from './SidebarContent';
import { TopbarLogo } from './TopbarLogo';

type Props = {
  title?: string;
  wide?: boolean;
  narrow?: boolean;
};

export const Layout: FunctionComponent<PropsWithChildren<Props>> = ({
  title,
  wide,
  narrow,
  children,
}) => (
  <MainAppLayout
    title={title}
    wide={wide}
    narrow={narrow}
    sidebarContent={<SidebarContent />}
    topbarLogo={<TopbarLogo />}
  >
    {children}
  </MainAppLayout>
);
