import { FunctionComponent } from 'react';
import { I18nResources, useTranslation } from 'react-i18next';

type Props = {
  value?: string | null;
};

type Pharmacy = keyof I18nResources['prescription']['Pharmacies'];

const PharmacyName: FunctionComponent<Props> = ({ value }) => {
  const { t } = useTranslation('prescription');

  if (!value) {
    return null;
  }

  return <>{t(`Pharmacies.${value as Pharmacy}`, { defaultValue: null })}</>;
};

export default PharmacyName;
