import { FunctionComponent } from 'react';
import Linkify from 'linkify-react';
import SenderName from './SenderName';
import RecipientName from './RecipientName';
import { Attachments, DateTime } from '@pm/core';
import { CoreMessageFieldsFragment } from '@pm/graphql';

type Props = {
  message: CoreMessageFieldsFragment;
  userId: string;
};

const Message: FunctionComponent<Props> = ({ message, userId }) => (
  <div className="mt-3 w-full shadow">
    <div className="flex items-center bg-gray-50">
      <SenderName sender={message.sender} />
      <RecipientName recipient={message.recipient} />
      <div className="flex-1 p-4 text-right text-sm leading-8 text-gray-400">
        <span className="ml-2">
          <DateTime value={message.createdAt} format="Pp" />
        </span>
      </div>
    </div>
    <div className="text-md bg-white py-6 px-4">
      <Linkify
        options={{
          target: '_blank',
          className: 'text-blue-500 underline hover:text-blue-600',
        }}
      >
        <p className="whitespace-pre-wrap text-gray-600">{message.content}</p>
      </Linkify>
    </div>
    {'attachments' in message && (
      <Attachments
        attachments={message.attachments}
        handleAttachmentClick={() =>
          window.analytics.track('File downloaded', {
            userId,
            date: new Date().toLocaleString(),
            source: {
              category: 'Secure Message',
              messageId: message.id,
            },
          })
        }
      />
    )}
  </div>
);

export default Message;
