import i18n from 'i18next';
import _ from 'lodash';

export class ScreeningOutcome {
  results = {};
  pass = true;
  requiresMdAssessment = false;
  notQualifiedRoute = '';

  questions = i18n.t('frida-screening-questionnaire', { returnObjects: true });
  outcomes = {
    mustSeeMdKeys: {
      keys: [],
    },
    notQualified: { route: 'not-qualified', keys: [] as string[] },
    inPersonPsych: {
      route: 'in-person-psych',
      keys: ['AttemptedSuicide', 'HospitalizedMentalHealth'],
    },
    inPersonCare: {
      route: 'in-person-care',
      keys: ['HeartDisease'],
    },
    psych: {
      route: 'psych',
      keys: [
        'FetalAlcoholDrug',
        'PersonalityDisorder',
        'AntisocialOrConductDisorder',
        'BipolarSchizophrenia',
        'BrainInjuryConcussion',
        'RoadRage',
        'ViolencePeopleAnimals',
      ],
    },
  };
  // routes that will not trigger redirects
  safeRoutes = [
    'qualified',
    'not-qualified',
    'psych',
    'in-person-care',
    'in-person-psych',
    'assessment',
  ];

  constructor(results: object) {
    this.results = results;

    if (this.results) {
      this.outcomes.notQualified.keys.push(
        ...Object.keys(this.results).slice(2, Object.keys(this.results).length),
      );
      // these questions can be answered 'Yes' or have specific pass/fail logic
      _.remove(this.outcomes.notQualified.keys, (key) =>
        [
          'CannabisRegularly',
          'CriticizedForDrugUse',
          'DrinkOrDrugsMorning',
          'ChildhoodBullyingVictim',
          'ChildhoodAbuseVictim',
          'LowRedBloodB12Iron',
          'SleepApnea',
          'PartnerViolence',
          'Glaucoma',
          'Diabetes',
          'Thyroid',
          'Pregnant',
          'FosterCare',
          'DevelopmentalDisorders',
        ].includes(key),
      );
    }
  }

  getAnswer(key: string) {
    return Object.values(this.results)[Object.keys(this.results).indexOf(key)];
  }

  parseResults() {
    this.pass = true;

    if (
      this.getAnswer('PreviousDiagnosis') === 'No' &&
      (this.getAnswer('SymptomsBefore12YearsOld') === 'No' ||
        this.getAnswer('DevelopmentalDisorders') === 'Yes')
    ) {
      this.pass = false;
    }

    if (
      this.getAnswer('CriticizedForDrugUse') === 'Yes' &&
      this.getAnswer('DrinkOrDrugsMorning') === 'Yes'
    ) {
      this.pass = false;
    }

    this.outcomes.notQualified.keys.forEach((key) =>
      this.getAnswer(key) === 'Yes' ? (this.pass = false) : null,
    );

    // decide which results page the user will see
    let route = this.outcomes.notQualified.route;
    this.outcomes.psych.keys.forEach((key) => {
      if (this.getAnswer(key) === 'Yes') {
        route = this.outcomes.psych.route;
      }
    });
    this.outcomes.inPersonCare.keys.forEach((key) => {
      if (this.getAnswer(key) === 'Yes') {
        route = this.outcomes.inPersonCare.route;
      }
    });
    this.outcomes.inPersonPsych.keys.forEach((key) => {
      if (this.getAnswer(key) === 'Yes') {
        route = this.outcomes.inPersonPsych.route;
      }
    });
    this.notQualifiedRoute = route;
  }
}
