import { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation, getI18n, Trans } from 'react-i18next';
import { toast } from 'react-hot-toast';
import Province from './Province';
import OutOfProvince from './OutOfProvince';
import OutOfProvinceFeedback from './OutOfProvinceFeedback';
import {
  Card,
  FlowbiteField,
  SubmitButton,
  useAuth,
  useSupportedCanadianProvinces,
} from '@pm/core';
import QuestionnaireLayout from '../components/QuestionnaireLayout';
import CompleteProfilePage from './CompleteProfilePage';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export type FridaSignupResults = {
  province?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  email?: string;
  password?: string;
  phone?: string;
  results?: string;
};

const FridaSignupQuestionnaire = () => {
  const { t } = useTranslation('frida-signup-questionnaire');
  const supportedProvinces = useSupportedCanadianProvinces();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { useSignup } = useAuth();
  const [signupPatientMutation] = useSignup();
  const i18n = getI18n();
  const provinceCode =
    searchParams.get('provinceCode')?.toUpperCase() ||
    localStorage.getItem('PROVINCE');
  const initialState = {
    province: provinceCode || '',
    email: '',
    password: '',
    results: '',
  };
  const [results] = useState(initialState);
  const stringifiedASRS = localStorage.getItem('ASRS_SCREENING');
  const LDClient = useLDClient();
  const stringifiedScreening = localStorage.getItem('PRE_SCREEN');

  const schema = Yup.object({
    password: Yup.string()
      .required(t('Details.PasswordRequired'))
      .min(8, t('Details.PasswordMinLength')),
    email: Yup.string()
      .email(t('Details.InvalidEmail'))
      .required(t('Details.EmailRequired')),
  });

  return (
    <Formik
      validateOnMount
      initialValues={results}
      validationSchema={schema}
      onSubmit={async (values) => {
        const provinceId = (supportedProvinces ?? []).find((prov) => {
          if (provinceCode) {
            return prov.code === provinceCode;
          }
          return prov.name === values.province;
        })?.id;

        let results,
          screeningResults = {};
        if (stringifiedASRS) {
          results = JSON.parse(stringifiedASRS);
        }
        if (stringifiedScreening) {
          screeningResults = JSON.parse(stringifiedScreening);
        }
        if (!provinceId || !values.email) {
          return;
        }

        await signupPatientMutation({
          variables: {
            input: {
              results,
              screeningResults,
              provinceId,
              email: values.email,
              password: values.password,
              locale: i18n.language,
            },
          },
        })
          .then(() => {
            window.analytics.track('Signup');
            LDClient?.track('Frida sign up');
            localStorage.removeItem('ASRS_SCREENING');
            localStorage.removeItem('PRE_SCREEN');
            navigate('/');
          })
          .catch((err) => {
            console.error(err);
            toast.error(t('Error'));
          })
          .finally(() => {
            window.analytics.track('Signup End');
          });
      }}
    >
      <Form className="text-gray-900">
        <QuestionnaireLayout>
          <Card className="mt-0 mb-5 space-y-4 p-4">
            <div className="text-2xl font-medium">
              {t('RecommendationTitle')}
            </div>
            <div className="text-xl text-gray-500">
              {t('RecommendationText')}
            </div>
          </Card>
          {/* next steps card */}
          <Card className="mb-5 p-4">
            <div className="mb-3 text-2xl font-medium">{t('NextSteps')}</div>
            <ul className="ml-8 list-disc space-y-4">
              <li className="text-xl text-gray-500">{t('Step1')}</li>
              <li className="text-xl text-gray-500">{t('Step2')}</li>
              <li className="text-xl text-gray-500">{t('Step3')}</li>
            </ul>
          </Card>

          {/* Frida account */}
          <Card className="mb-5 p-4">
            <div className="mb-4 text-2xl font-medium">
              {t('Details.CreateFridaAccount')}
            </div>
            <FlowbiteField
              name="email"
              type="email"
              label={t('Details.Email')}
              placeholder={t('Details.EmailPlaceholder')}
              dataCy="signupEmail"
              autoComplete="email"
            />
            <FlowbiteField
              name="password"
              type="password"
              label={t('Details.Password')}
              placeholder={t('Details.PasswordPlaceholder')}
              dataCy="signupPassword"
              autoComplete="new-password"
            />
            <div className="mt-9">
              <SubmitButton>{t('Submit')}</SubmitButton>
            </div>
            <div className="mt-9 flex flex-row">
              <img
                src="/images/PIPEDA-icon.svg"
                alt="PIPEDA logo"
                className="mr-4"
              />
              <div className="text-xs text-gray-500">
                <Trans t={t} i18nKey="PrivacyPolicy">
                  <a
                    href="https://talkwithfrida.com/privacy-policy"
                    className="text-hyperlink-foreground underline"
                    target="_blank"
                    rel="noreferrer"
                  />
                  <a
                    href="https://talkwithfrida.com/terms-of-use/"
                    className="text-hyperlink-foreground underline"
                    target="_blank"
                    rel="noreferrer"
                  />
                </Trans>
              </div>
            </div>
          </Card>
        </QuestionnaireLayout>
      </Form>
    </Formik>
  );
};

export default FridaSignupQuestionnaire;
export { Province, OutOfProvince, OutOfProvinceFeedback, CompleteProfilePage };
