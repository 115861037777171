import { FunctionComponent } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

type Props = {
  className?: string;
  onClick?: () => void;
};

export const BackArrow: FunctionComponent<Props> = ({ className, onClick }) => {
  return (
    <button onClick={onClick} aria-label="Back Button">
      <ArrowLeftIcon
        className={clsx(
          'hover-hover:hover:cursor-pointer h-5 text-gray-500',
          className,
        )}
      />
    </button>
  );
};
