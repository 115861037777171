import { FunctionComponent } from 'react';
import { Attachment } from './Attachment';

type Props = {
  attachments: {
    filename: string;
    downloadUrl: string;
  }[];
  handleAttachmentClick: React.MouseEventHandler<HTMLAnchorElement>;
};

export const Attachments: FunctionComponent<Props> = ({
  attachments,
  handleAttachmentClick,
}) => (
  <ul className="grid grid-cols-1 gap-5 bg-gray-50 p-2 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
    {attachments.map((attachment) => (
      <Attachment
        key={attachment.downloadUrl}
        attachment={attachment}
        handleAttachmentClick={handleAttachmentClick}
      />
    ))}
  </ul>
);
