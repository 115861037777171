import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, PropsWithChildren } from 'react';
import toast from 'react-hot-toast';

type Props = {
  text: string;
};

export const ClipboardButton: FunctionComponent<PropsWithChildren<Props>> = ({
  text,
}) => (
  <button
    onClick={() => {
      navigator.clipboard
        .writeText(text)
        .then(() => toast.success('Copied'))
        .catch(() => toast.error('Copy failed'));
    }}
  >
    <FontAwesomeIcon
      icon={faCopy}
      className="cursor-pointer text-gray-400 hover:text-gray-500"
      title="Copy"
    />
  </button>
);
