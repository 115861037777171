import { LDProvider } from 'launchdarkly-react-client-sdk';
import { FunctionComponent, PropsWithChildren } from 'react';
import { browserName, deviceType } from 'react-device-detect';

type Props = {
  sdkKey: string;
};

export const LaunchDarklyProvider: FunctionComponent<
  PropsWithChildren<Props>
> = ({ children, sdkKey }) => {
  return (
    <LDProvider
      clientSideID={sdkKey}
      user={{
        anonymous: true,
        custom: {
          location: window.location.href,
          browserName: browserName,
          deviceType: deviceType,
        },
      }}
    >
      {children}
    </LDProvider>
  );
};
