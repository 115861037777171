import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { PreConsultProgressContext } from '../index';
import {
  GetMePatientDocument,
  useUpdateWorkflowStepMutation,
} from '@pm/graphql';
import { Button, FlowbiteField, MultiSelectField, Select } from '@pm/core';
import NextButtonLabel from '../../components/NextButtonLabel';

type QuestionProps = {
  name: string;
  question: string;
  options: object;
  textInputLabel: string;
  textInputTrigger: string;
  required: boolean;
  pageType: string;
  nextPath?: string;
};

const Question = ({
  name,
  question,
  options,
  textInputLabel,
  textInputTrigger,
  required,
  pageType,
  nextPath,
}: QuestionProps) => {
  const { workflowStepId } = useParams();
  const { t } = useTranslation('frida-pre-consult-questionnaire');
  const { values, submitForm } = useFormikContext<{ [id: string]: string }>();
  const { step, endStep, setStep } = useContext(PreConsultProgressContext);
  const [update] = useUpdateWorkflowStepMutation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  let disabled = false;
  if (required) {
    disabled = !values[name];
    if (pageType === 'TextInputs') {
      disabled = Object.keys(options).some((input) => {
        return !values[input];
      });
    }
    if (pageType === 'MultiSelect') {
      disabled = values[name] === '';
    }
    if (textInputTrigger && values[name].includes(textInputTrigger)) {
      disabled = !values[`${name}Specify`];
    }
  }
  values.QuestionnaireVersion = t('QuestionnaireVersion');

  const inputFields = [];
  if (pageType === 'Select') {
    inputFields.push(
      <div key={name} className="mb-3">
        <Select name={name} options={Object.values(options)} />
      </div>,
    );
  } else if (pageType === 'MultiSelect') {
    const multiSelectOptions = [];
    for (const value of Object.values(options)) {
      multiSelectOptions.push({ name: value, value: value });
    }
    inputFields.push(
      <div key={name}>
        <MultiSelectField name={name} options={multiSelectOptions} />
      </div>,
    );
  } else if (pageType === 'TextInputs') {
    for (const [key, value] of Object.entries(options)) {
      inputFields.push(
        <div key={key}>
          {value && <div className="mt-7 text-xl">{value}</div>}
          <div className="mt-6">
            <FlowbiteField
              name={key}
              placeholder={t('PleaseSpecify')}
              autoComplete="off"
            />
          </div>
        </div>,
      );
    }
  }

  return (
    <div className="flex flex-col">
      <div className="mb-4 text-xl sm:mb-0">{question}</div>
      {inputFields}
      {textInputLabel &&
        (textInputTrigger === '' ||
          values[name].includes(textInputTrigger)) && (
          <>
            <div className="mt-7 mb-6 text-xl">{textInputLabel}</div>
            <FlowbiteField
              name={`${name}Specify`}
              placeholder={t('PleaseSpecify')}
              autoComplete="off"
            />
          </>
        )}
      <Button
        className="mt-7"
        disabled={disabled || loading}
        onClick={() => {
          if (workflowStepId) {
            setLoading(true);
            update({
              variables: {
                input: {
                  id: workflowStepId,
                  completionParams: {
                    ...values,
                    firstIncompleteQuestion: nextPath,
                  },
                },
              },
              refetchQueries: [{ query: GetMePatientDocument }],
            }).then(() => {
              if (step !== endStep) {
                setLoading(false);
                setStep(step + 1);
                nextPath &&
                  navigate(`/pre-consult/${workflowStepId}/${nextPath}`);
              } else {
                submitForm();
              }
            });
          }
        }}
      >
        <NextButtonLabel
          loading={loading}
          label={step !== endStep ? t('Next') : t('Finish')}
        />
      </Button>
    </div>
  );
};

export { Question };
