import { useGetCountriesQuery } from '@pm/graphql';

export const useCanadianProvinces = () => {
  const { data } = useGetCountriesQuery();
  if (!data) return undefined;

  const canada = data.countries.find((country) => country.code === 'CA');
  return canada?.provinces;
};

export const useSupportedCanadianProvinces = () =>
  useCanadianProvinces()?.filter((province) => province.supported);

export const useUnsupportedCanadianProvinces = () =>
  useCanadianProvinces()?.filter((province) => !province.supported);
