import { ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button, MultiSelectField, Select, Spacer } from '@pm/core';
import { PreScreenResultsContext } from '..';
import NextButtonLabel from '../../components/NextButtonLabel';
import { useLDClient } from 'launchdarkly-react-client-sdk';

type QuestionProps = {
  name: string;
  question: string;
  options: { text: ReactNode; value: string }[];
  nextPath?: string;
};

const Question = ({ name, question, options, nextPath }: QuestionProps) => {
  const { t } = useTranslation('frida-pre-screen-questionnaire');
  const { step, endStep, setStep } = useContext(PreScreenResultsContext);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, submitForm }: any = useFormikContext();
  const disabled = !values[name];
  const newQuestion = t('SymptomsBefore12YearsOld.Question');
  const newOptions = Object.values(
    t('SymptomsBefore12YearsOld.Answers', { returnObjects: true }),
  );

  const LDClient = useLDClient();
  const showSymptomList = LDClient?.variation('frida-screening-age-12', false);

  return (
    <div className="flex flex-col">
      {showSymptomList && step === endStep ? (
        <div>
          <div className="mb-2 text-xl text-gray-700">{newQuestion}</div>
          <MultiSelectField name={name} options={newOptions} />
          <Spacer size={9} />
        </div>
      ) : (
        <div>
          <div className="mb-2 text-xl text-gray-700">{question}</div>
          <Select name={name} options={options} />
          <Spacer size={12} />
        </div>
      )}
      <Button
        disabled={disabled}
        onClick={() => {
          if (values['PreviousDiagnosis'] === 'Yes' || step === endStep) {
            return submitForm();
          }

          setStep(step + 1);
          navigate(`/pre-screen/${nextPath}`);
        }}
      >
        <NextButtonLabel loading={false} label={t('Next') as string} />
      </Button>
    </div>
  );
};

export { Question };
