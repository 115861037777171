import { DateTime } from '@pm/core';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

type Props = {
  date: string;
  hideOnMobile?: boolean;
};

const AppointmentDayCard: FunctionComponent<Props> = ({
  date,
  hideOnMobile,
}) => {
  return (
    <div className={clsx('px-2 text-xl', hideOnMobile && 'hidden md:block')}>
      <DateTime value={date} format="EEEE, MMMM d" />
    </div>
  );
};

export default AppointmentDayCard;
