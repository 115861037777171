import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardText } from '@pm/core';
import {
  StripeVerificationSessionStatusEnum as StatusEnum,
  useCreateStripeVerificationSessionMutation,
  useGetStripeVerificationSessionQuery,
} from '@pm/graphql';
import { loadStripe } from '@stripe/stripe-js';
import { FunctionComponent, useEffect, useState } from 'react';

const stripePromise = loadStripe(
  import.meta.env.VITE_ENVIRONMENT === 'production'
    ? 'pk_live_51Jn9TVHmfhkgjy6M947UNfbwRPYh63dDjx5DLOknC3GCKhUGvsxp6KpOLKu7s6kM4Wo01RHQrloo9E9Di0xjRcR800hjn6N4rZ'
    : 'pk_test_51Jn9TVHmfhkgjy6Mk6qMPm5DVXQdmvryn1hZBp7UzWFlsFK3s8o86GFwJYiGqqcmg9wYzF480LJuLiEzU6sAzC0L00NQfXsWBk',
);

export const IdentificationCard: FunctionComponent = () => {
  const [create] = useCreateStripeVerificationSessionMutation();
  const { data, loading } = useGetStripeVerificationSessionQuery();
  const status = data?.stripeVerificationSession?.status || '';
  const [infoProvided, setInfoProvided] = useState(false);
  useEffect(() => {
    setInfoProvided(
      status === StatusEnum.Verified || status === StatusEnum.Processing,
    );
  }, [status]);

  return (
    <Card className="mt-4">
      <div className="mb-6 text-2xl font-medium text-gray-900">
        Identification
      </div>
      <CardText>
        Please provide a piece of government issued identification with your
        photo, name, and date of birth as well as a selfie that matches your
        identification.
      </CardText>
      <div className="mt-6 text-sm text-gray-500">Identification info</div>
      {loading ? (
        <FontAwesomeIcon
          className="mt-2 opacity-20"
          icon={faCircleNotch}
          spin
          size="1x"
        />
      ) : (
        <div className="text-gray-700">
          {infoProvided ? (
            <>Provided</>
          ) : (
            <>
              Not provided
              <div className="float-right">
                <button
                  disabled={!stripePromise}
                  onClick={async () => {
                    await create().then((response) => {
                      const clientSecret =
                        response.data?.createStripeVerificationSession
                          ?.clientSecret;
                      if (clientSecret) {
                        stripePromise.then((stripe) => {
                          if (stripe) {
                            stripe
                              .verifyIdentity(clientSecret)
                              .then((results) => {
                                if (results.error) {
                                  console.log('[error]', results.error);
                                } else {
                                  setInfoProvided(true);
                                  console.log('Verification submitted!');
                                }
                              });
                          }
                        });
                      }
                    });
                  }}
                >
                  <div className="text-frida-purple-dark underline">Add</div>
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </Card>
  );
};
