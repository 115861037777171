import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Toaster } from 'react-hot-toast';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import GoogleAnalytics from './components/GoogleAnalytics';
import Segment from './components/Segment';
import SEO from './utilities/seo';
import {
  AuthProvider,
  EnvironmentProvider,
  errorLink,
  LaunchDarklyProvider,
  LDUserLocation,
  MenuProvider,
  renewLink,
  ScrollToTop,
} from '@pm/core';
import App from './apps';

// translations!
import './i18n';

// css files
import 'tailwindcss/tailwind.css';

console.log(
  'Environment variables:',
  import.meta.env.VITE_ENVIRONMENT,
  import.meta.env.VITE_CONTEXT,
  import.meta.env.VITE_REVIEW_ID,
);

// set our api url and sentry environment
let sentryEnvironment = 'dev';
let apiHost = import.meta.env.VITE_API_HOST;
let sdkKey = '621809b9f36a0014e5af68e4'; //staging key

if (!apiHost) {
  apiHost = 'https://api-staging.gofreddie.com';
}

if (import.meta.env.VITE_ENVIRONMENT === 'production') {
  apiHost = 'https://api.purposemed.com';
  sentryEnvironment = 'prod';
  sdkKey = '621577cf301c99145d0164f3';
} else if (import.meta.env.VITE_ENVIRONMENT === 'local') {
  apiHost = 'http://localhost:3001';
  sdkKey = '621577cf301c99145d0164f2';
}

// set our api host if we're in a PR
// if (import.meta.env.VITE_CONTEXT === 'deploy-preview') {
//   apiHost = `https://freddie-review-pr-${
//     import.meta.env.VITE_REVIEW_ID
//   }.herokuapp.com`;
// }

// setup auth and the base url in graphql
const httpLink = createHttpLink({
  uri: apiHost + '/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('jwt');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      brand: 'frida',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, renewLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          conversations: relayStylePagination(['filter']),
          users: relayStylePagination(['filter']),
          workflowSteps: relayStylePagination(['filter']),
        },
      },
    },
  }),
});

// initialize our Sentry crash reporting tool
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://06c6fe9c533c48919e909fd2d6d37112@o371410.ingest.sentry.io/6109122',
    release: `web@${import.meta.env.VITE_COMMIT_REF}`,
    environment: sentryEnvironment,
    ignoreErrors: [
      'Non-Error exception',
      'Loading chunk',
      'ResizeObserver loop limit exceeded',
      'Unexpected token < in JSON at position 0',
      'SecurityError: Blocked a frame with origin',
      'Type',
      'Illegal invocation',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'LaunchDarklyFlagFetchError',
    ],
    integrations: [new BrowserTracing()],
    tracesSampleRate: sentryEnvironment === 'prod' ? 0.1 : 1.0,
  });
}

// render the app
const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <MenuProvider>
        <Suspense fallback={<div></div>}>
          <HelmetProvider>
            <EnvironmentProvider apiHost={apiHost}>
              <Segment>
                <ApolloProvider client={client}>
                  <AuthProvider>
                    <LaunchDarklyProvider sdkKey={sdkKey}>
                      <BrowserRouter>
                        <SEO />
                        <App />
                        <GoogleAnalytics />
                        <LDUserLocation />
                        <Toaster position="top-right" />
                        <ScrollToTop />
                      </BrowserRouter>
                    </LaunchDarklyProvider>
                  </AuthProvider>
                </ApolloProvider>
              </Segment>
            </EnvironmentProvider>
          </HelmetProvider>
        </Suspense>
      </MenuProvider>
    </StrictMode>,
  );
}
