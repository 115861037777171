import clsx from 'clsx';
import { FunctionComponent, PropsWithChildren } from 'react';

type Props = {
  className?: string;
  variant?: 'white' | 'green' | 'purposemed';
};

export const Card: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
  variant = 'white',
}) => {
  const computedClassName = clsx(
    'p-6 sm:p-10 rounded-lg w-full',
    variant === 'white' && 'bg-white border-gray-200 shadow-md border',
    variant === 'green' && 'bg-freddie-green-2 drop-shadow',
    variant === 'purposemed'
      ? 'border border-gray-300 rounded-lg'
      : 'shadow-md',
    className && className,
  );

  return <div className={computedClassName}>{children}</div>;
};
