import { useState, useEffect, FunctionComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { AsrsProgressContext } from '.';
import {
  GetMePatientDocument,
  GetMyWorkflowStepsDocument,
  PatientWorkflowStepFragment,
  useCompleteWorkflowStepMutation,
  useCompleteAssessmentMutation,
} from '@pm/graphql';
import { BackArrow, Card, usePatientProfile } from '@pm/core';
import { useIsQuestionnaireComplete } from '../../../hooks/workflowSteps';
import { Layout } from '../../../layout';
import { SignupWorkflowSteps } from '../../DashboardPage/types';

type Props = {
  part?: 'PartA' | 'PartB' | 'Both';
  isAssessment: boolean;
};

const AuthAsrsQuestionnaire: FunctionComponent<Props> = ({
  part,
  isAssessment,
}) => {
  const { t } = useTranslation('frida-asrs-questionnaire');
  const navigate = useNavigate();
  const [completeWorkflowStep] = useCompleteWorkflowStepMutation();
  const [completeAssessment] = useCompleteAssessmentMutation();
  const { pathname } = useLocation();
  const [step, setStep] = useState(0);
  const [error, setError] = useState('');
  const { id } = useParams();
  let baseRoute =
    part === 'PartA'
      ? '/asrs-a/'
      : part === 'PartB'
      ? `/asrs-b/${id}/`
      : `/asrs/${id}/`;
  if (isAssessment) {
    baseRoute = '/assessment' + baseRoute;
  }
  const { profile } = usePatientProfile();
  const workflowSteps = profile?.workflowSteps;
  // parse initial values
  const workflowStep: PatientWorkflowStepFragment | undefined =
    workflowSteps?.find((step) => step.id === id);
  const results = workflowStep?.completionParams ?? {};
  const questionsFromI18n: string[] = Object.keys(
    t('Questions', { returnObjects: true }),
  );
  const questions: string[] =
    part === 'PartA'
      ? questionsFromI18n.slice(0, 6)
      : part === 'PartB'
      ? questionsFromI18n.slice(6, questionsFromI18n.length)
      : questionsFromI18n;

  const initialValues: { [key: string]: string } = Object.assign(
    {},
    ...questions.map((q) => ({ [q]: '' })),
  );
  Object.entries(initialValues).forEach(([key]) => {
    // put answers from workflep step into here, if applicable
    if (results) {
      if (results[key]) initialValues[key] = results[key];
    }
  });
  // put answers from assessment into here, if applicable
  if (isAssessment) {
    const assessmentNode = profile?.assessments?.nodes?.find(
      (step) => step?.id === id,
    );

    if (assessmentNode) {
      Object.entries(initialValues).forEach(([key]) => {
        if (assessmentNode.data[key])
          initialValues[key] = assessmentNode.data[key];
      });
    }
  }

  const shape: { [key: string]: Yup.StringSchema } = {};
  questions.forEach((q: string) => {
    shape[q] = Yup.string();
  });
  const schema = Yup.object().shape(shape);

  const asrsBCompleted = useIsQuestionnaireComplete(
    workflowStep?.type ?? SignupWorkflowSteps.ASRS_B.type,
  );

  useEffect(() => {
    if (!step) {
      // redirect on mount
      navigate(baseRoute + questions[0], { replace: true });
    }
  }, [baseRoute, navigate, questions, step]);

  // route tracking
  useEffect(() => {
    if (part === 'PartB' && asrsBCompleted && !isAssessment) {
      navigate('/', { replace: true, state: null });
    }

    const questionNum = parseInt(pathname.split('/')[2]);
    const currentQuestion = questions[questionNum];

    if (currentQuestion) {
      window.analytics?.track(currentQuestion);
    }
  }, [pathname, questions, navigate, asrsBCompleted, part, isAssessment]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        if (id) {
          if (isAssessment) {
            completeAssessment({
              variables: {
                input: {
                  id,
                  data: values,
                },
              },
              refetchQueries: [
                { query: GetMePatientDocument }, // TODO: remove this when NewDashboard feature flag is off
                { query: GetMyWorkflowStepsDocument },
              ],
              awaitRefetchQueries: true,
            }).then(() => {
              window.analytics.track('ASRS End');
              navigate('/', { replace: true });
            });
          } else {
            completeWorkflowStep({
              variables: {
                input: {
                  workflowStepId: id,
                  completionParams: values,
                },
              },
              refetchQueries: [
                { query: GetMePatientDocument }, // TODO: remove this when NewDashboard feature flag is off
                { query: GetMyWorkflowStepsDocument },
              ],
              awaitRefetchQueries: true,
            }).then(() => {
              const payload = `ASRS-${part === 'PartA' ? 'A' : 'B'} End`;
              window.analytics.track(payload);
              navigate('/', { replace: true });
            });
          }
        } else {
          setError('Something went wrong on our end. Please contact support.');
        }
      }}
    >
      <Layout narrow>
        <Card>
          <BackArrow
            className="mb-4"
            onClick={() => {
              setStep(step - 1);
              navigate(-1);
            }}
          />
          <AsrsProgressContext.Provider
            value={{
              baseRoute,
              step,
              setStep,
              endStep: questions.length,
            }}
          >
            <Outlet />
          </AsrsProgressContext.Provider>
          {!!error && (
            <div className="my-3 text-lg text-red-500">
              {error}
              <br />
              <a href="mailto:support@gofreddie.com" className="underline">
                support@gofreddie.com
              </a>
            </div>
          )}
          {/* question counter */}
          <div className="mt-3.5 text-center text-sm font-normal leading-5 text-gray-500">
            Question {step} of {questions.length}
          </div>
        </Card>
      </Layout>
    </Formik>
  );
};

export { Question } from './pages';
export default AuthAsrsQuestionnaire;
