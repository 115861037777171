import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export const LoadingPage = ({ classOverride }: { classOverride?: string }) => (
  <div className={classOverride ?? 'flex h-screen w-full flex-col'}>
    <div className="flex-1" />
    <div className="w-full text-center">
      <FontAwesomeIcon icon={faCircleNotch} spin size="6x" />
    </div>
    <div className="flex-1" />
  </div>
);
