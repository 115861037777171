import { BackArrow, Card, CardText, Spacer } from '@pm/core';
import { useGetMePatientQuery } from '@pm/graphql';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../layout';
import { format, parseISO } from 'date-fns';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import PharmacyName from './PharmacyName';

const PrescriptionRecord = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('prescription');
  const { data } = useGetMePatientQuery();
  const prescriptions = data?.me?.prescriptions?.edges;
  const LDClient = useLDClient();
  const showPrescriptionPage = LDClient?.variation(
    'show-frida-prescriptions',
    false,
  );
  useEffect(() => {
    window.analytics.track('View Prescription Record Page');
  }, []);

  if (!showPrescriptionPage) {
    navigate('/');
  }

  return (
    <Layout narrow>
      <BackArrow className="mb-3 text-gray-700" onClick={() => navigate('/')} />
      <div className="text-2xl font-medium text-gray-900"> {t('Title')} </div>
      <Spacer size={6} />
      <div className="flex flex-col-reverse">
        {prescriptions?.length == 0 || prescriptions == null ? (
          <Card>
            <CardText>{t('NeedPrescription')}</CardText>
          </Card>
        ) : (
          prescriptions?.map((prescription) => (
            <div key={prescription?.node?.id}>
              <Card>
                <div className="text-xl font-medium text-gray-900">
                  {prescription?.node?.medication}
                </div>
                <div className="mt-2 text-lg font-normal text-gray-500">
                  <div className="flex flex-row gap-1">
                    <div>
                      {prescription?.node?.numOfPills + ' ' + t('NumOfPills')}
                    </div>
                    <div>
                      {prescription?.node?.dosage + ' ' + t('Milligrams')}
                    </div>
                  </div>
                  <div>
                    <>{t('Pharmacy')}</>
                    &nbsp;
                    <PharmacyName
                      value={prescription?.node?.fulfillmentMethod}
                    />
                  </div>
                  <div className="mt-4 text-sm font-normal text-gray-500">
                    {t('PrescribedDate') +
                      ' ' +
                      format(
                        parseISO(prescription?.node?.sentAt),
                        'MMM-dd-yyyy',
                      )}
                  </div>
                </div>
              </Card>
              <Spacer size={4} />
            </div>
          ))
        )}
      </div>
    </Layout>
  );
};

export default PrescriptionRecord;
