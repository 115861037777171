import {
  useState,
  useEffect,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Card, BackArrow, usePatientProfile } from '@pm/core';
import {
  GetMePatientDocument,
  GetMyWorkflowStepsDocument,
  useCompleteWorkflowStepMutation,
} from '@pm/graphql';
import { SignupWorkflowSteps } from '../../DashboardPage/types';
import { useIsQuestionnaireComplete } from '../../../hooks/workflowSteps';
import { Layout } from '../../../layout';
import PreConsultRoutes from './routes';

export const PreConsultProgressContext = createContext<{
  step: number;
  endStep: number;
  setStep: Dispatch<SetStateAction<number>>;
}>({
  step: 0,
  endStep: 0,
  setStep: useState,
});

const PreConsultQuestionnaire = () => {
  const { profile } = usePatientProfile();
  const { workflowStepId } = useParams();
  const questionnaireStep = profile?.workflowSteps.find(
    (step) => step.id === workflowStepId,
  );
  const questionnaireCompleted = useIsQuestionnaireComplete(
    SignupWorkflowSteps['PreConsult'].type,
  );
  const [complete] = useCompleteWorkflowStepMutation();
  const currentValues: { [key: string]: string } =
    questionnaireStep?.completionParams ?? {};
  const { t } = useTranslation('frida-pre-consult-questionnaire');
  const questions = Object.keys(t('Questions', { returnObjects: true }));
  const questionData = Object.entries(t('Questions', { returnObjects: true }));
  const initialValues: { [field: string]: string } = {};
  const shape: { [id: string]: Yup.StringSchema } = {};
  if (currentValues) {
    for (const value of Object.values(questionData)) {
      if (
        value[1].PageType === 'Select' ||
        value[1].PageType === 'MultiSelect'
      ) {
        initialValues[value[0]] = currentValues[value[0]] ?? '';
        shape[value[0]] = Yup.string();
        if (Object.keys(value[1].TextInputLabel).length > 0) {
          initialValues[`${value[0]}Specify`] =
            currentValues[`${value[0]}Specify`] ?? '';
          shape[`${value[0]}Specify`] = Yup.string().when(value[0], {
            is: value[1].TextInputTrigger,
            then: Yup.string().required('Required'),
          });
        }
      } else if (value[1].PageType === 'TextInputs') {
        for (const question of Object.keys(value[1].Inputs)) {
          initialValues[question] = currentValues[question] ?? '';
          shape[question] = Yup.string();
        }
      }
    }
  }

  const schema = Yup.object().shape(shape);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [step, setStep] = useState(1);
  const [previousPath, setPreviousPath] = useState('/');

  useEffect(() => {
    const questionNum = parseInt(pathname.split('/')[3]);
    const currentQuestion = questions[questionNum];
    if (currentQuestion) {
      window.analytics.track(currentQuestion);
    }
  }, [pathname, questions]);

  const firstQuestionPath = `/pre-consult/${workflowStepId}/${questions[0]}`;
  useEffect(() => {
    if (questionnaireCompleted) {
      navigate('/', { replace: true, state: null });
    }
    const currentQuestion = pathname.split('/')[3];
    if (currentQuestion) {
      const currentQuestionIndex = questions.indexOf(pathname.split('/')[3]);
      // ensure that the correct question number is shown
      setStep(currentQuestionIndex + 1);
      const lastPath =
        currentQuestionIndex > 0 ? questions[currentQuestionIndex - 1] : '/';
      setPreviousPath(lastPath);
    } else {
      navigate(firstQuestionPath, { replace: true });
    }
  }, [
    pathname,
    questions,
    firstQuestionPath,
    setStep,
    navigate,
    questionnaireCompleted,
  ]);

  if (workflowStepId && initialValues) {
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={(values) => {
          complete({
            variables: {
              input: {
                workflowStepId,
                completionParams: values,
              },
            },
            refetchQueries: [
              { query: GetMePatientDocument }, // TODO: remove this when NewDashboard feature flag is off
              { query: GetMyWorkflowStepsDocument },
            ],
            awaitRefetchQueries: true,
          }).then(() => {
            navigate('/', { replace: true });
          });
        }}
      >
        <Layout narrow>
          <Card>
            <BackArrow
              className="mb-4"
              onClick={() => {
                navigate(previousPath);
                setStep(step - 1);
              }}
            />
            <PreConsultProgressContext.Provider
              value={{
                step,
                setStep,
                endStep: questions.length,
              }}
            >
              <Outlet />
            </PreConsultProgressContext.Provider>
            {/* question counter */}
            <div className="mt-4 text-center text-sm font-normal leading-5 text-gray-500">
              Question {step} of {questions.length}
            </div>
          </Card>
        </Layout>
      </Formik>
    );
  } else {
    return null;
  }
};

export { Question } from './pages';
export { PreConsultRoutes };
export default PreConsultQuestionnaire;
