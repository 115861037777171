import clsx from 'clsx';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { Link } from 'react-router-dom';
import { ClipboardButton } from '../ClipboardButton';

type Props = {
  small?: boolean;
  sensitive?: boolean;
  wrapContent?: boolean;
  copy?: string;
  to?: string;
  textFormatting?: string;
  textVariant?: 'black' | 'gray500';
  width?: string;
  className?: string;
};

export const TableCell: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  small,
  wrapContent,
  sensitive,
  copy,
  to,
  className,
  textFormatting = '',
  textVariant = 'black',
  width = 'max-w-xs',
}) => {
  const [hover, setHover] = useState(false);

  if (textVariant === 'gray500') {
    textFormatting = textFormatting + ' text-gray-500 text-sm pl-4';
  }

  let content = (
    <div className={clsx('px-2 py-4', className)}>
      <div
        className={clsx(width, 'whitespace-pre-line', !wrapContent && 'flex')}
      >
        {children}
      </div>
      {copy && (
        <div className={clsx('ml-2', !hover && 'invisible')}>
          <ClipboardButton text={copy} />
        </div>
      )}
    </div>
  );

  if (to) {
    content = <Link to={to}>{content}</Link>;
  }

  return (
    <td
      className={clsx(
        'truncate border-b border-gray-200',
        textFormatting,
        small && 'text-sm',
        sensitive && 'data-hj-suppress',
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {content}
    </td>
  );
};
