import { useEffect } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReplyMessage from '../../components/ReplyMessage';
import {
  useGetConversationQuery,
  useMarkConversationAsReadMutation,
} from '@pm/graphql';
import { Button, LoadingPage, useProfile } from '@pm/core';
import Message from './Message';
import { Layout } from '../../layout';

const ConversationPage = () => {
  const { conversationId } = useParams();
  const { data } = useGetConversationQuery({
    variables: { id: conversationId ?? '' },
  });
  const { userId } = useProfile();
  const [readConversation] = useMarkConversationAsReadMutation();
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  // mark the conversation as read
  useEffect(() => {
    if (data?.conversation) {
      readConversation({
        variables: {
          input: {
            id: conversationId ?? '',
          },
        },
        refetchQueries: [
          'GetConversation',
          'GetMyConversations',
          'GetMessageStatistics',
        ],
      });
    }
  }, [data, conversationId, readConversation]);

  if (!data || !userId) {
    return <LoadingPage />;
  }

  const { conversation } = data;

  if (conversation === null) {
    console.log('Conversation not found: ', conversationId);
    return <Navigate to="/conversations" />;
  }

  if (!conversation) {
    return <LoadingPage />;
  }

  const message = conversation.messages[0];

  const replyUserId =
    message.sender.role.code !== 'PATIENT'
      ? message.sender.id
      : message.recipient.id;

  return (
    <Layout title={message.subject} wide>
      {conversation.messages
        .map((child) => (
          <Message key={child.id} message={child} userId={userId} />
        ))
        .reverse()}
      <div className="mt-3 w-full">
        <ReplyMessage
          toId={replyUserId}
          userId={userId}
          parentId={message.id}
          subject={message.subject}
        />
      </div>
      <Button onClick={() => navigate(-1)} className="mt-2">
        {t('Back')}
      </Button>
    </Layout>
  );
};

export default ConversationPage;
