import { useNavigate } from 'react-router-dom';
import { Formik, Form, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { isValid } from 'date-fns';
import {
  BackArrow,
  Card,
  FlowbiteField,
  Dropdown,
  Datepicker,
  getDay,
  getMonth,
  getYear,
  isUserEighteen,
  LoadingPage,
  SubmitButton,
  usePatientProfile,
  useUpdatePatientProfile,
  Button,
} from '@pm/core';
import { Layout } from '../../layout';

const PatientSettingsPage = () => {
  const { t } = useTranslation(['settings', 'global']);
  const coverageTypes = t('settings:CoverageTypes', { returnObjects: true });
  const { profile, refetch } = usePatientProfile();
  const [updateProfile] = useUpdatePatientProfile();
  const navigate = useNavigate();
  const yesNoValues = { yes: t('global:Yes'), no: t('global:No') };

  if (!profile) {
    return <LoadingPage />;
  }

  const day = getDay(profile.profile?.dateOfBirth);
  const month = getMonth(profile.profile?.dateOfBirth);
  const year = getYear(profile.profile?.dateOfBirth);

  const initialValues = {
    firstName: profile.firstName ?? '',
    lastName: profile.lastName ?? '',
    email: profile.email ?? '',
    healthCareNumber: profile.profile?.healthCareNumber ?? '',
    dateOfBirth: profile.profile?.dateOfBirth ?? '',
    bdayDay: (day.length === 1 ? `0${day}` : day) ?? '',
    bdayMonth: (month.length === 1 ? `0${month}` : month) ?? '',
    bdayYear: year ?? '',
    phone: profile.phone.rawNumber ?? '',
    locale: profile.locale ?? 'en',
    password: '',
    smsEnabled: profile.smsEnabled ? 'yes' : 'no',
    financialExpectation: profile.profile?.financialExpectation ?? '',
    attachments: [''],
    voicemailConsent: profile.voicemailConsent ? 'yes' : 'no',
  };

  const schema = Yup.object().shape({
    firstName: Yup.string().required(t('settings:FirstNameRequired')),
    lastName: Yup.string().required(t('settings:LastNameRequired')),
    email: Yup.string()
      .email('You must enter a valid email address')
      .required(t('settings:EmailRequired')),
    dateOfBirth: Yup.mixed()
      .required(t('settings:DateOfBirthRequired'))
      .test('valid-date', t('settings:DateOfBirthValid'), (value) => {
        return value ? isValid(value) : false;
      })
      .test('adult-user', t('settings:DateOfBirthAdult'), (value) => {
        return isUserEighteen(value);
      }),
    bdayDay: Yup.string(),
    bdayMonth: Yup.string(),
    bdayYear: Yup.string(),
    healthCareNumber: Yup.string(),
    phone: Yup.string().required(t('settings:PhoneRequired')),
    financialExpectation: Yup.string(),
    attachments: Yup.mixed(),
    password: Yup.string()
      .min(8, t('settings:PasswordMinLength'))
      .oneOf(
        [Yup.ref('confirmPassword')],
        t('settings:ConfirmPasswordRequired'),
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      t('settings:PasswordsMustMatch'),
    ),
  });

  const compareValues = (values: FormikValues): boolean => {
    // convert incoming date string to JS Date
    // for comparison
    const formattedDate = new Date(
      initialValues.dateOfBirth.replace(/-/g, '/'),
    );

    return (
      JSON.stringify(values) ===
      JSON.stringify({
        ...initialValues,
        dateOfBirth: formattedDate,
      })
    );
  };

  return (
    <Formik
      validateOnChange
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values) => {
        await updateProfile({
          variables: {
            input: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              healthCareNumber: values.healthCareNumber,
              dateOfBirth: values.dateOfBirth,
              phoneNumber: values.phone,
              locale: values.locale,
              password: values.password,
              smsEnabled: values.smsEnabled == 'yes' ? true : false,
              voicemailConsent: values.voicemailConsent == 'yes' ? true : false,
              financialExpectation: values.financialExpectation,
            },
          },
        }).then(async () => {
          toast.success('Settings updated');
          await refetch().then(() => {
            navigate('/profile');
          });
        });

        if (
          values.financialExpectation !== profile.profile?.financialExpectation
        ) {
          window.analytics.track('Patient Financial Expectation Changed', {
            patientId: profile.id,
            financialExpectation: values.financialExpectation,
          });
        }
        if (
          (profile.smsEnabled && values.smsEnabled == 'no') ||
          (!profile.smsEnabled && values.smsEnabled == 'yes')
        ) {
          window.analytics.track('SMS Messages Toggled by Patient', {
            userId: profile.id,
            changedTo: values.smsEnabled == 'yes' ? 'enabled' : 'disabled',
          });
        }
      }}
    >
      {({ values, resetForm }) => (
        <Form>
          <Layout narrow>
            <BackArrow
              className="mb-3"
              onClick={() => {
                resetForm();
                navigate(-1);
              }}
            />
            <Card>
              <div className="mb-3 text-2xl font-medium text-gray-900">
                {t('settings:YourProfile')}
              </div>
              {/* first name */}
              <FlowbiteField
                name="firstName"
                label={t('settings:FirstName')}
                placeholder={t('settings:FirstNamePlaceholder')}
                autoComplete="given-name"
              />
              {/* last name */}
              <FlowbiteField
                name="lastName"
                label={t('settings:LastName')}
                placeholder={t('settings:LastNamePlaceholder')}
                autoComplete="family-name"
              />
              {/* date of birth */}
              <div className="mb-3">
                <label
                  htmlFor="dateOfBirth"
                  className="text-sm font-medium text-gray-500"
                >
                  {t('settings:DateOfBirth')}
                </label>
                <Datepicker
                  name="dateOfBirth"
                  initialDate={profile.profile?.dateOfBirth ?? null}
                />
              </div>
              {/* email address */}
              <FlowbiteField
                name="email"
                label={t('settings:Email')}
                placeholder={t('settings:EmailPlaceholder')}
                autoComplete="email"
              />
              {/* phone number */}
              <FlowbiteField
                name="phone"
                label={t('settings:PhoneNumber')}
                placeholder={t('settings:PhoneNumberPlaceholder')}
                autoComplete="tel-national"
              />
              <div className="mb-4">
                {/* voicemail consent */}
                <Dropdown
                  name="voicemailConsent"
                  options={yesNoValues}
                  label={t('settings:VoicemailConsent')}
                />
                {/* sms enabled or nah */}
                <Dropdown
                  name="smsEnabled"
                  options={yesNoValues}
                  label={t('settings:SmsEnabled')}
                />
              </div>
              {/* health care number */}
              <FlowbiteField
                name="healthCareNumber"
                label={t('settings:HealthCareNumber')}
                placeholder={t('settings:HealthCareNumberPlaceholder')}
              />
              {/* insurance / financial expectation */}
              <Dropdown
                name="financialExpectation"
                options={coverageTypes ?? {}}
                label={t('settings:Insurance')}
                placeholder={t('settings:InsuranceDropdown')}
              />
              {/* password */}
              <FlowbiteField
                name="password"
                type="password"
                label={t('settings:Password')}
                placeholder={t('settings:PasswordPlaceholder')}
                autoComplete="new-password"
              />
              {/* confirm password */}
              <FlowbiteField
                name="confirmPassword"
                type="password"
                label={t('settings:ConfirmPassword')}
                placeholder={t('settings:ConfirmPasswordPlaceholder')}
              />
              {/* submit button */}
              <SubmitButton disabled={compareValues(values)} className="mt-10">
                {t('settings:SaveChanges')}
              </SubmitButton>
              <Button
                variant="tertiary"
                className="mt-2 focus:bg-white"
                onClick={() => {
                  navigate('/profile');
                }}
              >
                {t('settings:DiscardChanges')}
              </Button>
            </Card>
          </Layout>
        </Form>
      )}
    </Formik>
  );
};

export default PatientSettingsPage;
