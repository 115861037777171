import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import Phq9Questionnaire, { Question } from '.';

type Props = {
  isAssessment?: boolean;
};

const Phq9Routes: FunctionComponent<Props> = ({ isAssessment = false }) => {
  const { t } = useTranslation('frida-phq9-questionnaire');
  const questions = Object.entries(t('Questions', { returnObjects: true }));
  const answers = Object.values(t('Answers', { returnObjects: true }));

  return (
    <Routes>
      <Route
        path="/"
        element={<Phq9Questionnaire isAssessment={isAssessment} />}
      >
        {questions.map(([questionKey, question], i) => {
          const nextPath =
            i + 1 < questions.length
              ? questions[i + 1][0]
              : questions[questions.length - 1][0];

          return (
            <Route
              key={i}
              path={questionKey}
              element={
                <Question
                  name={questionKey}
                  question={question}
                  options={answers}
                  nextPath={nextPath}
                  isAssessment={isAssessment}
                />
              }
            />
          );
        })}
      </Route>
    </Routes>
  );
};

export default Phq9Routes;
