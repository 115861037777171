import { FunctionComponent, PropsWithChildren } from 'react';

type Props = {
  topMargin?: number;
  tableType?: string;
};

export const Table: FunctionComponent<PropsWithChildren<Props>> = ({
  topMargin = 6,
  children,
  tableType = 'auto',
}) => (
  <div className={`mt-${topMargin} flex flex-col`}>
    <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div className="inline-block min-w-full border border-gray-200 align-middle shadow sm:rounded-lg">
        <table className={`w-full table-${tableType}`}>{children}</table>
      </div>
    </div>
  </div>
);
