import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { WfirsProgressContext } from '../index';
import WfirsIntro from './WfirsIntro';
import WfirsSection from './WfirsSection';
import {
  useUpdateAssessmentMutation,
  useUpdateWorkflowStepMutation,
} from '@pm/graphql';
import { Button, Select } from '@pm/core';
import NextButtonLabel from '../../components/NextButtonLabel';

type QuestionProps = {
  name: string;
  question: string;
  section: string;
  options: string[];
  next: {
    section: string | null;
    question: string | null;
    intro: boolean;
  };
  isAssessment?: boolean;
};

const Question = ({
  name,
  section,
  question,
  options,
  next,
  isAssessment = false,
}: QuestionProps) => {
  const { t } = useTranslation('frida-wfirs-questionnaire');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, submitForm }: any = useFormikContext();
  const [updateMutation] = useUpdateWorkflowStepMutation();
  const [updateAssessment] = useUpdateAssessmentMutation();
  const questions = Object.values(
    t(`Sections.${section}.Questions` as 'Sections.Section1Family.Questions', {
      returnObjects: true,
    }),
  );
  const { setStep, step, endStep, setEndStep, setError, setSection } =
    useContext(WfirsProgressContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const disabled = !values[name];
  values.QuestionnaireVersion = t('QuestionnaireVersion');

  setStep(questions.indexOf(question) + 1);

  return (
    <div className="flex flex-col">
      <div className="mb-2 text-xl">{question}</div>
      <Select name={name} options={options} />
      <Button
        disabled={disabled}
        className="mt-12"
        onClick={async () => {
          if (id) {
            setLoading(true);
            if (isAssessment) {
              await updateAssessment({
                variables: {
                  input: {
                    id,
                    data: {
                      ...values,
                    },
                  },
                },
              }).catch((err) => {
                console.error(err);
                setError('Something went wrong. Please contact support.');
              });
            } else {
              await updateMutation({
                variables: {
                  input: {
                    id,
                    completionParams: {
                      ...values,
                    },
                  },
                },
              }).catch((err) => {
                console.error(err);
                setError('Something went wrong. Please contact support.');
              });
            }
          }

          if (step === endStep && section === 'Section7') {
            return submitForm();
          } else {
            setLoading(false);
          }
          setStep(step + 1);

          let nextPath = '/wfirs/';
          if (isAssessment) {
            nextPath = '/assessment' + nextPath;
          }
          if (next.intro) {
            nextPath += `${id}/${next.section ?? section}/intro`;
          } else {
            nextPath += `${id}/${next.section ?? section}/${next.question}`;
          }

          if (nextPath) {
            if (nextPath.includes('intro')) {
              const newSection = nextPath.split('/')[isAssessment ? 4 : 3];
              setStep(1);
              setEndStep(
                Object.keys(
                  t(
                    `Sections.${newSection}.Questions` as 'Sections.Section1Family.Questions',
                    { returnObjects: true },
                  ) ?? [],
                )?.length,
              );
              setSection(newSection);
            }
          }
          nextPath && navigate(nextPath);
        }}
      >
        <NextButtonLabel
          loading={loading}
          label={
            step === endStep && section === 'Section7'
              ? t('Finish')
              : next.intro
              ? t('FinishSection')
              : t('Next')
          }
        />
      </Button>
    </div>
  );
};

export { Question, WfirsIntro, WfirsSection };
