import { FunctionComponent } from 'react';
import clsx from 'clsx';
import { CoreConversationFieldsFragment } from '@pm/graphql';
import { DateTime, TableCell } from '@pm/core';

type Props = {
  conversation: CoreConversationFieldsFragment;
};

const MessageListRow: FunctionComponent<Props> = ({ conversation }) => {
  const message = conversation.lastMessage;
  let otherUser = message.sender;

  if (message.recipient.role.code !== 'PATIENT') {
    otherUser = message.recipient;
  }

  const otherUserDisplayName = ['CX', 'MOA', 'ADMIN'].includes(
    otherUser.role.code,
  )
    ? 'Frida Medical Team'
    : `${otherUser.firstName} ${otherUser.lastName}`; //Otherwise show clinician's name

  const path = `/conversations/${conversation.id}`;

  return (
    <tr
      className={clsx(
        'hover:bg-gray-50',
        !conversation.readByCurrentUser && 'font-bold',
      )}
    >
      {message.sender.role.code === 'PATIENT' ? (
        <TableCell to={path}>
          {message.sender.firstName} {message.sender.lastName}
        </TableCell>
      ) : (
        <TableCell to={path}>{otherUserDisplayName}</TableCell>
      )}
      <TableCell to={path}>{conversation.subject}</TableCell>
      <TableCell to={path}>
        <DateTime value={conversation.lastMessageSentAt} format="Pp" />
      </TableCell>
    </tr>
  );
};

export default MessageListRow;
