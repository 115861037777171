import { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AsrsQuestionnaireProps, AsrsProgressContext } from './index';
import { BackArrow, Card } from '@pm/core';
import QuestionnaireLayout from '../components/QuestionnaireLayout';
import { useGetPreScreenResults } from '../../../hooks/questionnaires/index';

const InauthAsrsQuestionnaire = ({ part }: AsrsQuestionnaireProps) => {
  const { t } = useTranslation('frida-asrs-questionnaire');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [step, setStep] = useState(0);
  const preScreenResults = useGetPreScreenResults();

  // parse initial values
  const stringifiedAnswers = localStorage.getItem('ASRS_SCREENING');
  const cached: { [key: string]: string } = stringifiedAnswers
    ? JSON.parse(stringifiedAnswers)
    : null;
  const questionsFromI18n: string[] = Object.keys(
    t('Questions', { returnObjects: true }),
  );
  const questions: string[] =
    part === 'PartA'
      ? questionsFromI18n.slice(0, 6)
      : part === 'PartB'
      ? questionsFromI18n.slice(6, questionsFromI18n.length - 1)
      : questionsFromI18n;
  const initialValues: { [key: string]: string } = Object.assign(
    {},
    ...questions.map((q) => ({ [q]: '' })),
  );
  Object.entries(initialValues).forEach(([key]) => {
    if (cached) {
      initialValues[key] = cached[key];
    }
  });

  const shape: { [key: string]: Yup.StringSchema } = {};
  questions.forEach((q: string) => {
    shape[q] = Yup.string();
  });
  const schema = Yup.object().shape(shape);

  // route tracking
  useEffect(() => {
    if (!pathname.includes('intro')) {
      const questionNum = parseInt(pathname.split('/')[2]);
      const currentQuestion = questions[questionNum];

      if (currentQuestion) {
        window.analytics.track(currentQuestion);
      }
    }
  }, [pathname, questions]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        let score = 0;
        let hasDiagnosis = false;

        if (
          Object.values(values).every((v) => {
            return v && v.length;
          })
        ) {
          Object.values(values).forEach((value) => {
            if (value.toLowerCase().includes('often')) {
              score = score + 1;
            }
            if (
              value.toLowerCase().includes('trymeds') ||
              value.toLowerCase().includes('justmeds')
            ) {
              hasDiagnosis = true;
            }
          });

          if (preScreenResults.SymptomsBefore12YearsOld === 'No') {
            return navigate('/asrs-a/not-qualified');
          }

          if (
            score >= 4 ||
            hasDiagnosis ||
            preScreenResults?.PreviousDiagnosis === 'Yes'
          ) {
            window.analytics.track('Intake ASRS-A Pass');

            localStorage.setItem(
              'ASRS_SCREENING',
              JSON.stringify({ ...values }),
            );

            navigate('/signup', { replace: true });
          } else {
            navigate('/asrs-a/not-qualified');
          }
        }
      }}
    >
      <QuestionnaireLayout>
        <Card>
          {!pathname.includes('intro') && !pathname.includes('not-qualified') && (
            <BackArrow
              className="mb-4"
              onClick={() => {
                setStep(step - 1);
                navigate(-1);
              }}
            />
          )}
          <AsrsProgressContext.Provider
            value={{
              step,
              setStep,
              endStep: questions.length,
              baseRoute: '/asrs-a/',
            }}
          >
            <Outlet />
          </AsrsProgressContext.Provider>
          {/* question counter */}
          {!pathname.includes('intro') && !pathname.includes('not-qualified') && (
            <div className="mt-3.5 text-center text-sm font-normal leading-5 text-gray-500">
              Question {step} of {questions.length}
            </div>
          )}
        </Card>
      </QuestionnaireLayout>
    </Formik>
  );
};

export { AsrsIntro, Question } from './pages';
export default InauthAsrsQuestionnaire;
