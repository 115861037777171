import { FunctionComponent, PropsWithChildren } from 'react';
import { useFormikContext } from 'formik';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../Button';

type Props = {
  disabled?: boolean;
  className?: string;
};

export const SubmitButton: FunctionComponent<PropsWithChildren<Props>> = ({
  disabled,
  className,
  children,
}) => {
  const { isSubmitting, isValid } = useFormikContext();
  const isDisabled = isSubmitting || !isValid || disabled;

  return (
    <Button
      submit
      className={className}
      title={!isValid ? 'Please fix the errors shown above' : undefined}
      data-cy="submit"
      data-testid="submit"
      disabled={isDisabled}
    >
      {isSubmitting ? <FontAwesomeIcon icon={faCircleNotch} spin /> : children}
    </Button>
  );
};
