import { Button, LoadingPage, useAuth, useProfile } from '@pm/core';
import PatientApp from './PatientApp';

const AuthApp = () => {
  const { isPatient, isProvider } = useProfile();
  const { logout } = useAuth();

  if (isPatient) {
    return <PatientApp />;
  }

  if (isProvider) {
    return (
      <div className="max-w-512 mx-auto flex flex-col p-10 text-center">
        You&apos;re a provider, please use the PurposeMed app instead.
        <Button variant="primary" onClick={logout} className="mt-10">
          Logout
        </Button>
      </div>
    );
  }

  return <LoadingPage />;
};

export default AuthApp;
