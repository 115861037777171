import { ReactNode, useContext, useState } from 'react';
import { useFormikContext } from 'formik';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Qualified from './Qualified';
import NotQualified from './ScreenedOut/NotQualified';
import { useUpdateWorkflowStepMutation } from '@pm/graphql';
import { ScreeningContext } from '../index';
import { Button, Select } from '@pm/core';
import NextButtonLabel from '../../components/NextButtonLabel';

type QuestionProps = {
  name: string;
  question: string;
  options: { text: ReactNode; value: string }[];
  nextPath?: string;
};

const Question = ({ name, question, options, nextPath }: QuestionProps) => {
  const { t } = useTranslation('frida-screening-questionnaire');
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, submitForm }: any = useFormikContext();
  const { step, endStep, setStep } = useContext(ScreeningContext);
  const [updateMutation] = useUpdateWorkflowStepMutation();
  const { workflowStepId }: Readonly<Params<string>> = useParams();
  const [loading, setLoading] = useState(false);
  const disabled = !values[name];
  values.QuestionnaireVersion = t('QuestionnaireVersion');

  const questions: string[] = Object.values(
    t('Questions', { returnObjects: true }),
  );

  setStep(questions.indexOf(question) + 1);

  return (
    <div className="flex flex-col">
      <div className="mb-2 text-xl">{question}</div>
      <Select name={name} options={options} />
      <Button
        disabled={disabled}
        data-cy="question-button"
        className="mt-10"
        onClick={async () => {
          if (workflowStepId) {
            setLoading(true);
            await updateMutation({
              variables: {
                input: {
                  id: workflowStepId,
                  completionParams: {
                    ...values,
                  },
                },
              },
            }).catch((err) => {
              console.error(err);
            });
          }

          nextPath &&
            navigate(
              workflowStepId
                ? `/screening/${
                    workflowStepId ?? ':workflowStepId'
                  }/${nextPath}`
                : `/screening/${nextPath}`,
            );
          step === endStep ? submitForm() : setLoading(false);
        }}
      >
        <NextButtonLabel
          loading={loading}
          label={step === endStep ? t('Finish') : t('Next')}
        />
      </Button>
    </div>
  );
};

export { Question, Qualified, NotQualified };
