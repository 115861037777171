import clsx from 'clsx';
import { FunctionComponent, PropsWithChildren } from 'react';

type Props = {
  label?: string;
  className?: string;
  variant?: string;
};

export const QuestionnaireLabel: FunctionComponent<
  PropsWithChildren<Props>
> = ({ label, children, className, variant = 'bigGreen' }) => (
  <div
    className={clsx(
      className,
      variant === 'bigGreen' &&
        'text-freddie-green-1 mb-6 text-2xl font-semibold sm:text-3xl',
      variant === 'baseGreen' && 'text-freddie-green-1 text-lg font-bold',
    )}
  >
    {children ? children : label}
  </div>
);
