import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ButtonLink, CenteredLayout, SubmitButton } from '@pm/core';
import { FlowbiteField, useAuth } from '@pm/core';

const ForgotPasswordPage = () => {
  const { t } = useTranslation(['forgot-password', 'login']);
  const { useRecoverPassword } = useAuth();
  const [recoverPasswordMutation] = useRecoverPassword();
  const [message, setMessage] = useState('');

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t('login:InvalidEmail'))
      .required(t('login:MissingEmail')),
  });

  const handleSubmit = async ({ email }: { email: string }) => {
    await recoverPasswordMutation({
      variables: {
        input: { email },
      },
    }).then(({ data }) => {
      if (data) {
        if (!data.recoverPassword?.errors.length) {
          setMessage(t('forgot-password:CheckEmail'));
        }
      }
    });
  };

  return (
    <CenteredLayout>
      {message ? (
        <>
          <h2 className="text-2xl font-extrabold leading-9">
            {t('forgot-password:ThankYou')}
          </h2>
          <div className="mt-4 text-xl font-normal text-gray-500">
            {message}
          </div>
          <ButtonLink to="/" className="mt-10">
            {t('forgot-password:Return')}
          </ButtonLink>
        </>
      ) : (
        <>
          <h2 className="text-2xl font-extrabold leading-9">
            {t('forgot-password:Title')}
          </h2>
          <div className="my-4 text-xl font-normal text-gray-500">
            {t('forgot-password:Description')}
          </div>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={schema}
            validateOnMount={true}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <FlowbiteField
                  name="email"
                  label={t('forgot-password:EmailAddress')}
                  type="email"
                  placeholder={t('forgot-password:EmailAddressPlaceholder')}
                  bottomMargin={false}
                />
                <SubmitButton className="mt-10">
                  {t('forgot-password:ResetPassword')}
                </SubmitButton>
              </Form>
            )}
          </Formik>
        </>
      )}
    </CenteredLayout>
  );
};

export default ForgotPasswordPage;
