import { createContext, FunctionComponent, PropsWithChildren } from 'react';

type EnvironmentContextType = {
  directUploadsUrl: string;
  googleAuthUrl: string;
  apiHost: string;
};

type Props = {
  apiHost: string;
};

export const EnvironmentContext = createContext<EnvironmentContextType>({
  directUploadsUrl: '',
  googleAuthUrl: '',
  apiHost: '',
});

export const EnvironmentProvider: FunctionComponent<
  PropsWithChildren<Props>
> = ({ children, apiHost }) => (
  <EnvironmentContext.Provider
    value={{
      directUploadsUrl: `${apiHost}/uploads/direct_uploads`,
      googleAuthUrl: `${apiHost}/auth/google_oauth2/callback`,
      apiHost,
    }}
  >
    {children}
  </EnvironmentContext.Provider>
);
