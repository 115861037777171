import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PreScreenQuestionnaire from '.';
import { Question } from './pages';

const PreScreenRoutes = () => {
  const { t } = useTranslation(['global', 'frida-pre-screen-questionnaire']);
  const questions = Object.entries(
    t('frida-pre-screen-questionnaire:Questions', { returnObjects: true }),
  );
  const answers = [
    { text: t('global:Yes'), value: 'Yes' },
    { text: t('global:No'), value: 'No' },
  ];

  return (
    <Routes>
      <Route path="/" element={<PreScreenQuestionnaire />}>
        {questions.map(([key, q], i) => {
          const nextPath =
            i + 1 < questions.length
              ? questions[i + 1][0]
              : questions[questions.length - 1][0];

          return (
            <Route
              key={i}
              path={key}
              element={
                <Question
                  name={key}
                  question={q}
                  options={answers}
                  nextPath={nextPath}
                />
              }
            />
          );
        })}
      </Route>
    </Routes>
  );
};

export default PreScreenRoutes;
