import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, intlFormat, parseISO } from 'date-fns';
import {
  useCompleteWorkflowStepMutation,
  useRescheduleAppointmentMutation,
} from '@pm/graphql';
import { BackArrow, Button, Card } from '@pm/core';
import { Layout } from '../../layout';

type Props = {
  reschedule?: boolean;
};

const AppointmentConfirmationPage: FunctionComponent<Props> = ({
  reschedule = false,
}) => {
  const { date, workflowStepId, appointmentId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['appointment', 'call-dashboard']);
  const [complete] = useCompleteWorkflowStepMutation();
  const [rescheduleAppt] = useRescheduleAppointmentMutation();

  if (!workflowStepId || !date || (reschedule && !appointmentId)) {
    navigate('/');
    return null;
  }

  const day = format(parseISO(date), 'EEEE, MMMM d');
  const time = format(parseISO(date), 'h:mm aaa');
  const timezone = intlFormat(parseISO(date), { timeZoneName: 'short' })
    .split(', ')
    .pop();
  const cardText = (t('appointment:Confirm.CardText') as string)
    .replace('{date}', day)
    .replace('{time}', time)
    .replace('{timezone}', timezone ?? '');

  return (
    <Layout narrow>
      <BackArrow
        onClick={() => {
          navigate(`/appointment/${workflowStepId}`);
        }}
      />
      <Card className="mt-5">
        <div className="mb-4 text-2xl font-medium text-gray-900">
          {t('appointment:Confirm.Title')}
        </div>
        <div className="mb-10 text-gray-500">{cardText}</div>
        <Button
          onClick={() => {
            if (reschedule && appointmentId) {
              rescheduleAppt({
                variables: {
                  input: {
                    appointmentId: appointmentId,
                    timeSlot: date,
                  },
                },
                refetchQueries: ['GetMePatient'],
                awaitRefetchQueries: true,
              });
            }

            complete({
              variables: {
                input: {
                  workflowStepId,
                  completionParams: {
                    booking: {
                      timeSlot: date,
                    },
                  },
                },
              },
              refetchQueries: ['GetMyWorkflowSteps'],
              awaitRefetchQueries: true,
            }).then(() => {
              window.analytics.track('Initial Appointment Booked');
              navigate('/');
            });
          }}
        >
          {t('appointment:Confirm.Button')}
        </Button>
      </Card>
    </Layout>
  );
};

export default AppointmentConfirmationPage;
