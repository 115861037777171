import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { format, intlFormat, parseISO } from 'date-fns';

import { ButtonLink, Card } from '@pm/core';
import { AcuityAppointmentFragment } from '@pm/graphql';

type Props = {
  appointment: AcuityAppointmentFragment;
  workflowStepId: string;
};

const ManageAppointmentCard: FunctionComponent<Props> = ({
  appointment,
  workflowStepId,
}) => {
  const { t } = useTranslation('patient-dashboard');

  // hide the button if the appt is < 24 hours out
  const today = +new Date();
  const appt = +new Date(appointment.scheduledAt);
  const oneDayIntoTheFuture = 24 * 60 * 60 * 1000;
  const isManageable = today - appt < oneDayIntoTheFuture;

  const day = format(parseISO(appointment.scheduledAt), 'EEEE, MMMM d');
  const time = format(parseISO(appointment.scheduledAt), 'h:mm aaa');
  const timezone = intlFormat(parseISO(appointment.scheduledAt), {
    timeZoneName: 'short',
  })
    .split(', ')
    .pop();

  const cardText = t('Appointment.Manage.CardText')
    .replace('{duration}', appointment.duration ?? '')
    .replace('{date}', day)
    .replace('{time}', time)
    .replace('{timezone}', timezone ?? '');

  return (
    <Card>
      <div className="text-gray-500">{cardText}</div>
      {isManageable ? (
        <ButtonLink
          to={`/appointment/manage/${workflowStepId}/${appointment.acuityAppointmentId}/${appointment.scheduledAt}/${appointment.duration}`}
          variant="secondary"
          className="mt-4"
        >
          {t('Appointment.Manage.Button')}
        </ButtonLink>
      ) : null}
    </Card>
  );
};

export default ManageAppointmentCard;
