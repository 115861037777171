import { FunctionComponent } from 'react';
import clsx from 'clsx';

import { BadgeType } from './index';

type Props = {
  text: string;
  type: BadgeType | 'messages';
  className?: string;
};

const MenuBadge: FunctionComponent<Props> = ({ text, type, className }) => {
  return (
    <div
      className={clsx(
        className,
        'text-s flex h-5 content-center items-center justify-center rounded-full p-3 text-center font-medium',
        type === 'messages' &&
          'bg-messages-badge-background text-messages-badge-foreground',
        type === 'info' && 'bg-blue-500 text-white',
        type === 'success' && 'bg-green-500 text-white',
        type === 'danger' && 'bg-red-500 text-white',
        type === 'warning' && 'bg-yellow-200 text-black',
        type === 'disabled' && 'bg-gray-300 text-gray-50',
      )}
    >
      {text}
    </div>
  );
};

export default MenuBadge;
