import clsx from 'clsx';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';

type Props = {
  title?: string;
  wide?: boolean;
  narrow?: boolean;
  sidebarContent: JSX.Element;
  topbarLogo: JSX.Element;
};

export const MainAppLayout: FunctionComponent<PropsWithChildren<Props>> = ({
  sidebarContent,
  topbarLogo,
  wide,
  narrow,
  title,
  children,
}) => (
  <div className="flex h-screen flex-col">
    <Topbar>{topbarLogo}</Topbar>
    <div className="flex flex-1">
      <Sidebar>{sidebarContent}</Sidebar>
      <main className="flex-1 overflow-y-auto">
        <div className="py-6">
          {title && (
            <div
              className={clsx(
                'mx-auto px-4 sm:px-6 md:px-8',
                wide ? 'w-full' : 'max-w-7xl',
              )}
            >
              <h1 className="mb-2 text-base font-bold text-gray-400">
                {title}
              </h1>
            </div>
          )}
          <div
            className={clsx(
              'mx-auto px-4 sm:px-6 md:px-8',
              wide ? 'w-full' : 'max-w-7xl',
              narrow && 'max-w-lg',
            )}
          >
            {children}
          </div>
        </div>
      </main>
    </div>
  </div>
);
