import { Trans, useTranslation } from 'react-i18next';

const Psych = () => {
  const { t } = useTranslation('frida-screening-questionnaire');

  return (
    <div className="flex flex-col gap-y-7 text-gray-900">
      <div className="text-2xl font-medium">
        {t('NotQualified.Psych.Title')}
      </div>
      <div className="text-xl text-gray-500">
        {t('NotQualified.Psych.CardText')}
      </div>
      <div className="text-xl text-gray-500">
        <Trans t={t} i18nKey="NotQualified.Psych.LinkText">
          <a
            href="https://caddac.ca/find-a-resource"
            className="text-frida-purple underline"
          />
        </Trans>
      </div>
    </div>
  );
};

export default Psych;
