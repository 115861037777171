import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Gad7Questionnaire, { Question } from '.';
import { FunctionComponent } from 'react';

type Props = {
  isAssessment?: boolean;
};

const Gad7Routes: FunctionComponent<Props> = ({ isAssessment = false }) => {
  const { t } = useTranslation('frida-gad7-questionnaire');
  const questions = Object.entries(t('Questions', { returnObjects: true }));
  const answers = Object.values(t('Answers', { returnObjects: true }));

  return (
    <Routes>
      <Route
        path="/"
        element={<Gad7Questionnaire isAssessment={isAssessment} />}
      >
        {questions.map(([key, question], i) => {
          const nextPath =
            i + 1 < questions.length
              ? questions[i + 1][0]
              : questions[questions.length - 1][0];

          return (
            <Route
              key={i}
              path={key}
              element={
                <Question
                  name={key}
                  question={question}
                  options={answers}
                  nextPath={nextPath}
                  isAssessment={isAssessment}
                />
              }
            />
          );
        })}
      </Route>
    </Routes>
  );
};

export default Gad7Routes;
