import { FunctionComponent, PropsWithChildren } from 'react';

export const CenteredLayout: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <div className="bg-frida-beige flex min-h-screen">
      <div className="mx-auto my-auto">
        <div className="max-w-512 sm:min-w-512 mx-4 rounded-lg border border-gray-200 bg-white p-6 shadow-md sm:p-10">
          {children}
        </div>
      </div>
    </div>
  );
};
