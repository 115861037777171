import { ApolloQueryResult } from '@apollo/client';
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import { GetMeQuery, useGetMeQuery } from '@pm/graphql';

export type ProfileContextType = {
  profile: GetMeQuery['me'];
  refetch: () => Promise<ApolloQueryResult<GetMeQuery>>;
  loading: boolean;
};

export const ProfileContext = createContext<ProfileContextType | undefined>(
  undefined,
);

export const ProfileProvider: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  const { data, refetch, loading } = useGetMeQuery();

  // set our language
  useEffect(() => {
    if (data?.me) {
      i18n.changeLanguage(data.me.locale);
    }
  }, [data, i18n]);

  // set sentry user
  useEffect(() => {
    if (data?.me && data.me.id) {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: data.me?.id });
      });
    }
  }, [data]);

  // set segment user
  useEffect(() => {
    if (
      data?.me &&
      data.me.id &&
      window.analytics &&
      window.analytics.identify
    ) {
      window.analytics.identify(data.me.id, {
        role: data.me.role.code,
      });
    }
  }, [data]);

  return (
    <ProfileContext.Provider
      value={{
        profile: data?.me,
        refetch,
        loading,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
