import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { Gad7ProgressContext } from '../index';
import {
  GetMePatientDocument,
  useUpdateWorkflowStepMutation,
  useUpdateAssessmentMutation,
} from '@pm/graphql';
import { Button, Select } from '@pm/core';
import NextButtonLabel from '../../components/NextButtonLabel';

type QuestionProps = {
  name: string;
  question: string;
  options: string[];
  nextPath?: string;
  isAssessment?: boolean;
};

const Question = ({
  name,
  question,
  options,
  nextPath,
  isAssessment = false,
}: QuestionProps) => {
  const { id } = useParams(); // Either the workflow step id or the assessment id
  const { t } = useTranslation('frida-gad7-questionnaire');
  const { values, submitForm } = useFormikContext<{ [id: string]: string }>();
  const { step, endStep, setStep } = useContext(Gad7ProgressContext);
  const [update] = useUpdateWorkflowStepMutation();
  const [updateAssessment] = useUpdateAssessmentMutation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const disabled = !values[name];
  values.QuestionnaireVersion = t('QuestionnaireVersion');

  return (
    <div className="flex flex-col">
      <div className="mb-4 text-xl sm:mb-0">{question}</div>
      <Select name={name} options={options} />
      <Button
        disabled={disabled}
        className="mt-10 sm:mt-6"
        onClick={() => {
          if (id) {
            setLoading(true);
            if (isAssessment) {
              updateAssessment({
                variables: {
                  input: {
                    id,
                    data: {
                      ...values,
                      firstIncompleteQuestion: nextPath,
                    },
                  },
                },
                refetchQueries: [{ query: GetMePatientDocument }],
              }).then(() => {
                if (step !== endStep) {
                  setLoading(false);
                  setStep(step + 1);
                  nextPath && navigate(`/assessment/gad7/${id}/${nextPath}`);
                } else {
                  submitForm();
                }
              });
            } else {
              update({
                variables: {
                  input: {
                    id,
                    completionParams: {
                      ...values,
                      firstIncompleteQuestion: nextPath,
                    },
                  },
                },
                refetchQueries: [{ query: GetMePatientDocument }],
              }).then(() => {
                if (step !== endStep) {
                  setLoading(false);
                  setStep(step + 1);
                  nextPath && navigate(`/gad7/${id}/${nextPath}`);
                } else {
                  submitForm();
                }
              });
            }
          }
        }}
      >
        <NextButtonLabel
          loading={loading}
          label={step !== endStep ? t('Next') : t('Finish')}
        />
      </Button>
    </div>
  );
};

export { Question };
