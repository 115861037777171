import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { Card } from '@pm/core';

export type RoadmapSteps = {
  step: '1' | '2' | '3' | '4';
  setImageLoaded: Dispatch<SetStateAction<boolean>>;
};

const RoadmapCard: FunctionComponent<RoadmapSteps> = ({
  step,
  setImageLoaded,
}) => {
  return (
    <Card>
      <picture>
        <source
          srcSet={`/images/roadmap-steps-${step}-sm.svg`}
          media="(max-width: 800px)"
        />
        <img
          className="inline-block h-full"
          src={`/images/roadmap-steps-${step}.svg`}
          alt={`Roadmap steps ${step}`}
          onLoad={() => setImageLoaded(true)}
        />
      </picture>
    </Card>
  );
};

export default RoadmapCard;
