import { usePatientProfile } from '@pm/core';

export const useIsProfileComplete = (): boolean => {
  const { profile } = usePatientProfile();
  if (
    profile &&
    profile.profile?.dateOfBirth &&
    profile.firstName &&
    profile.lastName &&
    profile.phone.rawNumber &&
    profile.voicemailConsent
  ) {
    return true;
  }
  return false;
};
