import { I18nResources, TFunction, useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { MedicationResponseProgressContext } from '../index';
import { Button, Select, FlowbiteField } from '@pm/core';
import NextButtonLabel from '../../components/NextButtonLabel';
import { SideEffects } from './SideEffects';
import {
  GetMePatientDocument,
  useUpdateAssessmentMutation,
  useUpdateWorkflowStepMutation,
} from '@pm/graphql';

type detailedQuestion = {
  Question: string;
  Details: string;
  Placeholder: string;
};

type QuestionProps = {
  name: string;
  question: detailedQuestion | string;
  nextPath?: string;
  isAssessment?: boolean;
};

const Question = ({
  name,
  question,
  nextPath,
  isAssessment = false,
}: QuestionProps) => {
  const { id } = useParams();
  const { t } = useTranslation('frida-medication-response-questionnaire');
  const [updateWorkflowStep] = useUpdateWorkflowStepMutation();
  const [updateAssessment] = useUpdateAssessmentMutation();
  const navigate = useNavigate();

  const { values, submitForm } = useFormikContext<{ [id: string]: string }>();
  const { step, endStep, setStep } = useContext(
    MedicationResponseProgressContext,
  );
  const [showInputForm, setShowInputForm] = useState(false);
  const [loading, setLoading] = useState(false);

  let disabled = !values[name];
  values.QuestionnaireVersion = t('QuestionnaireVersion');
  let isOpenField = false;
  let placeholder;
  const isAskForDetailsQuestion =
    (question as detailedQuestion).Question != null;

  //Setting placeholder values for input fields
  if (name.includes('Other')) {
    isOpenField = true;
    disabled = false;
    if (name === 'OtherEffects') {
      placeholder = t('OtherEffectsPlaceholder');
    } else {
      placeholder = t('OtherIssuesPlaceholder');
    }
  } else if (isAskForDetailsQuestion) {
    placeholder = (question as detailedQuestion).Placeholder;
  }

  const options = setAnswers(name, isAskForDetailsQuestion, t);
  let questionCopy = question.toString();
  if (isAskForDetailsQuestion) {
    questionCopy = (question as detailedQuestion).Question;
  }

  useEffect(() => {
    if (!isAskForDetailsQuestion || disabled) {
      setShowInputForm(false);
    }
  }, [showInputForm, isAskForDetailsQuestion, disabled]);

  return (
    <div className="flex flex-col">
      <div className="text-xl text-gray-700 sm:mb-0">
        {questionCopy.split('{n}').map((substr: string, index: number) => (
          <div key={index} className="my-4">
            {substr}
          </div>
        ))}
      </div>
      {name.includes('SideEffect') ? (
        <SideEffects
          name={name}
          nextPath={nextPath}
          isAssessment={isAssessment}
        />
      ) : (
        <div>
          {isOpenField ? (
            <FlowbiteField
              name={name}
              placeholder={placeholder}
              className="border-1 border-frida-purple mt-2 w-full rounded-md"
              autoComplete="off"
              bottomMargin={false}
            />
          ) : (
            <>
              <Select
                name={name}
                options={options}
                onSelect={(value: string) => {
                  if (isAskForDetailsQuestion && value == 'Yes') {
                    setShowInputForm(true);
                  } else if (isAskForDetailsQuestion && value == 'No') {
                    setShowInputForm(false);
                  }
                }}
              />
              {showInputForm && (
                <div className="mt-10">
                  <div className="mb-6 text-xl">
                    {(question as detailedQuestion).Details}
                  </div>
                  <FlowbiteField
                    name={name + 'Details'}
                    placeholder={placeholder}
                    autoComplete="off"
                    className="border-1 border-frida-purple w-full rounded-md"
                  ></FlowbiteField>
                </div>
              )}
            </>
          )}
          <Button
            disabled={
              disabled ||
              (showInputForm && values[name + 'Details'] == undefined)
            }
            className="mt-10"
            onClick={async () => {
              if (isOpenField && !values[name]) {
                values[name] = 'N/A';
              }
              if (isAskForDetailsQuestion && values[name] == 'No') {
                values[name + 'Details'] = 'N/A';
              }
              setShowInputForm(false);

              if (id) {
                setLoading(true);

                if (isAssessment) {
                  await updateAssessment({
                    variables: {
                      input: {
                        id,
                        data: {
                          ...values,
                          firstIncompleteQuestion: nextPath,
                        },
                      },
                    },
                    refetchQueries: [{ query: GetMePatientDocument }],
                  });
                } else {
                  await updateWorkflowStep({
                    variables: {
                      input: {
                        id,
                        completionParams: {
                          ...values,
                          firstIncompleteQuestion: nextPath,
                        },
                      },
                    },
                    refetchQueries: [{ query: GetMePatientDocument }],
                  });
                }
              }

              if (step !== endStep) {
                setLoading(false);
                setStep(step + 1);
                nextPath &&
                  navigate(
                    `${
                      isAssessment ? '/assessment' : ''
                    }/med-response/${id}/${nextPath}`,
                  );
              } else {
                submitForm();
              }
            }}
          >
            <NextButtonLabel
              loading={loading}
              label={step !== endStep ? t('Next') : t('Finish')}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

type Answers = keyof I18nResources['frida-medication-response-questionnaire'];

const setAnswers = (
  questionLabel: string,
  isAskForDetailsQuestion: boolean,
  t: TFunction<'frida-medication-response-questionnaire', undefined>,
) => {
  let answers: Answers = 'SymptomAnswers';
  if (isAskForDetailsQuestion) {
    answers = 'YesNoAnswers';
  } else if (questionLabel == 'MedicationFrequency') {
    answers = 'MedicationFrequencyAnswers';
  }

  const options = Object.entries(t(answers, { returnObjects: true })).map(
    (element) => {
      return { value: element[0], text: element[1] };
    },
  );
  return options;
};

export { Question };
