import {
  format,
  differenceInCalendarWeeks,
  addWeeks,
  differenceInCalendarDays,
  differenceInYears,
  addDays,
  getDaysInMonth,
  lastDayOfMonth,
  startOfMonth,
  parseISO,
} from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';
import i18next from 'i18next';

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) =>
    (start + i * step).toString(),
  );
const currentYear = new Date().getFullYear();
export const years = range(currentYear, currentYear - 100, -1);
export const days = range(1, 31, 1);
export const months = () => {
  let locale = enCA;
  if (i18next.language === 'fr') {
    locale = frCA;
  }
  return [
    format(new Date(0, 1, 0), 'MMMM', { locale }),
    format(new Date(0, 2, 0), 'MMMM', { locale }),
    format(new Date(0, 3, 0), 'MMMM', { locale }),
    format(new Date(0, 4, 0), 'MMMM', { locale }),
    format(new Date(0, 5, 0), 'MMMM', { locale }),
    format(new Date(0, 6, 0), 'MMMM', { locale }),
    format(new Date(0, 7, 0), 'MMMM', { locale }),
    format(new Date(0, 8, 0), 'MMMM', { locale }),
    format(new Date(0, 9, 0), 'MMMM', { locale }),
    format(new Date(0, 10, 0), 'MMMM', { locale }),
    format(new Date(0, 11, 0), 'MMMM', { locale }),
    format(new Date(0, 12, 0), 'MMMM', { locale }),
  ];
};
export const monthNumbers = range(1, 12, 1);

export const getYear = (date: string | null) => {
  if (!date) {
    return '';
  }
  const tokens = date.split('-');
  if (tokens.length < 3) {
    return '';
  }
  return tokens[0];
};

export const getMonth = (date: string | null) => {
  if (!date) {
    return '';
  }
  const tokens = date.split('-');
  if (tokens.length < 3) {
    return '';
  }
  return Number(tokens[1]).toString();
};

export const getDay = (date: string | null) => {
  if (!date) {
    return '';
  }
  const tokens = date.split('-');
  if (tokens.length < 3) {
    return '';
  }
  return Number(tokens[2]).toString();
};

export const makeDateString = (year: string, month: string, day: string) => {
  const paddedMonth = month.length === 1 ? `0${month}` : month;
  const paddedDay = day.length === 1 ? `0${day}` : day;
  return `${year}-${paddedMonth}-${paddedDay}`;
};

export const getEpochWeek = (date: Date) =>
  differenceInCalendarWeeks(date, new Date(0));

export const setEpochWeek = (epochWeek: number) =>
  addWeeks(new Date(0), epochWeek);

export const getEpochDay = (date: Date) =>
  differenceInCalendarDays(date, new Date(0));

export const setEpochDay = (epochDay: number) => addDays(new Date(0), epochDay);

export const isUserEighteen = (date: Date) => {
  const age = differenceInYears(new Date(), new Date(date));
  return age >= 18;
};

export const buildMonthMatrix = (
  month: number,
  year: number,
): Array<Date[]> => {
  const dateString = `${year}-${String(month + 1).padStart(
    2,
    '0',
  )}-01T12:00:00`;
  const date = new Date(new Date(dateString).toUTCString());
  const dateArray: Date[] = [];
  const matrix: Array<Date[]> = [[], [], [], [], [], []];

  const daysInCurrentMonth = getDaysInMonth(date);
  const dateClone = new Date(date);
  const prevMonth = new Date(dateClone.setMonth(dateClone.getMonth() - 1));
  const daysInPreviousMonth = getDaysInMonth(prevMonth);

  // start offset
  const startOffset = startOfMonth(date).getDay();

  // end offset
  const lastWeekDayIndex = lastDayOfMonth(date).getDay() + 1;
  const endOffset = weekDays.length * 2 - lastWeekDayIndex;

  // add dates to show from the previous month
  // JS Date months start at 0, days & years start at 1. go figure
  const daysToShowFromPreviousMonth: number[] = [];
  Array.from(Array(startOffset).keys()).map((day) => {
    daysToShowFromPreviousMonth.push(daysInPreviousMonth - day);
  });
  if (daysToShowFromPreviousMonth.length) {
    daysToShowFromPreviousMonth.reverse().map((day) => {
      dateArray.push(
        new Date(
          `${year}-${String(prevMonth.getMonth() + 1).padStart(
            2,
            '0',
          )}-${String(day).padStart(2, '0')}T12:00:00`,
        ),
      );
    });
  }

  // add dates from the current month
  const daysToShowFromCurrentMonth: number[] = [];
  Array.from(Array(daysInCurrentMonth).keys()).map((day) => {
    daysToShowFromCurrentMonth.push(day);
  });
  daysToShowFromCurrentMonth.map((day) => {
    dateArray.push(
      new Date(
        `${year}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
          day + 1,
        ).padStart(2, '0')}T12:00:00`,
      ),
    );
  });

  // finally, add dates to show from the upcoming month
  const daysToShowFromNextMonth: number[] = [];
  Array.from(Array(endOffset).keys()).map((day) => {
    daysToShowFromNextMonth.push(day + 1);
  });
  if (daysToShowFromNextMonth.length) {
    daysToShowFromNextMonth.map((day) => {
      if (month + 1 === 12) {
        dateArray.push(
          new Date(
            `${year + 1}-${String(1).padStart(2, '0')}-${String(day).padStart(
              2,
              '0',
            )}T12:00:00`,
          ),
        );
      } else {
        dateArray.push(
          new Date(
            `${year}-${String(date.getMonth() + 2).padStart(2, '0')}-${String(
              day,
            ).padStart(2, '0')}T12:00:00`,
          ),
        );
      }
    });
  }

  for (const week of matrix) {
    const newWeek = dateArray.splice(0, 7);
    week.push(...newWeek);
  }

  return matrix;
};

export const getFirstDayOfNextMonth = (date: Date): Date => {
  const dateClone = new Date(date);
  const nextMonthDate = new Date(dateClone.setMonth(dateClone.getMonth() + 1));

  return nextMonthDate;
};

export const getChangeInMonths = (monthDifference = 0, date?: Date): Date => {
  if (!date) {
    date = new Date();
  }
  const dateClone = new Date(date);
  const newDate = new Date(
    dateClone.setMonth(dateClone.getMonth() + monthDifference),
  );

  return newDate;
};

export const formatDateAmericanFormat = (date: string) => {
  const dateAsISO = parseISO(date);
  return format(dateAsISO, 'MMM-dd-yyyy');
};
