import { FunctionComponent } from 'react';
import { CoreParticipantFieldsFragment } from '@pm/graphql';

type Props = {
  recipient: CoreParticipantFieldsFragment;
};

const RecipientName: FunctionComponent<Props> = ({ recipient }) => {
  let content = (
    <>
      {recipient.firstName} {recipient.lastName}
    </>
  );

  if (['CX', 'MOA', 'ADMIN'].includes(recipient.role.code)) {
    content = <>Frida Medical Team</>;
  }

  return (
    <div className="p-4 align-bottom text-sm leading-8 text-gray-400">
      {content}
    </div>
  );
};
export default RecipientName;
