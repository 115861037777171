import clsx from 'clsx';
import { FunctionComponent, PropsWithChildren } from 'react';

type Props = {
  width?: string;
  variant?: 'uppercaseGray' | 'gray900' | 'white';
};

export const TableHeadingCell: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  width,
  variant = 'uppercaseGray',
}) => (
  <th
    className={clsx(
      variant === 'white' &&
        'border-b border-gray-200 py-3 pl-6 text-left text-sm font-medium text-gray-900',
      variant === 'gray900' &&
        `border-b border-gray-300 bg-gray-50 py-3 pl-6 text-left text-sm font-medium text-gray-900 ${width}`,
      variant === 'uppercaseGray' &&
        `border-b border-gray-200 bg-gray-50 px-2 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500 ${width}`,
    )}
  >
    {children}
  </th>
);
