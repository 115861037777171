import { Button, MultiSelectField, Select } from '@pm/core';
import {
  GetMePatientDocument,
  useUpdateAssessmentMutation,
  useUpdateWorkflowStepMutation,
} from '@pm/graphql';
import { useFormikContext } from 'formik';
import { ReactElement, useContext, useState } from 'react';
import { I18nResources, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NextButtonLabel from '../../components/NextButtonLabel';
import { MedicationResponseProgressContext } from '../index';

type SideEffectProps = {
  name: string;
  nextPath?: string;
  isAssessment?: boolean;
};

type PossibleSideEffects =
  keyof I18nResources['frida-medication-response-questionnaire']['PossibleSideEffects'];

const SideEffects = ({
  name,
  nextPath,
  isAssessment = false,
}: SideEffectProps) => {
  const { id } = useParams();
  const { t } = useTranslation('frida-medication-response-questionnaire');
  const [updateWorkflowStep] = useUpdateWorkflowStepMutation();
  const [updateAssessment] = useUpdateAssessmentMutation();
  const navigate = useNavigate();

  const { values, submitForm } = useFormikContext<{ [id: string]: string }>();
  const { step, endStep, setStep } = useContext(
    MedicationResponseProgressContext,
  );
  const [loading, setLoading] = useState(false);

  const inputFields: ReactElement[] = [];
  let disabled = !values[name];
  const options = Object.entries(
    t('PossibleSideEffects', { returnObjects: true }),
  ).map((element) => {
    return { value: element[0], name: element[1] };
  });

  //If the question is about individual side effect frequency build list of side effects
  if (name !== 'SideEffects') {
    const frequencyOptions = Object.entries(
      t('SideEffectFrequencyAnswers', { returnObjects: true }),
    ).map((element) => {
      return { value: element[0], text: element[1] };
    });
    const sideEffects: string[] = createSideEffectsList(values);
    disabled = !areAllSideEffectFrequencyQuestionsAnswered(values, sideEffects);
    sideEffects.forEach((effect, i) => {
      const text = t(`PossibleSideEffects.${effect as PossibleSideEffects}`, {
        defaultValue: null,
      });
      inputFields.push(
        <div key={i} className="mt-10 mb-4 text-xl sm:mb-0">
          <div> {text as string} </div>
          <Select
            name={effect}
            options={frequencyOptions}
            className="text-base"
          />
        </div>,
      );
    });
    setSideEffectFrequencyAnswers(values, name, sideEffects);
  }

  return (
    <div>
      {name === 'SideEffects' ? (
        <MultiSelectField name={name} options={options} />
      ) : (
        <div className="mb-3">{inputFields}</div>
      )}
      <Button
        disabled={disabled}
        onClick={async () => {
          if (id) {
            if (name === 'SideEffects' && values[name] == 'None') {
              values['SideEffectFrequency'] = 'N/A';
            }
            setLoading(true);

            if (isAssessment) {
              await updateAssessment({
                variables: {
                  input: {
                    id,
                    data: {
                      ...values,
                      firstIncompleteQuestion: nextPath,
                    },
                  },
                },
                refetchQueries: [{ query: GetMePatientDocument }],
              });
            } else {
              await updateWorkflowStep({
                variables: {
                  input: {
                    id,
                    completionParams: {
                      ...values,
                      firstIncompleteQuestion: nextPath,
                    },
                  },
                },
                refetchQueries: [{ query: GetMePatientDocument }],
              });
            }
          }

          if (step !== endStep) {
            setLoading(false);
            if (name === 'SideEffects' && values[name] == 'None') {
              //if no side effects skip ahead
              nextPath = 'OtherEffects';
              setStep(step + 2);
            } else {
              setStep(step + 1);
            }
            nextPath &&
              navigate(
                `${
                  isAssessment ? '/assessment' : ''
                }/med-response/${id}/${nextPath}`,
              );
          } else {
            submitForm();
          }
        }}
        className="mt-7"
      >
        <NextButtonLabel
          loading={loading}
          label={step !== endStep ? t('Next') : t('Finish')}
        />
      </Button>
    </div>
  );
};

const createSideEffectsList = (currentValues: { [key: string]: string }) => {
  const usersSideEffects = currentValues.SideEffects;
  const sideEffects: string[] = usersSideEffects.split(',');
  return sideEffects;
};

// build data storage for all frequency answers
const setSideEffectFrequencyAnswers = (
  values: { [id: string]: string },
  name: string,
  usersSideEffects: string[],
) => {
  if (areAllSideEffectFrequencyQuestionsAnswered(values, usersSideEffects)) {
    const sideEffectAnswers: string[] = [];
    for (const sideEffect of usersSideEffects) {
      sideEffectAnswers.push(
        '{' + sideEffect + ': ' + values[sideEffect] + '}',
      );
    }
    values[name] = sideEffectAnswers.toString();
  }
};

//test if user has answered all questions about their side effects
const areAllSideEffectFrequencyQuestionsAnswered = (
  values: { [id: string]: string },
  usersSideEffects: string[],
) => {
  for (const sideEffect of usersSideEffects) {
    if (!values[sideEffect]) {
      return false;
    }
  }
  return true;
};

export { SideEffects };
