import { FunctionComponent, PropsWithChildren } from 'react';
import { Field } from 'formik';
import clsx from 'clsx';

type Props = {
  name: string;
  className?: string;
};

export const Checkbox: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  name,
  className,
}) => {
  return (
    <div className="flex flex-row">
      <Field
        name={name}
        type="checkbox"
        className={clsx(
          'float-left mr-2 mt-0.5 h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat transition duration-200 checked:bg-blue-600 focus:outline-none',
          className,
        )}
      />
      <div className="text-sm text-gray-500">{children}</div>
    </div>
  );
};
