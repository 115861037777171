import { ApolloQueryResult } from '@apollo/client';
import { GetMePatientQuery, useGetMePatientQuery } from '@pm/graphql';
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useEffect,
} from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { browserName, deviceType } from 'react-device-detect';

type PatientProfileContextType = {
  profile: GetMePatientQuery['me'];
  refetch: () => Promise<ApolloQueryResult<GetMePatientQuery>>;
  loading: boolean;
};

export const PatientProfileContext = createContext<
  PatientProfileContextType | undefined
>(undefined);

export const PatientProfileProvider: FunctionComponent<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const LDClient = useLDClient();
  const { data, refetch, loading } = useGetMePatientQuery();
  const provinceCode = data?.me?.profile?.province?.code;
  if (provinceCode) {
    localStorage.setItem('PROVINCE', provinceCode);
  }
  const steps = data?.me?.workflowSteps ?? [];
  const hasReceivedRX = steps.some(
    (step) => step.type === 'Steps::FulfillPrescription',
  );
  sessionStorage.setItem('HAS_RECEIVED_RX', hasReceivedRX.toString());

  // set LaunchDarkly user
  useEffect(() => {
    if (data?.me && data.me.id && LDClient && provinceCode) {
      sessionStorage.setItem('USER_ID', data.me.id);
      const newUser = {
        key: data.me.id,
        custom: {
          location: window.location.href,
          browserName: browserName,
          deviceType: deviceType,
          province: provinceCode,
          hasReceivedRX: hasReceivedRX.toString(),
        },
      };
      LDClient.identify(newUser);
    }
  }, [data, LDClient, provinceCode, hasReceivedRX]);

  if (!data?.me) {
    return null;
  }

  return (
    <PatientProfileContext.Provider
      value={{
        profile: data?.me,
        refetch,
        loading,
      }}
    >
      {children}
    </PatientProfileContext.Provider>
  );
};
