import { FunctionComponent, ChangeEvent, useState, useMemo } from 'react';
import {
  FieldMetaProps,
  ErrorMessage,
  useField,
  useFormikContext,
} from 'formik';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { makeDateString, getDay, getMonth, getYear } from '../..';

interface InputProps {
  name: string;
  value: string;
  placeholder: string;
  meta: FieldMetaProps<unknown>;
  label?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Input: FunctionComponent<InputProps> = ({
  name,
  value,
  placeholder,
  meta,
  label,
  onChange,
}) => {
  return (
    <div className="flex-grow">
      {label && (
        <label className="float-left block text-xs text-gray-500">
          {label}
        </label>
      )}
      <input
        name={name}
        value={value}
        placeholder={placeholder}
        type="text"
        inputMode="numeric"
        onChange={onChange}
        className={clsx(
          'bg-input-background block w-full rounded-md px-4 py-3 focus:ring focus:ring-opacity-50 sm:leading-5',
          meta.touched && meta.error
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-400 focus:ring-red-200'
            : 'focus:border-blue-300 focus:ring-blue-200',
        )}
      />
    </div>
  );
};

interface Props {
  name: string;
  initialDate?: string;
}

export const Datepicker: FunctionComponent<Props> = ({ name, initialDate }) => {
  const { t } = useTranslation('global');
  const [, meta] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [date, setDate] = useState({
    month: initialDate?.length ? getMonth(initialDate) : '',
    day: initialDate?.length ? getDay(initialDate) : '',
    year: initialDate?.length ? getYear(initialDate) : '',
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setDate((date) => ({ ...date, [event.target.name]: event.target.value }));

  useMemo(() => {
    if (date.day && date.month && date.year) {
      const newDate = new Date(
        `${makeDateString(date.year, date.month, date.day)}`.replace(/-/g, '/'),
      );

      setFieldTouched(name, true, true);
      setFieldValue(name, newDate, true);
    }
  }, [date, name, setFieldValue, setFieldTouched]);

  return (
    <div className="grid grid-cols-3 grid-rows-1 place-content-center gap-4">
      <Input
        name="month"
        value={date.month}
        meta={meta}
        label={t('Month')}
        placeholder="MM"
        onChange={handleChange}
      />
      <Input
        name="day"
        value={date.day}
        meta={meta}
        label={t('Day')}
        placeholder="DD"
        onChange={handleChange}
      />
      <Input
        name="year"
        value={date.year}
        meta={meta}
        label={t('Year')}
        placeholder="YYYY"
        onChange={handleChange}
      />
      <ErrorMessage
        name={name}
        component="p"
        className="col-span-3 text-red-500"
      />
    </div>
  );
};
